import React, {useContext, useEffect, useState} from 'react';

import MetaTags from "../../../atoms/MetaTags";
import {BasketContext} from "../../../providers/BasketProvider";
import {Link} from "react-router-dom";

function Complete({ checkout, paymentMethod }) {
  const {
    resetBasket,
    order,
    calculateTotal
  } = useContext(BasketContext);

  let total = order.total;
  return (
    <>
      <MetaTags title="Mastery" pathname={location.pathname}/>

      <div className="container px-[40px] mx-auto">

      </div>

      <div>
        <span
          className="col-span-2 block w-full rounded-[10px] border-[#ffd23f] px-5 py-3.5 bg-yellow font-bold text-dark-navy">Order Number - 123456789</span>
        <div className="p-4">
          <div
            className="flex flex-col lg:flex-row border-b border-[#F5F5F5] lg:mb-6 pb-6 text-[14px]">
            <div className="mr-24">
              <div className="flex my-4"><span className="font-bold mr-2">Date</span>
                {new Date().toLocaleDateString()}
              </div>
              <div className="flex my-4"><span className="font-bold mr-2">Email</span>
                {checkout.email}
              </div>
              <span className="font-bold">Billing Address</span>
              <div>{checkout.billing_address1}</div>
              <div>{checkout.billing_address2}</div>
              <div>{checkout.billing_town}</div>
              <div>{checkout.billing_country}</div>
              <div>{checkout.billing_postcode}</div>
            </div>
            <div>
              <div className="flex my-4"><span className="font-bold mr-2">Payment</span>{paymentMethod}
              </div>
              <div className="flex my-4">
                <span className="font-bold mr-2">Total</span>£{total.toLocaleString('en-GB', { minimumFractionDigits: 2 })}
              </div>
              <span className="font-bold">Delivery Address</span>
              <div>{checkout.shipping_address1}</div>
              <div>{checkout.shipping_address2}</div>
              <div>{checkout.shipping_town}</div>
              <div>{checkout.shipping_country}</div>
              <div>{checkout.shipping_postcode}</div>
            </div>
          </div>
          {/*desktop home button*/}
          <div className="lg:block hidden max-w-[580px] mt-3 mb-3">
            <div>
              <span className="text-[12px]">Please <Link to="/contact"
                className="text-[#ffd23f] hover:cursor-pointer">contact us</Link> we can update, change or cancel your order as required </span>
            </div>
            <div className="flex justify-end">
              <Link
                to="/"
                className="hover:cursor-pointer bg-dark-navy text-white py-3 px-5 rounded-[8px] mt-12 w-full lg:w-auto text-center">Go
                to home</Link>
            </div>
          </div>
        </div>
      </div>

      {/*mobile home button*/}
      <div className="lg:hidden block lg:max-w-[580px] mt-3 mb-3">
        <div>
          <span className="text-[12px]">Please <Link to="/contact"
            className="text-[#ffd23f] hover:cursor-pointer">contact us</Link> we can update, change or cancel your order as required </span>
        </div>
        <div className="flex lg:justify-end">
          <Link
            to="/"
            className="hover:cursor-pointer bg-dark-navy text-white py-3 px-5 rounded-[8px] mt-6 w-full lg:w-auto text-center">Go
            to home</Link>
        </div>
      </div>
    </>
  );
};
export default Complete;
