import { SET_OPENED, SET_FOLLOWED_TAB } from '../actions/configAction';

const init = {
  opened: null,
  followedTab: 0,
};

function config(state = init, action) {
  switch (action.type) {
    case SET_OPENED:
      return { ...state, opened: action.payload };
    case SET_FOLLOWED_TAB:
      return { ...state, followedTab: action.payload };
    default:
      return state;
  }
}

export default config;
