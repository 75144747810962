import React, { Fragment } from 'react';

export default React.forwardRef(({ navigate, onClick, colour = 'bg-dark-navy text-white', classes = '', children, ...props }, ref) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (navigate) {
      navigate(e);
    }
  };

  return (
    <button ref={ref} className={colour + " cursor-pointer rounded-md py-2 px-4 inline-block " + classes} onClick={handleClick} {...props}>
      {children}
    </button>
  );
})
