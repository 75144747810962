import { useEffect, useState } from "react"
import InputError from "./InputError";

const EmailInput = ({input_name, value, confirm = false, confirm_email_value = '', handleInputChange, validationDetailsSetter = null}) => {

    const [input, setInput] = useState({
        email: value,
        confirm_email: confirm_email_value
    })
    const [validation, setValidation] = useState({
        email: false,
        confirm_email: false
    });

    useEffect(() => {
        setValidation(prev => ({
            ...prev,
            email: isCorrectEmailFormat(input.email)
          }))
          if(!confirm) {
            setValidationDetails(isCorrectEmailFormat(input.email))
          }
    }, [input.email])

    useEffect(() => {
        setValidation(prev => ({
            ...prev,
            confirm_email: input.email == input.confirm_email
          }))
          if(confirm) {
            setValidationDetails(isCorrectEmailFormat(input.email) && input.email == input.confirm_email)
          }
    }, [input.confirm_email])

    const validationErrorMessage = {
        email: 'Please enter a valid email address.',
        confirm_email: 'Please ensure email addresses match.'
    }

    const isCorrectEmailFormat = (email) => {
        var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    const inputChange = (event) => {
        let target_is_email = event.target.name == input_name;
        let currentInputs = input
        if(target_is_email) {
            input.email = event.target.value
        } else {
            input.confirm_email = event.target.value
        }
        setInput(currentInputs);
        validate(event)
        handleInputChange(event)
    }

    const setValidationDetails = (valid) => {
        if(validationDetailsSetter) {
            validationDetailsSetter((prev) => ({
                ...prev,
                email: valid
            }))
        }
    }

    const validate = (event) => {
        let target_is_email = event.target.name == input_name;
        let currentValidaton = validation;
        if(target_is_email) {
            currentValidaton.email = isCorrectEmailFormat(event.target.value)
        }
        if(confirm) {
            if(!target_is_email) {
                currentValidaton.confirm_email = input.email == input.confirm_email
            }
        }
        setValidation(currentValidaton)
        setValidationDetails(confirm ? currentValidaton.email && currentValidaton.confirm_email : currentValidaton.email)
    }
    
    return (
        <>
            <input 
                required 
                placeholder="Email*" 
                type="email" 
                name={input_name} 
                id={input_name} 
                value={input.email} 
                className="block w-full rounded-[10px] py-3.5 px-5 border-[#ffd23f] border" 
                onChange={inputChange} 
            />
            { !validation.email && input.email !== undefined && (
                <InputError message={validationErrorMessage.email} />
            )}
            {
                confirm && (
                    <>
                        <input 
                            required 
                            placeholder="Confirm Email*" 
                            type="email" 
                            name={input_name + '_confirmation'} 
                            id={input_name + '_confirmation'} 
                            value={input.confirm_email} 
                            className="block w-full rounded-[10px] py-3.5 px-5 border-[#ffd23f] border mt-6" 
                            onChange={inputChange} 
                        />
                        { ( validation.email ) && !validation.confirm_email && input.confirm_email !== undefined && (
                            <InputError message={validationErrorMessage.confirm_email} />
                        )}
                    </>
                )
            }
        </>
    )
}

export default EmailInput