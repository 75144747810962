export const SET_OPENED = 'SET_OPENED';
export const SET_FOLLOWED_TAB = 'SET_FOLLOWED_TAB';

export const setOpened = payload => ({
  type: SET_OPENED,
  payload,
});
export const setFollowedTab = payload => ({
  type: SET_FOLLOWED_TAB,
  payload,
});
