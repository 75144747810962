import React from 'react';

import policyPage from '../../containers/policyPage';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import MetaTags from "../../../atoms/MetaTags";

const Policy = props => {
  return (
    <div className='policy-page wysiwyg'>
        <MetaTags title="Privacy policy" pathname={props.location.pathname} />

      <h1>What is this Privacy Policy for?</h1>
      <p>This privacy policy is provided for this website and governs the privacy of those who choose to use the site.</p>
      <p>The policy sets out each of the areas where user privacy is concerned and outlines the obligations and requirements on users, the website and its owners. In addition the way this website processes, stores and protects user data and information is detailed in this policy.</p>
      <h2>The Website</h2>
      <p>This website and it's owners take a proactive approach to user privacy and ensure the necessary steps are taken to protect the privacy of users throughout their visit.</p>
      <h2>Use of Cookies</h2>
      <p>This website uses cookies to improve the user’s experience while visiting the website. Cookies are small files saved to the user's computer hard drive that track, save and store information about the users interactions with, and usage of, the website. This allows the website, through its server, to provide the users with a tailored experience on this website.</p>
      <p>Users are advised that, if they wish to deny the use and saving of cookies from this website on their computers hard drive, they should take necessary steps in their web browser’s security settings to block all cookies from this website and external serving vendors.</p>
      <p>This website uses tracking software to monitor visitors to better understand how they use the site. This software is provided by Google Analytics which uses cookies to track visitor usage. The software will save a cookie to your computer hard drive in order to track and monitor your engagement and usage of the website but will not store, save or collect personal information. You can read <a href="http://www.google.com/privacy.html">Google's privacy policy</a> for further information.</p>
      <p>Other cookies may be stored to your computer hard drive by external vendors when this website uses referral programs, sponsored links or adverts. Such cookies are used for conversion and referral tracking and typically expire after 30 days, though some may take longer. No personal information is stored, saved or collected.</p>
      <h2>External Links</h2>
      <p>Although this website aims only to include quality, safe and relevant external links, users should always adopt a policy of caution before clicking any external web links mentioned in this website.</p>
      <p>The owners of this website cannot guarantee or verify the contents of any externally linked website, despite their best efforts. Users should, therefore, note they click on external links at their own risk. This website and it's owners will not be held liable for any damages or other losses caused by visiting any external links.</p>
      <h2>Contact and Communication</h2>
      <p>Users contacting this website and/or its owners do so at their own discretion and provide any  personal details requested at their own risk. Your personal information is kept private and stored securely until such time it is no longer required or has no use, as required by law. Every effort has been made to ensure a safe and secure form to email submission process but we advise users using such form to email processes that they do so at their own risk.</p>
      <p>This website and its owners use any information submitted to provide you with further information about the products / services they offer or to assist you in answering any questions or queries you may have submitted. This includes using your details to subscribe you to any email newsletter programme the website operates but only if this was made clear to you and your express permission was granted when submitting any form to email process. Data will be similarly used if you have previously enquired about  a product or service that the email newsletter relates to. This is not an entire list of your user rights in regard to receiving email marketing material. Your details are not passed on to any third parties.</p>
      <h2>Social Media Platforms</h2>
      <p>Communication, engagement and actions taken through external social media platforms that this website and its owners participate on are subject to both the terms and conditions and the privacy policies of each such social media platform.</p>
      <p>Users are advised to use social media platforms wisely and communicate / engage upon them with due care and caution in regard to their own privacy and personal details. Neither this website nor its owners will ever ask for personal or sensitive information through social media platforms and encourage users wishing to discuss sensitive details to contact them through primary communication channels such as by telephone or email.</p>
      <p>This website may use social sharing buttons which help share web content directly from web pages to the social media platform in question. Users of such social sharing buttons are advised that they do so at their own discretion and should note that the social media platform may track and save your request to share a web page through your social media platform account.</p>
      <h2>Shortened Links in Social Media</h2>
      <p>This website and its owners, through their social media platform accounts, may share web links to relevant web pages. By default some social media platforms shorten lengthy URLs (web addresses).</p>
      <p>Users are advised to exercise caution and judgement before clicking any shortened url's published on social media platforms by this website and its owners. Despite best efforts to ensure only genuine url's are published, many social media platforms are prone to spam and hacking. This website and its owners cannot be held liable for any damages or losses caused by visiting any shortened links.</p>
      <h2>Email Newsletter</h2>
      <p>This website operates an email newsletter programme, used to inform subscribers about products and services supplied by the site. Users can subscribe through an online automated process should they wish to do so but do so at their own discretion. Some subscriptions may be manually processed through prior written agreement with the user.</p>
      <p>All personal details relating to subscriptions are held securely and as required by law. No personal details are passed on to third parties nor shared with companies / people outside of the company that operates this website. You may request a copy of personal information held about you by this website's email newsletter programme. A fee may be payable. If you would like a copy of the information held on you please write to the address at the bottom of this policy.</p>
      <p>Email marketing campaigns published by this website or its owners may contain tracking facilities within the actual email. Subscriber activity is tracked and stored in a database for future analysis and evaluation. Such tracked activity may include; the opening of emails, forwarding of emails, the clicking of links within the email content, times, dates and frequency of activity (this is not a comprehensive list).</p>
      <p>This information is used to refine future email campaigns and supply the user with more relevant content based around their activity.</p>
      <p>In compliance with the law subscribers are given the opportunity to unsubscribe at any time through an automated system. This process is detailed at the footer of each email campaign. If an automated un-subscription system is unavailable clear instructions on how to un-subscribe will be detailed instead.</p>
      <h2>Adverts and Sponsored Links</h2>
      <p>This website may contain sponsored links and adverts. These will typically be served through our advertising partners, who may have privacy policies relating directly to such adverts.</p>
      <p>Clicking on any such adverts will send you to the advertiser's website through a referral programme which may use cookies and track the number of referrals sent from this website. This may include the use of cookies which may, in turn, be saved on your computer hard drive. Users should, therefore, note they click on sponsored external links at their own risk and this website and it's owners cannot be held liable for any damages or other losses caused by visiting any external links mentioned.</p>
      <h2>Resources & Further Information</h2>
      <ul>
        <li><a href="http://www.google.com/privacy.html">Google Privacy Policy</a></li>
        <li><a href="http://twitter.com/privacy">Twitter Privacy Policy</a></li>
        <li><a href="http://www.facebook.com/about/privacy/">Facebook Privacy Policy</a></li>
        <li><a href="http://www.linkedin.com/static?key=privacy_policy">Linkedin Privacy Policy</a></li>
      </ul>

      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about your privacy or how we use your personal data, please contact us using the details below.</p>
      <p><strong>Post:</strong></p>
      <p>20-22 Wenlock Road</p>
      <p>London</p>
      <p>England</p>
      <p>N1 7GU</p>

      <p><strong>Telephone number: </strong><a href='tel:+448944656758'>(+44) 07944 656758</a></p>
      <p><strong>Email:</strong> <a href='mailto:privacy@grandudes.com'>privacy@grandudes.com</a></p>
      <p><strong>Website:</strong> <a href='https://www.grandudes.com'>www.grandudes.com</a></p>
    </div>
  );
};

export default policyPage(Policy);
