import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import authPage from '../../../containers/authPage';
import {Link} from "react-router-dom";
import MetaTags from "../../../../atoms/MetaTags";

class VerifyService extends Component {
  state = {
    error: undefined,
  }

  componentDidMount() {
    const { service } = this.props.match.params;
    const query = new URLSearchParams(this.props.location.search);
    const code = query.get('code');

    if (code) {
      // run verify request
      this.props.verifyServiceAction(service, code).then(data => {
        if (data) {
          // set 'token' to localstorage
          localStorage.setItem('token', data.accessToken);
          // get 'me'(current user) data
          this.props.getMe(data.accessToken).then(user => {
            // redirect to homepage
            this.props.history.push(`/`);
          });
        }
      });
    }
    else {
      this.setState({ error: 'Could not sign in' });
    }
  }

  render() {
    const error = this.state.error || this.props.auth.error;

    if (error) {
      return <>
        <MetaTags title="Verification" pathname={this.props.location.pathname} />

        <div className="auth-form auth-form--sign-up">
          <div className="auth-form__vertical-frame">
            <div className="auth-form__title">Login failed</div>
            <div className="mb-3 text-xl leading-tight">
                Sorry but something went wrong. Please try signing in again.
            </div>
            <Link to={"/login"}
                  className="btn btn--secondary">
              Sign in
            </Link>
          </div>
        </div>
      </>
    }

    return <></>;
  }
}

VerifyService.propTypes = {
  auth: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  verifyAction: PropTypes.func,
  getMe: PropTypes.func,
};

export default authPage(VerifyService);
