import { NOTIFICATIONS_UPDATE, NOTIFICATIONS_LOADING } from '../actions/notificationsAction';

const initialState = {
  isLoading: false,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case NOTIFICATIONS_UPDATE:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    default:
      return state;
  }
};
