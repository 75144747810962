import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import Loader from '../../atoms/Loader';
import {
  signUp,
  verify,
  signIn,
  resetPassword,
  newPassword,
  authClearData,
  userVerification, verifyService
} from '../../actions/authAction';
import { getProfileMe } from '../../actions/userAction';
import Footer from '../Footer';

const authPage = WrappedComponent => {
  class HOC extends Component {
    componentDidMount() {
      document.body.className = document.body.className.replace('no-scroll', '');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.config.opened !== this.props.config.opened) {
        const objRef = document.body;
        if (this.props.config.opened) {
          objRef.classList.add('no-scroll');
        } else {
          objRef.className = objRef.className.replace('no-scroll', '');
        }
      }
    }

    render() {
      return (
        <div className="main-body main-body--auth">
          <header className="auth__header">
            <Link to={'/'} className="auth__logo">
              <img src="/images/logo.svg" alt="logo" width="230" height="54" />
            </Link>
            <div className="auth__menu">
              <div className="auth__menu-item">
                {/* <Link to={'/about'} className="auth__menu-link">
                  About
                </Link> */}
              </div>
              <div className="auth__menu-item">
                {/* <Link to={'/contact'} className="auth__menu-link">
                  Contact
                </Link> */}
              </div>
            </div>
          </header>
          <div className="auth__main">
            <div className="container">
              <div className="auth__frame">
                <div className="auth__left">
                  <WrappedComponent {...this.props} />
                </div>
                <div className="auth__right">
                  <div className="auth__icon-frame">
                    <Icon name="icon-avatar-1" />
                  </div>
                  <div className="auth__icon-frame">
                    <Icon name="icon-avatar-2" />
                  </div>
                  <div className="auth__icon-frame">
                    <Icon name="icon-avatar-3" />
                  </div>
                  <div className="auth__icon-frame">
                    <Icon name="icon-avatar-4" />
                  </div>
                  <div className="auth__icon-frame">
                    <Icon name="icon-avatar-heart" />
                  </div>
                  <div className="auth__icon-frame">
                    <Icon name="icon-avatar-6" />
                  </div>
                  <div className="auth__icon-frame">
                    <Icon name="icon-avatar-7" />
                  </div>
                  <div className="auth__icon-frame">
                    <Icon name="icon-avatar-8" />
                  </div>
                  <div className="auth__icon-frame">
                    <Icon name="icon-avatar-9" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          {this.props.auth.isLoading && <Loader />}
        </div>
      );
    }
  }

  HOC.propTypes = {
    config: PropTypes.object,
  };

  const mapStateToProps = store => ({
    config: store.config,
    auth: store.auth,
  });

  const mapDispatchToProps = dispatch => ({
    signUpAction: data => dispatch(signUp(data)),
    signInAction: data => dispatch(signIn(data)),
    verifyAction: (service, code) => dispatch(verify(service, code)),
    verifyServiceAction: (service, code) => dispatch(verifyService(service, code)),
    sendResetPassword: email => dispatch(resetPassword(email)),
    sendNewPassword: data => dispatch(newPassword(data)),
    resendVerification: email => dispatch(userVerification(email)),
    getMe: token => dispatch(getProfileMe(token)),
    authClearData: () => dispatch(authClearData()),
  });

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(HOC),
  );
};

export default authPage;
