import React, { Component } from 'react';
import classNames from 'classnames';

import PostThumb from '../../atoms/PostThumb';
import { sendNotificationsSeen } from '../../utils/api/post';

class FollowedList extends Component {
  state = {
    opened: false,
    seenNotifications: [],
  };

  toggleList = () => {
    this.setState({ opened: !this.state.opened });
  };

  onNotificationItemEnter = notificationId => {
    const { seenNotifications } = this.state;
    const newId = seenNotifications.includes(notificationId) ? [] : [notificationId];
    this.setState({ seenNotifications: [...seenNotifications, ...newId] });
  };

  onNotificationItemLeave = () => {
    const { seenNotifications } = this.state;
    if (seenNotifications) {
      sendNotificationsSeen(seenNotifications).then(data => {
        this.setState({ seenNotifications: [] });
      });
    }
  };

  render() {
    const { items, title, type } = this.props;
    const showMore = items.length > 3;
    return (
      <div className="followed-frame">
        <div className="followed__title">
          Following
          {type === 'notifications' && (
            <div className="followed__btn-notify">
              <i className="far fa-bell" />
            </div>
          )}
        </div>
        <div className={classNames('followed-list', { opened: this.state.opened })}>
          {items.map((item, index) => (
            <div
              className="followed-list__item"
              key={index}
              onMouseEnter={() => {
                this.onNotificationItemEnter(item.id);
              }}
              onMouseLeave={this.onNotificationItemLeave}
            >
              {type === 'notifications' ? (
                <PostThumb
                  img={item.payload.whoReacted.avatar}
                  fullName={item.payload.whoReacted.displayName}
                  sub={item.type}
                  url={item.payload.whoReacted.id}
                  date={item.createdAt}
                  extended
                />
              ) : (
                <PostThumb
                  img={item.avatar}
                  fullName={item.displayName}
                  sub={item.sub}
                  url={item.id}
                  date={item.date}
                  extended
                />
              )}
            </div>
          ))}
        </div>
        {showMore && (
          <button className="followed__button" onClick={this.toggleList}>
            <i
              className={classNames('fas fa-chevron-down', {
                'fa-rotate-180': this.state.opened,
              })}
            />
          </button>
        )}
      </div>
    );
  }
}

export default FollowedList;
