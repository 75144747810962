/* eslint-disable */
import React from "react";

export default [
  {
    title: "Health",
    description: (<ul className="list-disc ml-4">
      <li>Physical</li>
      <li>Nutrition</li>
      <li>Emotional</li>
      <li>Mental</li>
      <li>Social</li>
    </ul>),
    intro: (
      <>
        <p className="text-22 mb-9 text-dark-navy">Men over the age of 50 can be just as active, healthy, and fit as
          younger men. Even while it might require a little more work, maintaining a healthy weight, exercising frequently,
          and going in for regular checkups can keep you going.</p>
        <p>All men want to live a little longer, right? Most importantly, all men want to look and feel physically healthy
          in their own bodies. Fortunately, there are steps you can take to look after your health - regardless of age.
          Whether you’re finding giving up a bad habit you’ve been trying to kick for a while. Your health should be
          your top priority.
        </p>
      </>
    ),
    intro2: (
      <>
        <span className="text-yellow">Grandudes</span>, let’s get this show on the road and help to keep you younger and full of
        vigour. It’s time to show everyone, including yourself, that you’ve still got it, and age is
        just a number.
      </>
    ),
    slug: "health",
    icon: "/images/health-icon.svg",
    image1: "/images/toolkit/health-image1.png",
  },
  {
    title: "Education",
    description: (<ul className="list-disc ml-4">
      <li>Intellectual</li>
      <li>Practical</li>
      <li>Child rearing</li>
      <li>Self-actualisation</li>
      <li>Professional</li>
    </ul>),
    intro: (
      <>
        <p className="text-22 mb-9 text-dark-navy">Education makes things possible: it is the greatest
          gift you can bestow upon yourself and those around you. The only way you can grow eternally
          is through education. Education continues until the day you die - it ignites your fire.</p>
        <p>The best schools instil a love for further discovery and growth. In order to fully learn,
          you must first love to learn. Our resources will give you fruit to discover what you’re
          passionate about, and to pursue it with a fresh sense of discovery.</p>
      </>
    ),
    intro2: (
      <>
        “Education is what remains after one has forgotten what one has learned in school” - Einstein.
      </>
    ),
    slug: "education",
    icon: "/images/education-icon.svg",
    image1: "/images/toolkit/education-image1.jpeg",
  },
  {
    title: "Legal",
    description: (<ul className="list-disc ml-4">
      <li>Estate planning</li>
      <li>Relationships</li>
      <li>Rights</li>
      <li>Housing</li>
      <li>Employment</li>
    </ul>),
    intro: (
      <>
        <p className="text-22 mb-9 text-dark-navy">We’ve got your back, to take full advantage of the
          law and your rights: from severance packages to divorce settlements; estate planning to consumer
          rights. Knowledge is power and we hand that to you, so that you can master your domain.</p>
        <p>The law is something we have to wrestle with every day. It’s so complicated that we often
          feel like it’s battling against us. It’s time to simplify your life, block out the noise,
          and focus on what matters. With our resources, links and contacts, you can move forward
          without missing a trick.</p>
      </>
    ),
    intro2: (
      <>
        <span className="text-yellow">Grandudes</span>, don’t let lawmakers kill their dreams. It’s time to put an end to bureaucracy
        through self-education. It’s time to express your highest self without worrying about the
        courts getting in your way.
      </>
    ),
    slug: "legal",
    icon: "/images/legal-icon.svg",
    image1: "/images/toolkit/legal-image1.jpeg",
  },
  {
    title: "Finance",
    description: (<ul className="list-disc ml-4">
      <li>Retirement</li>
      <li>Tax</li>
      <li>Investments</li>
      <li>Personal</li>
      <li>Business</li>
    </ul>),
    intro: (
      <>
        <p className="text-22 mb-9 text-dark-navy">’Money can’t buy happiness’, so they say. It can, however, buy freedom, safety, and
          security. We want you to have complete control over your life path, but if you’re stuck in
          the working trap you’ll never achieve this. The time of working for others is over: it’s time
          to work for yourself.</p>
        <p>At this time of life, managing your finances is critical, and we help you to put more pounds in your pocket.
          There are so many simple ways to improve your finances without giving up anything of value on your part.
          It’s all about knowing the game and playing it like the professionals. Explore our extensive toolkit to learn
          how.</p>
      </>
    ),
    intro2: (
      <>
        I finally know what distinguishes man from the other beasts: financial worries.” - <span className="text-yellow">Jules
        Renard.</span>
      </>
    ),
    slug: "finance",
    icon: "/images/finance-icon.svg",
    image1: "/images/toolkit/finance-image1.jpeg",
  },
  {
    title: "Grief and Loss",
    description: (<ul className="list-disc ml-4">
      <li>Bereavement</li>
      <li>Funeral planning</li>
      <li>Terminal illness</li>
      <li>Loss without death</li>
      <li>Healing</li>
    </ul>),
    intro: (
      <>
        <p className="text-22 mb-9 text-dark-navy">Loss is a part of life, and losing a loved one is
          not the only type of loss. Grief is our way of reacting when we lose someone or something
          important to us. Understanding and navigating grief is an imperative part of moving forward
          with your life - it cannot be neglected.</p>
        <p>Whether you’re experiencing grief right now, or preparing for the eventuality, no person
          is complete until they know how to deal with great losses. We have an abundance of resources
          and support groups to help you cope and get to the other side. This does not mean you forget,
          but that you remember in ways that allow you to move on with your life.</p>
      </>
    ),
    intro2: (
      <>
        “Only people who are capable of loving strongly can also suffer great sorrow, but this same
        necessity of loving serves to counteract their grief and heals them.” - Tolstoy
      </>
    ),
    slug: "grief-and-loss",
    icon: "/images/grief-and-loss-icon.svg",
    image1: "/images/toolkit/grief-and-loss-image1.jpeg",
  },
  {
    title: "Recreational",
    description: (<ul className="list-disc ml-4">
      <li>Sports and pastimes</li>
      <li>Reflection and relaxation</li>
      <li>Culture and arts</li>
      <li>Social and community</li>
      <li>Music and creative</li>
    </ul>),
    intro: (
      <>
        <p className="text-22 mb-9 text-dark-navy">Whether it’s your children leaving home, coming
          out of the work environment, or some other
          crossroads in your life: it’s in your hands to make your time worth it. With the world at
          your fingertips, free time can lead to the best time of your life. Now is your time to embrace
          the bountiful recreational opportunities the world offers.</p>
        <p>Recreation helps to restore our energy and promote a sense of joy. There may present
          challenges or difficulties, but that can add to the fulfilment. Don’t be content with daytime
          TV and surface-level social interactions. Check out our resources and pursue something which
          speaks to your soul.</p>
      </>
    ),
    intro2: (
      <>
        There’s a world of possibilities out there waiting for you. Either you open your eyes and let
        them whisk you away to a place of fulfilment, or you remain stagnant,disinterested, and purposeless.
      </>
    ),
    slug: "recreational",
    icon: "/images/recreation-icon.svg",
    image1: "/images/toolkit/recreation-image1.jpeg",
  }
]
