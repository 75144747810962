import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';

import Input from '../../../../atoms/form/Input';
import Checkbox from '../../../../atoms/form/Checkbox';
import formValidator from '../../../../utils/form';

import authPage from '../../../containers/authPage';
import ErrorParser from '../../../../atoms/ErrorParser';

import { toast } from 'react-toastify';
import {POST_ENDPOINTS} from "../../../../utils/api";
import MetaTags from "../../../../atoms/MetaTags";

const minLength6 = formValidator.minLength(6);
const minLength2 = formValidator.minLength(2);

const SignUp = props => {
  const {
    signUpAction,
    auth: { success, error },
    pristine,
    submitting,
    handleSubmit,
    authClearData,
  } = props;

  const outFormat = 'YYYY-MM-DD';

  useEffect(() => {
    if (success) {
        toast(success.description, { type: toast.TYPE.INFO, autoClose: false, position: toast.POSITION.TOP_CENTER });
    }
  },[success]);

  const signUpHandler = values => {
    const data = {
      ...values,
      passwordConfirmation: values.password,
      newsSubscription: values.subscription ? values.subscription : false,
      pseudonym: values.email.split('@')[0], // Just use the first part of their email for now
      isNamePrivateOnRegister: true,
      // Disabled as part of https://huddledigital.atlassian.net/browse/AICRET-6
      // isNamePrivateOnRegister: values.isNamePrivateOnRegister ? values.isNamePrivateOnRegister : false,
      // birthday: values.birthday.format(outFormat),
    };
      console.log(data);
    signUpAction(data);
  };
  return (
    <div className="auth-form auth-form--sign-up">
      <MetaTags title="Create an account" pathname={props.location.pathname} />

      <form autoComplete="off">
        <div className="auth-form__vertical-frame">
          <div>
            <div className="auth-form__title">Create an account</div>
            <div className="mb-5 text-xl leading-tight">
              Throughout the site you have the option to post in your own name, keep completely private, or post in a pseudonym.
            </div>
            {success && <div className="auth-form__success-validation">{success.description}</div>}
            {error && <ErrorParser data={error} />}
            {/*// Disabled as part of https://huddledigital.atlassian.net/browse/AICRET-6*/}
            {/*<div className="auth-form__frame-name">*/}
            {/*  <Field*/}
            {/*    title="First Name"*/}
            {/*    name="firstName"*/}
            {/*    component={Input}*/}
            {/*    type="text"*/}
            {/*    validate={[formValidator.required, minLength2]}*/}
            {/*  />*/}
            {/*  <Field*/}
            {/*    title="Last Name"*/}
            {/*    name="lastName"*/}
            {/*    component={Input}*/}
            {/*    type="text"*/}
            {/*    validate={[formValidator.required, minLength2]}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="auth-form__frame-email">*/}
            {/*    <Field*/}
            {/*        title="Pseudonym"*/}
            {/*        name="pseudonym"*/}
            {/*        component={Input}*/}
            {/*        type="text"*/}
            {/*        validate={[formValidator.required, minLength2]}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<Field*/}
            {/*    title="Tick here to keep your real name private from other users"*/}
            {/*    name="isNamePrivateOnRegister"*/}
            {/*    component={Checkbox}*/}
            {/*    type="checkbox"*/}
            {/*/>*/}
            <Field
              title="Email Address"
              name="email"
              component={Input}
              type="text"
              validate={[formValidator.required, formValidator.email]}
            />
            <Field
              title="Password"
              name="password"
              component={Input}
              type="password"
              validate={[formValidator.required, minLength6]}
            />
            <Field
              title="Tick here to receive our newsletter and updates"
              name="subscription"
              component={Checkbox}
              type="checkbox"
            />
          </div>
          <div>
            <button
              className="btn btn--primary"
              type="submit"
              onClick={handleSubmit(signUpHandler)}
              disabled={submitting}
            >
              <span className="text-el">Sign Up</span>
            </button>
            <Link
              to={'/login'}
              className="btn btn--secondary"
              onClick={() => {
                authClearData();
              }}
            >
              Already have an account?
            </Link>
            <div className="or">
              Or
            </div>
            <a
              href={POST_ENDPOINTS.signInGoogle}
              className="btn btn--secondary"
              onClick={() => {
                authClearData();
              }}
            >
              <i className="icon fab fa-google"></i>Sign up with Google
            </a>
            <a
              href={POST_ENDPOINTS.signInFacebook}
              className="btn btn--secondary"
              onClick={() => {
                authClearData();
              }}
            >
              <i className="icon fab fa-facebook" /> Sign up with Facebook
            </a>
          </div>
            <div className="auth-form__frame-policy">
                <p>By creating an account and using our website, you agree to our <Link to="/terms">Terms of Use.</Link></p>
                <p>For information about how we use your personal information, see our <Link to="/privacy">Privacy Policy.</Link></p>
            </div>
        </div>
      </form>
    </div>
  );
};

SignUp.propTypes = {
  auth: PropTypes.object,
  signUpAction: PropTypes.func,
};

const mapStateToProps = store => ({
  initialValues: {
    birthday: null,
  },
});

const mapDispatchToProps = dispatch => ({});

const form = reduxForm({
  form: 'signUp', // a unique identifier for this form
})(SignUp);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(authPage(form));
