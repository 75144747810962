/**
 * Redux Form Validators
 */

const required = value => (value ? undefined : 'Required');
const requiredStrict = value => (value && value.trim().length > 0 ? undefined : 'Required');
const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
const minLength = min => value => (value && value.length < min ? `Must be minimum ${min} characters` : undefined);
/*eslint-disable */
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Email address is not valid' : undefined;
const link = value =>
  value &&
  !value.match(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/g)
    ? 'Invalid link'
    : undefined;
const pseudonim = (pseudonim, data, redux) => {
  let isHiddenFirstName = redux.visibility.firstName === 'private';
  let isHiddenLastName = redux.visibility.lastName === 'private';

  let isEmptyPseudonim = pseudonim === null || pseudonim === undefined || pseudonim.trim().length === 0;

  return isHiddenFirstName && isHiddenLastName && isEmptyPseudonim
    ? 'Pseudonym is required when First name and Second name are hidden.'
    : undefined;
}
const date = value =>
  value &&
  !value.match(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/g)
    ? 'Invalid link'
    : undefined;
/* eslint-enable */

/**
 * Redux Form Normalizers
 */

const normalizeSpacings = (value, previousValue, allValues) => (!value ? previousValue : value);

const normalizeDate = (value, prevValue) => {
  if (!value) return value;

  const valueOnlyNumbers = value.replace(/[^\d]/g, '');
  const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

  // Enable backspacing:
  // if the user is backspacing and they backspace a forward slash, the date's
  // numbers won't be affected so just return the value.
  if (valueOnlyNumbers === prevValueOnlyNumbers) return value;

  const month = valueOnlyNumbers.slice(0, 2);
  const day = valueOnlyNumbers.slice(2, 4);
  const year = valueOnlyNumbers.slice(4, 8);

  if (valueOnlyNumbers.length < 2) return `${month}`;
  if (valueOnlyNumbers.length === 2) return `${month}/`;
  if (valueOnlyNumbers.length < 4) return `${month}/${day}`;
  if (valueOnlyNumbers.length === 4) return `${month}/${day}/`;
  if (valueOnlyNumbers.length > 4) return `${month}/${day}/${year}`;
};

export default {
  required,
  requiredStrict,
  maxLength,
  link,
  email,
  minLength,
  date,
  normalizeSpacings,
  normalizeDate,
  pseudonim,
};
