import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import PostThumb from '../../../atoms/PostThumb';
import Tags from '../../../atoms/Tags';

import generalPage from '../../containers/generalPage';
import formValidator from '../../../utils/form';
import Input from '../../../atoms/form/Input';
import Textarea from '../../../atoms/form/Textarea';
import MetaTags from "../../../atoms/MetaTags";

const maxLength50 = formValidator.maxLength(50);

class NewArticle extends Component {
  state = {
    tags: [{ name: 'Aliquam' }, { name: 'Dignissim' }, { name: 'Phasellus' }],
  };

  componentDidMount() {}

  submit = values => {
    // resetForm();
  };

  onChange = tags => {
    this.setState({ tags });
  };

  render() {
    const { user } = this.props;
    const { submitting, handleSubmit } = this.props;
    return (
      <div className="new-article">
        <MetaTags title="New Article" pathname={this.props.location.pathname} />

        <div className="new-article__title new-article__title--desktop">New Article</div>
        {user && (
          <div className="one-article__author new-article__author">
            <PostThumb img={user.img} fullName={user.fullName} date={'20/10/2019'} />
          </div>
        )}
        <div className="new-article__title--mobile">New Article</div>
        <div className="new-article__form">
          <form onSubmit={handleSubmit(this.submit)}>
            <div className="new-article__form-body">
              <Field
                title="Post Title"
                name="title"
                placeholder=""
                component={Input}
                type="text"
                validate={[formValidator.required, maxLength50]}
              />
              <Field
                name="description"
                placeholder=""
                component={Textarea}
                validate={[formValidator.required, maxLength50]}
              />
            </div>
          </form>
          <div className="new-article__form-tags">
            <Tags tags={this.state.tags} editMode title="Tags" onChange={this.onChange} />
          </div>
          <div className="new-article__form-buttons">
            <button className="btn btn--cancel" type="button">
              <span className="text-el">Cancel</span>
            </button>
            <button className="btn btn--save" type="submit" onClick={handleSubmit(this.submit)} disabled={submitting}>
              <span className="text-el">Save</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

NewArticle.propTypes = {
  user: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

const form = reduxForm({
  form: 'newArticle',
  enableReinitialize: true,
})(NewArticle);

const mapStateToProps = store => ({
  user: store.user,
});

const mapDispatchToProps = dispatch => ({});

export default generalPage(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(form),
);
