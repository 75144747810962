import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import config from './config';
import auth from './auth';
import user from './user';
import menu from './menu';
import notifications from './notifications';
import followed from './followed';
import gallery from './gallery';
import loader from './loader';
import inspirations from './inspirations';

const reducers = combineReducers({
  form: formReducer,
  config,
  user,
  menu,
  notifications,
  followed,
  gallery,
  auth,
  loader,
  inspirations,
});

export default reducers;
