import {useContext, useState} from "react";
import {BasketContext} from "../providers/BasketProvider";
import {Link} from "react-router-dom";

export default ({slug, id, text, badge, image, price, relatedProducts, shipping, textColour = 'text-white', is_virtual}) => {
  const { addItem } = useContext(BasketContext);
  const [ added, setAdded ] = useState(false);

  let handleAdd = e => {
    e.preventDefault();
    addItem(id, text, image, price, relatedProducts, [], shipping, is_virtual);
    setAdded(true);
    setTimeout(() => {
      setAdded(false);
    }, 500)
  };

  return (
    <div className="block border-gray-300">
      <div className="relative bg-dark-grey rounded-md h-[379px] p-5 overflow-hidden">
        {badge && (
          <div
            className="inline-block text-white py-1 px-2 rounded-md text-14 bg-yellow z-10 relative">{badge}</div>
        )}
        <Link to={`/shop/${slug}`}>
          <img src={image} className="absolute inset-0 z-0 object-cover w-full h-full"
             loading="lazy"/>
        </Link>
      </div>
      <h4 className={`my-4 text-18 ${textColour}`}><Link to={`/shop/${slug}`}>{text}</Link></h4>
      <h5 className={`font-semibold text-20 ${textColour}`}>&pound;{price}</h5>
      <div className="mt-4">
        {added === false && (
          <a className="my-2 py-3 px-4 rounded-[8px] bg-yellow text-dark-navy inline-block cursor-pointer" onClick={handleAdd}>Add to basket</a>
        )}
        {added === true && (
          <a className="my-2 py-3 px-4 text-white rounded-[8px] bg-dark-navy inline-block">Added to basket</a>
        )}
      </div>
    </div>
  );
}
