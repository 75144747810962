import React, { useContext, useEffect, useRef, useState } from 'react';

import MetaTags from '../../../atoms/MetaTags';
import generalContentPage from '../../containers/generalContentPage';
import { Link } from 'react-router-dom';
import { BasketContext } from '../../../providers/BasketProvider';
import * as PropTypes from 'prop-types';
import DisplayProductsByIDs from '../../Product/DisplayProductsByIDs';
import { couponLookup, discountCodeLookup } from '../../../utils/api';
import XMarkIcon from '../../../atoms/icons/x-mark';

function BasketButtons(props) {
  return (
    <>
      {!props.updating && (
        <button
          className="m-2 py-3 px-4 text-white rounded-[8px] hover:bg-dark-navy bg-[#91989F]"
          onClick={props.onClick}
        >
          Update basket
        </button>
      )}
      {props.updating && (
        <button
          className="m-2 py-3 px-4 text-white rounded-[8px] hover:bg-dark-navy bg-[#91989F]"
          onClick={props.onClick1}
        >
          Done
        </button>
      )}
      <Link to="/checkout" className="m-2 py-3 px-4 text-dark-navy rounded-[8px] bg-yellow">
        Go to checkout
      </Link>
    </>
  );
}

BasketButtons.propTypes = {
  updating: PropTypes.bool,
  onClick: PropTypes.func,
  onClick1: PropTypes.func,
};

function Basket({ location }) {
  const { 
    items, 
    updateItem, 
    numItems, 
    removeItem, 
    shipping, 
    calculateSubTotal, 
    calculateTotal,
    addCoupon,
    removeCoupon,
    coupon,
    itemIsDiscounted,
    relatedProducts 
  } = useContext(BasketContext);
  const [updating, setUpdating] = useState(false);
  const [discountError, setDiscountError] = useState('');
  const [quantities, setQuantities] = useState({});
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountCode, setDiscountCode] = useState(coupon.code ?? '');

  const handleCouponChange = (e) => {
    setDiscountCode(e.target.value);
  };

  const handleItemRemove = (id, e) => {
    e.preventDefault();
    removeItem(id);
  };

  const handleUpdate = e => {
    e.preventDefault();
    setUpdating(true);
  };

  const handleDoneUpdate = e => {
    e.preventDefault();

    Object.entries(quantities).forEach(([id, qty]) => {
      updateItem(id, qty);
    });

    setUpdating(false);
  };

  const applyCoupon = () => {
    if (discountCode) {
      setDiscountError('')
      discountCodeLookup({
        discount_code: discountCode
      })
      .then(res => {
        addCoupon(res.data.coupon, res.data.percentage_discount, res.data.products)
        setDiscountApplied(true)
        setDiscountCode('')
        setTimeout(() => {
          setDiscountApplied(false);
        }, 500)
      })
      .catch(err => {
        setDiscountError(err.message)
      });
    }
    else {
      removeCoupon()
    }
  }

  return (
    <>
      <MetaTags title="Mastery" pathname={location.pathname} />
      <div className="container mx-auto px-[40px]">
        <span className="block lg:mt-[112px] mt-10 max-w-[1200px] mx-auto mb-[60px] lg:mb-[71px] text-[30px] font-bold text-dark-navy">
          Your Basket
        </span>
      </div>
      <div className="lg:bg-white">
        <div className="container mx-auto px-[40px]">
          <img
            className="lg:block hidden absolute right-0 z-[-1] top-[-100px]"
            src="/images/background-letter-logo.svg"
          />
          <div className="flex flex-col lg:flex-row max-w-[1200px] mx-auto bg-white">
            <div className="flex-1 lg:max-w-[580px]">
              <span className="block bg-dark-navy text-white py-3 pl-4 rounded-[10px]">Products</span>
              <div>
                <div className="">
                  {numItems === 0 && <p>Your basket is empty</p>}
                  {Object.entries(items).map(([id, item]) => (
                    <div className="flex justify-between border-b border-[#F5F5F5] py-8 px-4">
                      <div className="flex">
                        <img className="block w-[80px] h-[80px] bg-[#F5F5F5]" src={item.thumbUrl} />
                        <div className="flex flex-col text-[14px] ml-12">
                          <div className="font-bold text-[16px]">{item.name}</div>
                          {/*<span className="py-2">Design: 1</span>*/}
                          {!updating && (
                            <>
                              <span
                                className="py-2">
                                  Qty: {item.qty} &pound;
                                  {(item.price * item.qty).toLocaleString('en-GB', { minimumFractionDigits: 2 })}
                              </span>
                              {item.variations && item.variations.map(variation => (
                                <div>{variation.name}: &pound;{(variation.price * item.qty).toLocaleString('en-GB', { minimumFractionDigits: 2 })}</div>
                              ))}
                              {itemIsDiscounted(parseInt(id), coupon) && (
                                <span className="py-2">Discount: {coupon.percent}%</span>
                              )}
                              <div className="flex justify-between">
                                  <a
                                    className="py-3 underline hover:cursor-pointer hover:no-underline"
                                    onClick={handleUpdate}
                                  >
                                    Edit
                                  </a>
                                  {/*<a*/}
                                  {/*  className="lg:hidden block py-3 underline text-[14px] hover:cursor-pointer hover:no-underline">Save*/}
                                  {/*  for later</a>*/}
                              </div>
                            </>
                          )}
                          {updating && (
                            <>
                              <div>
                                <span className="mr-2">Qty:</span>
                                <input
                                  className={`placeholder-black p-2 border border-[#ffd23f] rounded-md w-[100px]`}
                                  type="number"
                                  value={quantities[id] === undefined ? items[id].qty : quantities[id]}
                                  onChange={e => setQuantities({ ...quantities, [id]: e.currentTarget.value })}
                                />
                              </div>
                              <a
                                className="py-3 underline hover:cursor-pointer hover:no-underline"
                                onClick={handleDoneUpdate}
                              >
                                Done
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col items-end justify-between">
                        <a className="hover:cursor-pointer" onClick={handleItemRemove.bind(this, id)}>
                          <img className="min-w-[16px] aspect-square ml-4" src="/images/exit-icon.svg" />
                        </a>
                        {/*<a*/}
                        {/*  className="lg:block hidden underline text-[14px] hover:cursor-pointer hover:no-underline">Save*/}
                        {/*  for later</a>*/}
                      </div>
                    </div>
                  ))}
                  <div id='basket-coupon' className='py-4 flex gap-4 items-center'>
                      <label>Coupon: </label>
                      <input
                        type='text'
                        name='coupon'
                        id='basket-coupon-input'
                        className='block rounded-[10px] py-3 px-5 border-gray border'
                        onChange={handleCouponChange}
                        value={discountCode}
                      />
                      <button
                        onClick={applyCoupon}
                        className='m-2 py-3 px-4 text-white rounded-[8px] hover:bg-dark-navy bg-[#91989F]'
                      >
                        {discountApplied ? 'Applied' : 'Apply'}
                      </button>
                  </div>
                  {discountError && (<div className="failed-validation py-4 px-6 rounded relative mb-8" role="alert">
                    <img src={"images/warning.svg"} className="inline mr-2" alt="" />
                    {discountError}
                  </div>)}
                  {/*checkout buttons desktop*/}
                  <div className="lg:flex hidden justify-end pt-6 pb-12">
                    <BasketButtons updating={updating} onClick={handleUpdate} onClick1={handleDoneUpdate} />
                  </div>
                  {/* RELATED PRODUCTS */}
                  {
                    relatedProducts.length > 0 && (
                      <div className="flex">
                        <div className="flex-1 lg:max-w-[580px]">
                          <span className="block bg-[#F5F5F5] text-dark-navy py-3 pl-4 rounded-[10px] font-bold">You may also like these</span>
                          <DisplayProductsByIDs ids={relatedProducts} productsPerSlide={3} />
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            {/*Right content*/}
            <div className="lg:flex-1 flex lg:justify-end">
              <div className="bg-white lg:max-w-[440px] rounded-[10px] w-full">
                <span className="block bg-[#F5F5F5] text-dark-navy py-3 pl-4 rounded-[10px] font-bold">Summary</span>
                <div className="flex justify-between border-b border-[#F5F5F5] text-[14px]">
                  <span className="py-4 px-4">Subtotal</span>
                  <span className="py-4 px-4">
                    £{calculateSubTotal().toLocaleString('en-GB', { minimumFractionDigits: 2 })}
                  </span>
                </div>
                {coupon.code !== '' && <>
                  <div className="border-b border-[#F5F5F5] py-4">
                    <div className='flex justify-between text-[14px] '>
                      <span className="px-4">Coupon: {coupon.code}</span>
                      <div className='flex items-center'>
                        <span className="px-4">
                          {coupon.percent.toLocaleString('en-GB')}% off{coupon.products.length > 0 ? ' selected' : ''}
                        </span>
                        <XMarkIcon
                          className={'px-2 group hover:cursor-pointer'}
                          svgClassName={'w-[12px] group-hover:stroke-black transition-all ease-in-out duration-200'}
                          clickHandler={removeCoupon}
                        />
                      </div>
                    </div>
                    {
                      coupon.products.length > 0 && !Object.keys(items).some((value) => {return coupon.products.includes(parseInt(value))}) && (
                        <span className='px-4 text-12 text-[#DC2626]'>
                          This coupon does not apply to any of the products in the basket
                        </span>
                      )
                    }
                  </div>
                </>}
                {shipping && <>
                  <div className="flex justify-between border-b border-[#91989F] text-[14px]">
                    <span className="py-4 px-4">{shipping.name}</span>
                    <span
                      className="py-4 px-4">{shipping.price === 0 ? 'Free' : `£${shipping.price.toLocaleString('en-GB', { minimumFractionDigits: 2 })}`}</span>
                  </div>
                </>}
                <div
                  className="flex justify-between border-b border-[#91989F] text-[16px] font-bold">
                  <span className="py-4 px-4">Total</span>
                  <span className="py-4 px-4">
                    £{calculateTotal().toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </span>
                </div>
                <span
                  className="lg:block hidden mt-4 pl-4 font-bold">Shipping charges</span>
                <p className="lg:block hidden pl-4 text-[14px] mb-10">Standard shipping (£2.99) is delivered within 3 to 7 working days of order processing. Express delivery (£4.99) is delivered within 12 to 48 hours of order processing for deliveries within the UK. Outside of the UK will be up to 3 days longer depending on location.</p>
              </div>
            </div>
            {/*checkout buttons mobile*/}
            <div className="lg:hidden flex justify-between pt-6 pb-12">
              <BasketButtons updating={updating} onClick={handleUpdate} onClick1={handleDoneUpdate} />
            </div>
          </div>
          {/*<div className="container max-w-[1200px] mx-auto">*/}
          {/*    <span*/}
          {/*      className="block bg-[#F5F5F5] text-dark-navy py-3 pl-4 rounded-[10px] font-bold lg:max-w-[580px]">You may also like these</span>*/}
          {/*  <div className="mt-10 lg:max-w-[580px] overflow-hidden">*/}
          {/*    <div className="white-flickity-btns basket"*/}
          {/*         data-flickity='{"prevNextButtons": true, "cellAlign": "left", "contain": true, "groupCells": true}'>*/}
          {/*      <div*/}
          {/*        className="w-[178px] h-[230px] bg-[#F5F5F5] mr-[22px] rounded-[16px] overflow-hidden">*/}
          {/*        <img className="w-full h-full object-fit"*/}
          {/*             src="https://source.unsplash.com/600x600?summer"/></div>*/}
          {/*      <div*/}
          {/*        className="w-[178px] h-[230px] bg-[#F5F5F5] mr-[22px] rounded-[16px] overflow-hidden">*/}
          {/*        <img className="w-full h-full object-fit"*/}
          {/*             src="https://source.unsplash.com/600x600?summer"/></div>*/}
          {/*      <div*/}
          {/*        className="w-[178px] h-[230px] bg-[#F5F5F5] mr-[22px] rounded-[16px] overflow-hidden">*/}
          {/*        <img className="w-full h-full object-fit"*/}
          {/*             src="https://source.unsplash.com/600x600?summer"/></div>*/}
          {/*      <div*/}
          {/*        className="w-[178px] h-[230px] bg-[#F5F5F5] mr-[22px] rounded-[16px] overflow-hidden">*/}
          {/*        <img className="w-full h-full object-fit"*/}
          {/*             src="https://source.unsplash.com/600x600?summer"/></div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
}
export default generalContentPage(Basket);
