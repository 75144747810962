import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import { getUserProfileRoutes } from '../../../utils/helpers';
import Avatar from '../../../atoms/Avatar';
import Icon from "../../../Icon";

class UserDropdown extends Component {
  logOut = () => {
    localStorage.removeItem('token');
    window.location = '/';
  };

  render() {
    const {
      user: { current },
      profileRoute,
    } = this.props;

    return (
      <div className="header__profile profile-dropdown">
        <button className="profile-dropdown__btn">

          <Icon name="ico-profile" />

          {/*<Avatar*/}
            {/*src={current.avatar}*/}
            {/*className="profile-dropdown__image"*/}
            {/*fullName={`${current.general.firstName} ${current.general.lastName}`}*/}
          {/*/>*/}
          {/*<span className="profile-dropdown__name" title={`${current.general.firstName} ${current.general.lastName}`}>*/}
            {/*{current.general.firstName} {current.general.lastName}*/}
          {/*</span>*/}
        </button>
        <div className="profile-dropdown__wrap">
          <div className="profile-dropdown__target">
            <ul className="profile-drop-menu">
              {/* <li className="profile-drop-menu__item">
                <Link to={profileRoute.url} className="profile-drop-menu__link">
                  <span className="profile-drop-menu__text">Settings</span>
                  <span className="profile-drop-menu__icon">
                    <i className="fas fa-cog" />
                  </span>
                </Link>
              </li> */}
              <li className="profile-drop-menu__item">
                <button onClick={this.logOut} className="profile-drop-menu__link profile-drop-menu__link--logout">
                  <span className="profile-drop-menu__text">Logout</span>
                  <span className="profile-drop-menu__icon">
                    <i className="fas fa-power-off" />
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

UserDropdown.propTypes = {
  user: PropTypes.object,
  profileRoute: PropTypes.object,
};

const mapStateToProps = store => ({
  user: store.user,
  profileRoute: getUserProfileRoutes(store.menu, 'profile'),
});

export default withRouter(connect(mapStateToProps)(UserDropdown));
