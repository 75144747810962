import React, {useContext, useEffect, useRef, useState} from 'react';

import MetaTags from "../../../atoms/MetaTags";
import generalContentPage from "../../containers/generalContentPage";
import Flickity from "react-flickity-component";
import flickityConfig from "../../../config/flickity-config";
import BlockSignUp from "../../../atoms/BlockSignUp";
import {getProduct} from "../../../utils/api";
import LoadingSpinner from "../../../atoms/LoadingSpinner";
import {BasketContext} from "../../../providers/BasketProvider";
import {Link} from "react-router-dom";
import { useHistory } from "react-router-dom";
import {NotFound} from "../NotFound";

const HandbookPackage = (props) => {
  const { addItem } = useContext(BasketContext);
  const [ added, setAdded ] = useState(false);
  const [ product, setProduct ] = useState(undefined);
  const [ isLoading, setIsLoading ] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getProduct(props.match.params.package).then(product => {
      setProduct(product);
    }).finally(_ => {
      setIsLoading(false);
    });
  }, [props.match.params.package])

  let handleAdd = e => {
    e.preventDefault();
    addItem(product.id, product.name, product.images[0]['thumbUrl'], product.price, product.relatedProducts, [],product.shipping, product.is_virtual);
    setAdded(true);

    setTimeout(() => {
      history.push("/basket");
    }, 500);
  };

  if (!isLoading && !product) {
    return <NotFound />;
  }

  return (
    <>
      <MetaTags title="Mastery Package" pathname={props.location.pathname}/>

      <div className="overflow-hidden lg:pb-[140px] mb-[80px]">
        <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
          {isLoading && (<LoadingSpinner />)}
          {!isLoading && product && (<>
            <div className="grid gap-y-6 md:grid-cols-2 md:gap-x-20 xl:gap-x-60">
              <div className="hidden md:block">
                <Link to={'/mastery/handbook'} className="text-dark-grey underline hover:no-underline uppercase">Back</Link>
              </div>
              <div className="flex items-center mb-8">
                <div
                  className="rounded-full bg-white h-12 aspect-square flex items-center justify-center mr-4">
                  <img className="inline-block h-8" src="/images/mastery-icon.svg" alt="Mastery"/>
                </div>
                <div className="uppercase text-14">
                  Mastery
                </div>
              </div>
              <div className="relative w-full lg:max-w-none max-w-[400px]">
                {product.category.length && (<>
                  <span className="absolute top-0 right-0 bg-yellow text-dark-navy font-bold lg:px-16 px-12 py-3 rounded-bl-2xl rounded-tr-2xl" >{product.category[0]}</span>
                </>)}
                  <img src={product.images.length ? product.images[0]['originalUrl']: undefined} className="w-full rounded-2xl overflow-hidden"
                    loading="lazy"/>
              </div>
              <div className="h-full flex flex-col">
                <div className="mb-6">
                  <strong className="block text-40 lg:text-56 font-heading font-bold max-w-md">{product.name}</strong>
                </div>
                <div className="mb-14">
                  <strong className="block text-40 lg:text-50 font-heading font-bold max-w-md">&pound;{product.price}</strong>
                </div>
                <p className="lg:text-22 text-18 max-w-md mastery-list" dangerouslySetInnerHTML={{__html: product.description }}></p>
                <p className="lg:mt-12 mt-4">
                  {added === false && (
                    <a className="w-full md:w-auto bg-yellow rounded-lg text-center py-3 px-4 mt-12 text-18 inline-block text-dark-navy cursor-pointer" onClick={handleAdd}>Buy package</a>
                  )}
                  {added === true && (
                    <a className="w-full md:w-auto bg-yellow rounded-lg text-center py-3 px-4 mt-12 text-18 inline-block text-dark-navy">Added to basket</a>
                  )}
                </p>
              </div>
            </div>
          </>)}
        </div>
      </div>
      <div className="my-32 lg:mt-32 mt-0">
        <BlockSignUp />
      </div>
    </>
  );
};
export default generalContentPage(HandbookPackage);
