import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import {getMetadata} from "../utils/api";

const MetaTags = props => {
    const [ metaOgImage, setMetaOgImage] = useState('');
    const { shortDescription, body, title } = props;

    useEffect(() => {
        const ele = document.createElement("div");
        ele.innerHTML = body;
        const imageElement = ele.querySelector("img");
        if (imageElement) {
            setMetaOgImage(imageElement.src)
        }
    }, [body]);

    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@grandudes2" />
            <meta name="twitter:creator" content="@grandudes2" />
            <title>{`${title} | grandudes.com`}</title>
            <meta name="description" content={ shortDescription } />
            <meta property="og:title" content={ title } />
            <meta property="og:type" content="article" />
            <meta property="og:description" content={ shortDescription } />
            <meta property="og:url" content={ window.location.href } />
            <meta property="og:image" content={metaOgImage} />
        </Helmet>
    );
};

const MetaTagsOverride = props => {
  const [title, setTitle] = useState(undefined);
  const [description, setDescription] = useState(undefined);

  useEffect(() => {
    getMetadata()
      .then(metadata => {
        const page = metadata.find(item => item.Url === props.pathname);

        if (page) {
          setTitle(page.Title)
          setDescription(page.Description)
        }
      });
  }, [props.pathname]);

  return <MetaTags {...props} title={title || props.title} shortDescription={description || props.title} />
}

export default MetaTagsOverride;
