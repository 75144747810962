import React from 'react';

const Textarea = ({ title, input, placeholder, type, meta: { touched, error, warning } }) => (
  <label className="form__field">
    {title && <span className="form__label-name">{title}</span>}
    <span className="form__label-input">
      <textarea {...input} placeholder={placeholder} />
      {touched &&
        ((error && <span className="error">{error}</span>) || (warning && <span className="error">{warning}</span>))}
    </span>
  </label>
);

export default Textarea;
