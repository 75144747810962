import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Icon from "../Icon";
import connect from "react-redux/es/connect/connect";

const MobileFooterMenu = props => {

    const userId = props.user.signed ? props.user.current.id : '';

    return (
        <div className="mobile-footer-menu">
            <div className="menu-item">
                <Link to="/about-us">
                    <Icon name="ico-respect" /><br />
                    <span className="link-text">About</span>
                </Link>
            </div>
            <div className="menu-item">
              <Link to={`/profile/${userId}/diary`}>
                <Icon name="ico-diary" /><br />
                <span className="link-text">My Diary</span>
              </Link>
            </div>
            <div className="menu-item">
              <Link to={`/profile/${userId}/story`}>
                <Icon name="ico-lifestory" /><br />
                <span className="link-text">My Story</span>
              </Link>
            </div>
            <div className="menu-item">
                <Link to="/stories">
                    <Icon name="ico-article" /><br />
                    <span className="link-text">Stories</span>
                </Link>
            </div>
            <div className="menu-item">
                <Link to={`/profile/${userId}/about`}>
                    <Icon name="ico-profile" /><br />
                    <span className="link-text">Profile</span>
                </Link>
            </div>
        </div>
    )
};

const mapStateToProps = store => ({
    menu: store.menu, user: store.user,
});

export default withRouter(
    connect(
        mapStateToProps,
    )(MobileFooterMenu),
);
