export default ({ slug, text, badge, image, textColour = 'text-white' }) => {
    return (
      <a className="block" href={`/experiences/${slug}`}>
        <div className="relative bg-dark-grey rounded-md h-[277px] mb-3 p-5 overflow-hidden">
          {badge && (
            <div className="inline-block text-dark-navy py-1 px-2 rounded-md text-14 bg-yellow z-10 relative">{badge}</div>
          )}
          <img src={image} className="absolute inset-0 z-0 object-cover w-full h-full" loading="lazy" />
        </div>
        <h4 className={`text-20 ${textColour}`}>{text}</h4>
      </a>
    );
  }
