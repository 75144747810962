import React, {useEffect, useRef, useState} from 'react';

import MetaTags from "../../../atoms/MetaTags";
import generalContentPage from "../../containers/generalContentPage";
import Flickity from "react-flickity-component";
import flickityConfig from "../../../config/flickity-config";
import BlockSignUp from "../../../atoms/BlockSignUp";
import {Link} from "react-router-dom";
import {getPackages, searchProducts} from "../../../utils/api";
import LoadingSpinner from "../../../atoms/LoadingSpinner";
import PageBlockPackagesGrid from "../../PageBlocks/PackagesGrid";

function ImageWithSmallBackgroundShape({image_url, image_classes, shape_position}) {
  let base_shape_classes = 'absolute lg:h-80 sm:h-56 h-48 -z-10 lg:-bottom-56 sm:-bottom-36 -bottom-24';
  if (shape_position === 'right') {
    base_shape_classes += ' -scale-x-100 -right-[80px] right-12';
  } else {
    base_shape_classes += ' lg:-left-[80px] left-12';
  }
  return (
    <div className="relative">
      <img src={image_url} alt="Photo" className={image_classes}/>
      <img src="/images/yellow-background-shape.svg" className={base_shape_classes}/>
    </div>
  );
}

const Handbook = (props) => {
  const packages = useRef();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ products, setProducts ] = useState(undefined);

  useEffect(() => {
    getPackages('Mastery handbook for men').then(data => {
      setProducts(data);
      setIsLoading(false);
    });
  }, []);

  const handleDiscoverPackages = (e) => {
    e.preventDefault();
    packages.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <>
      <MetaTags title="Mastery Handbook" pathname={props.location.pathname}/>

      <div className="overflow-hidden background-shapes yellow lg:pb-[140px] pb-[80px] mb-[80px]">
        <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
          <div className="flex items-center mb-8">
            <div
              className="rounded-full bg-white h-[50px] aspect-square flex items-center justify-center mr-4">
              <img className="inline-block h-[35px]" src="/images/mastery-icon.svg" alt="Mastery"/>
            </div>
            <div className="uppercase text-14">
              Mastery
            </div>
          </div>
          <div className="mb-10">
            <strong className="block text-40 lg:text-60 font-heading font-bold max-w-lg">Discover
              the Ultimate
              Handbook to personally
              design your <span className="text-yellow">ideal life.</span></strong>
          </div>
          <p className="lg:text-22 text-18 max-w-lg">This unique interactive guide is a blueprint
            for you to become the Master of your own life. It ensures you make the very most of your
            remaining time on earth – and there are some incredible experiences, and relationships
            ahead!</p>
          <p className="mt-12">
            <a className="bg-yellow rounded-md py-2 px-4 inline-block text-dark-navy cursor-pointer" onClick={handleDiscoverPackages}>Discover
              packages</a>
          </p>
        </div>
        {/* IMAGE AND TEXT */}
        <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 lg:pb-[50px] lg:flex lg:gap-20 xl:gap-0">
          {/* TEXT - RIGHT*/}
          <div className="order-2 flex-1 xl:ml-[150px] lg:mt-20">
            <h2 className="block text-40 lg:text-60 font-heading font-bold max-w-lg">If you are
              ready to take charge of your <span className="text-yellow">future</span></h2>
            <p className="mt-4 lg:text-22 text-18 max-w-lg">and want a clear action plan, the
              Grandudes Mastery handbook is just that. We help you identify a sense of purpose and
              specific achievable goals to ensure you lead a life well lived.</p>
            <p className="mt-4 max-w-lg">Because the handbook empowers you to find your own path,
              and recognises that not one size fits all, it is continually relevant and is modified
              to keep you on track.</p>
            <p className="mt-4 max-w-lg">Whether you are looking to enhance an already fantastic
              life, or break out of an unfulfilling rut, the Mastery handbook is an adaptable
              toolkit to ensure you are truly living your best life.</p>
          </div>
          {/* IMAGE ROUNDED - LEFT */}
          <div
            className="block relative rounded-xl w-screen h-screen max-w-[270px] max-h-[353px] md:max-w-[445px] md:max-h-[595px] xl:max-w-[559px] xl:max-h-[732px] overflow-hidden xl:mt-0 mt-14">
            <img src="/images/handbook-image1.png" alt="Photo"
                 className="absolute inset-0 h-full w-full object-cover"/>
          </div>
        </div>
      </div>

      <div className="bg-mid-grey w-full overflow-x-clip">
        {/* BLOCK CONTENT */}
        <div
          className="max-w-[1200px] px-pad-sm mx-auto flex lg:flex-row flex-col lg:items-center items-end lg:space-x-[150px] py-14 lg:mb-[150px] mb-[360px]">
          <div className="flex-1">
            <h2 className="font-heading font-bold text-36 lg:text-50 mb-6 max-w-lg">This unique <strong
              className="text-yellow">Mastery</strong> guide has been created so that men over 50
              can discover what they want, and how to achieve it.</h2>
            <p className="max-w-lg">Enveloped within the rich interactive content, the Mastery Handbook also connects you to real people who can help you along the path. The comprehensive connections ensure that you are never alone and they are all cherry picked for their ability to relate to your challenges and goals. From mentors and life coaches, to hypnotherapists and psychologists; from art and music teachers to fitness and nutrition experts. Not only will you find the means and the motivation to improve your health to enjoy longevity in a more qualitative way, but you will also engage in areas of life that you have not been acquainted with before. We show you how to hit the reset button and see things with fresh eyes but with the benefit of experience.</p>
            <p className="mt-4 max-w-lg">Get crystal clear about what you really want, where you want to be, with whom, how and why. This is probably something you have never truly addressed fully before; and you will unveil new horizons for fulfilment and joy, along with a deep sense of awe.</p>
          </div>

          <div
            className="translate-x-[100px] mb-[-320px] lg:translate-x-0 xl:mb-0 mt-14 xl:mt-0 xl:h-[700px] xl:translate-y-[200px]">
            <div className="relative">
              {/* IMAGE */}
              <div
                className="relative rounded-xl w-screen h-screen max-w-[270px] max-h-[353px] md:max-w-[445px] md:max-h-[595px] xl:max-w-[559px] xl:max-h-[732px] overflow-hidden">
                <img src="/images/handbook-image2.png" alt="Photo"
                     className="absolute inset-0 h-full w-full object-cover"/>
              </div>
              {/* BRAND SHAPE */}
              <img src="/images/background-shape2-yellow.svg"
                   className="absolute 2xl:-bottom-60 xl:-bottom-48 lg:-bottom-32 md:-bottom-40 -bottom-24 right-0 -scale-x-100 md:-mr-pad-sm mr-[20px] xl:translate-x-[calc(100vw-1200px)] md:w-auto w-[178px] -z-10"/>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1200px] px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
        <div className="mb-10">
          <strong className="block text-40 lg:text-60 font-heading font-bold max-w-lg">In the Grandudes Handbook of <span className="text-yellow">Mastery</span></strong>
        </div>
        <p className="lg:text-22 text-18 max-w-lg">we assess your life to date, help you find your North Star and set out your journey forward.</p>
        <p className="mt-4 max-w-lg">Our 15 chapters cover planning for every aspect of your life – from setting and achieving your goals, getting healthier and fitter than you thought possible, reversing your chronological age, regaining energy and vitality, looking younger, cooler and more attractive, being respected and admired, managing and improving your finances, and so much more. We show you how to spend your time to maximise personal satisfaction, choose where to live, acquire new interests and skills you will love, meet new people and improve existing relationships. Within the Handbook are links to connect you to selected experts so that you are never alone. You will also be assigned to a Grandudes support partner should you wish to reach our for anything bespoke or for further clarification and help.</p>
      </div>
      <div className="mt-0" ref={packages}>
        {isLoading && (<LoadingSpinner />)}
        {!isLoading && (<PageBlockPackagesGrid products={products} route="/mastery/handbook" className='xl:mt-16' bg_color='navy' text_colour='white' title={<>To start on this incredible journey choose one of the options below</>}/>)}
      </div>
      <div className="my-32">
        <BlockSignUp />
      </div>
    </>
  );
};
export default generalContentPage(Handbook);
