import React, { useState, useEffect, Fragment } from 'react';

import generalPage from '../../containers/generalPage';

import singleHelpfulLinkConfig from '../../../config/helpful-links-config';

import { getHelpfulLinks } from "../../../utils/api";
import MetaTags from "../../../atoms/MetaTags";
import ButtonViewMore from "../../../atoms/ButtonViewMore";
import generalContentPage from "../../containers/generalContentPage";
import Button from "../../../atoms/Button";
import helpfulLinkCategories from "../../../config/helpful-links-config";
import NotFound from "../NotFound";
import LoadingBlock from "../../../atoms/LoadingBlock";
import {Link} from "react-router-dom";
import ButtonFilter from "../../../atoms/ButtonFilter";
import LoadingSpinner from "../../../atoms/LoadingSpinner";
import BlockSignUp from "../../../atoms/BlockSignUp";


function NextArrow({classes}) {
  classes += ' hover:cursor-pointer';
  return (
    <svg width="12" height="87" viewBox="0 0 12 87" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path d="M5.46967 86.5303C5.76257 86.8232 6.23744 86.8232 6.53033 86.5303L11.3033 81.7574C11.5962 81.4645 11.5962 80.9896 11.3033 80.6967C11.0104 80.4038 10.5355 80.4038 10.2426 80.6967L6 84.9393L1.75736 80.6967C1.46447 80.4038 0.989596 80.4038 0.696703 80.6967C0.403809 80.9896 0.403809 81.4645 0.696703 81.7574L5.46967 86.5303ZM5.25 3.27835e-08L5.25 86L6.75 86L6.75 -3.27835e-08L5.25 3.27835e-08Z" fill="#FFD23F"/>
    </svg>
  );
}

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

const FilterPanel = ({ slug, category, categories }) => {
  const [ showDropdown, setShowDropdown ] = useState(false);

  return (
    <>
      <div>
        <div className="hidden xl:block">
          Filter by
        </div>
        <div className="xl:hidden">
          <a className="bg-yellow rounded-md py-2 px-4 block text-white flex items-center"
             href="#" onClick={(e) => {
            e.preventDefault();
            setShowDropdown(current => !current);
          }}>
            Filter
            <img className="ml-2" src="/images/icon-chevron-down.svg" alt="Down"/>
          </a>
          <div className={"bg-light-grey py-2 " + (showDropdown ? '' : 'hidden')}>
            <Link to={`/toolkit/${slug}`} component={ButtonFilter} active={!category}>All</Link>
            {categories.map(subCat => (
              <Link key={subCat} to={`/toolkit/${slug}/${slugify(subCat)}`} component={ButtonFilter} active={category === slugify(subCat)}>{subCat}</Link>
            ))}
          </div>
        </div>
      </div>
      <div className="hidden xl:block xl:space-x-4 xl:flex-1">
        <Link to={`/toolkit/${slug}`} component={Button} colour={!category ? 'bg-yellow text-dark-navy' : 'bg-dark-grey'}>
          All
        </Link>
        {categories.map(subCat => (
          <Link key={subCat} to={`/toolkit/${slug}/${slugify(subCat)}`} component={Button} colour={category === slugify(subCat) ? 'bg-yellow text-dark-navy' : 'bg-dark-grey'}>
            {subCat}
          </Link>
        ))}
      </div>
    </>
  )
}

function SmallCard({icon, url, title, description, buttonText, icon_padding, classes}) {
  let base_classes = 'sm:rounded-xl p-16 bg-light-grey';
  if(!classes) {
    classes = base_classes;
  } else {
    classes = classes + ' ' + base_classes;
  }
  return (
    <div className={classes}>
      <img src={icon} className="h-8 mb-12 object-cover"/>
      <h6 className="font-heading font-bold text-22 mb-4">{title}</h6>
      <p className="mb-8">{description}</p>
      <Button colour="bg-yellow" href={url} target="_blank">
        {buttonText}
      </Button>
    </div>
  );
}


const SingleHelpfulLink = ({ match: { params: { slug, category } }, location }) => {
    const [ activeHelpfulLinkData, setActiveHelpfulLinkData ] = useState([]);
    const [ is404, setIs404 ] = useState(false);
    const [ currentCategory, setCurrentCategory ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ categories, setCategories ] = useState([]);
    const [ helpfulLinksData, setHelpfulLinksData] = useState(undefined);

    useEffect(() => {
        getHelpfulLinks().then(result => {
          setHelpfulLinksData(result);
          setLoading(false);
        });
    }, []);

    useEffect(() => {
      for (let item of singleHelpfulLinkConfig) {
        if (item.slug === slug) {
          setCurrentCategory(item);
          return;
        }
      }

      setIs404(true);
    }, [slug]);

    useEffect(() => {
        if (helpfulLinksData && helpfulLinksData.byCategory[currentCategory.title]) {
            const items = helpfulLinksData.byCategory[currentCategory.title];
            setActiveHelpfulLinkData(items.filter(item => !category || slugify(item.category) === category));
            setCategories(items.map(item => item.category).filter((v, i, a) => a.indexOf(v) === i));
        }
    }, [currentCategory, helpfulLinksData]);

    return (
        <>
            <MetaTags title={`Toolkit - ${loading ? 'Loading' : currentCategory.title}`} pathname={location.pathname} />

            <div className="background-shape">
              <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-gap-sm md:pb-gap-md lg:pb-gap-lg">
                <div className="flex items-center">
                  <div className="rounded-full bg-white h-[50px] aspect-square flex items-center justify-center mr-4">
                    <img className="inline-block h-[35px]" src="/images/health-icon.svg" alt="Health"/>
                  </div>
                  <div className="uppercase text-14">
                    Toolkit
                  </div>
                </div>
                <h1 className="font-heading font-bold text-40 lg:text-60 py-6 xl:py-8 max-w-[600px]">
                  {currentCategory.title}
                </h1>
                <div className="max-w-[600px]">
                  {currentCategory.intro}
                </div>
              </div>
              <div className="relative md:pb-gap-md lg:pb-gap-lg">
                <div className="container xl:py-[120px] px-pad-sm mx-auto xl:pt-0 grid md:grid-cols-2 lg:gap-40 gap-10">
                  <div className="relative">
                    <img src={currentCategory.image1} className="rounded-xl w-full md:ml-0 -ml-28"/>
                    <img src="/images/yellow-background-shape.svg" className="absolute md:w-full w-1/2 2xl:-bottom-60 xl:-bottom-48 lg:-bottom-32 md:-bottom-8 sm:-bottom-32 -bottom-24 left-0 right-1/2 -translate-x-1/2 -z-10"/>
                  </div>
                  <div className="flex flex-col items-start justify-between py-16">
                    <h4 className="font-heading font-bold xl:text-40 text-30 max-w-lg">{currentCategory.intro2}</h4>
                    <NextArrow classes="md:block hidden"/>
                  </div>
                </div>
              </div>
              <div className="container sm:px-pad-sm mx-auto pb-gap-sm md:pb-gap-md lg:pb-gap-lg">
                <div className="flex px-pad-sm sm:px-0 items-start space-x-5 xl:space-x-10 xl:items-center justify-between py-8 xl:py-14 relative">
                  <FilterPanel slug={slug} category={category} categories={categories} />
                </div>
                <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-16">
                  {loading && <LoadingBlock colour="black" size={10} text="Loading" classNames="align-center" />}
                  {!loading && activeHelpfulLinkData.length === 0 && (
                    <div>
                      Sorry, nothing has been added to this category yet.
                    </div>
                  )}
                  {!loading && activeHelpfulLinkData.map(link => (
                    <SmallCard key={link.linkId} icon={link.linkLogo} url={link.websiteUrl} title={link.title} description={(
                      <div className="wysiwyg" dangerouslySetInnerHTML={{__html: link.description }}></div>
                    )} buttonText="View Resource"/>
                  ))}
                </div>
              </div>
            </div>
            <div className="mb-32">
              <BlockSignUp />
            </div>
        </>
    );

  /**
   *
   *             <div className="single--helpful__title">Toolkit - {currentCategory}</div>
   *             <div className="single--helpful__content-wrapper">
   *                 {
   *                     activeHelpfulLinkData && activeHelpfulLinkData.map(helpfulLinkItemData =>
   *                         <div className="single--helpful__item" key={helpfulLinkItemData.linkId}>
   *                             {helpfulLinkItemData.bannerImage ?
   *                               <div className="banner-image" style={{ marginBottom: 12}}>
   *                                 <img src={helpfulLinkItemData.bannerImage}/>
   *                               </div>
   *                               :
   *                               <div className="logo">
   *                                 <img src={helpfulLinkItemData.linkLogo} style={{height: 38}} />
   *                               </div>
   *                             }
   *                             <div className="title">{helpfulLinkItemData.name}</div>
   *                             <div className="website">
   *                                 <a href={helpfulLinkItemData.websiteUrl} target="_blank">
   *                                     {helpfulLinkItemData.websiteUrl}
   *                                 </a>
   *                             </div>
   *                             <div className="contact">
   *                                 {helpfulLinkItemData.contactNumber !=null &&
   *                                     <a href={`tel:${helpfulLinkItemData.contactNumber}`} target="_blank">
   *                                         {`${helpfulLinkItemData.contactNumber} ${helpfulLinkItemData.openingHours}`}
   *                                     </a>
   *                                 }
   *                             </div>
   *                             <div className="description">{helpfulLinkItemData.description}</div>
   *                             <div className="cta">
   *                                 <a href={helpfulLinkItemData.websiteUrl} target="_blank">Visit Website</a>
   *                             </div>
   *                         </div>
   *                     )
   *                 }
   *             </div>
   */
};

export default generalContentPage(SingleHelpfulLink);
