import React, {useEffect, useState} from 'react';

import Button from "./Button";
import {Link} from "react-router-dom";

function NeedHelp({
  text = <>If you are interested in leaving messages or
    memoirs and would like to know more before going ahead please do contact us here and we
    will get back to you promptly.</>
}) {
  return <div className="container p-pad-sm mx-auto py-gap-sm md:py-gap-md lg:py-gap-lg">
    <div className="bg-dark-navy rounded-xl grid lg:grid-cols-2">
      <div className="relative lg:order-1 order-2">
        <img src="/images/need-help-person.svg"
             className="lg:absolute bottom-0 left-0 max-w-[514px] w-full object-cover"/>
      </div>
      <div
        className="flex justify-center items-start flex-col lg:px-pad-sm p-gap-sm md:p-gap-md lg:p-gap-lg text-white order-1">
        <h2 className="lg:text-56 text-40 font-heading font-bold mb-4">Need help?</h2>
        <p className="lg:text-20 text-16 max-w-xs mb-8">{text}</p>
        <Link to="/contact" component={Button} colour="bg-yellow text-dark-navy" classes="inline-block">
          Contact us
        </Link>
      </div>
    </div>
  </div>;
}

export default NeedHelp;
