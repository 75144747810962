export const GALLERY_UPDATE_IMAGES = 'GALLERY_UPDATE_IMAGES';
export const galleryUpdateImages = data => dispatch => dispatch({ type: GALLERY_UPDATE_IMAGES, payload: data });

export const GALLERY_CLOSE = 'GALLERY_CLOSE';
export const galleryClose = () => dispatch => dispatch({ type: GALLERY_CLOSE });

export const GALLERY_SET_CURRENT = 'GALLERY_SET_CURRENT';
export const gallerySetCurrent = id => dispatch => dispatch({ type: GALLERY_SET_CURRENT, payload: id });

export const GALLERY_LOADING = 'GALLERY_LOADING';

export const GALLERY_OPEN = 'GALLERY_OPEN';
export const galleryOpen = id => dispatch => {
  dispatch({
    type: GALLERY_LOADING,
  });
  dispatch(gallerySetCurrent(id));
  dispatch({
    type: GALLERY_OPEN,
  });
};
