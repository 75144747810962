import React, {useEffect, useState} from 'react';

import MetaTags from "../../../atoms/MetaTags";
import generalContentPage from "../../containers/generalContentPage";
import Flickity from "react-flickity-component";
import flickityConfig from "../../../config/flickity-config";

function LearningQuote({ name, description, quote}) {
  return <div className="w-full grid md:grid-cols-3 gap-x-40 lg:pb-36">
    <div className="w-full">
      <h2 className="block text-40 lg:text-2xl font-heading font-bold">{name}</h2>
      <p className="pt-4">{description}</p>
    </div>

    <div className="w-full col-span-2">
      <strong className="block text-50 lg:text-90 font-heading font-bold">“</strong>
      <figure>
        <blockquote className="block text-20 lg:text-30 font-heading font-bold">
          <p>{quote}</p>
        </blockquote>
      </figure>
    </div>
  </div>;
}

function ButtonLink({title, link}) {
  return <div className="flex justify-between items-center p-3 rounded-md bg-light-grey">
    <p>{title}</p>
    <a href={link} className="bg-yellow text-dark-navy py-2 px-4 rounded-md text-14 flex ">
      Visit site
      <img src="/images/icon-chevron-down.svg" className="ml-3 -rotate-90"/>
    </a>
  </div>;
}

const Learning = (props) => {
  const quotes = [
    {
      name: 'Henry Ford',
      description: 'Henry Ford who started the Ford Motor Company, was one of the richest men in history, He was a philanthropist and championed social injustices.',
      quote: 'Anyone who stops learning is old, whether at 20 or 80. Anyone who keeps learning stays young'
    },
    {
      name: 'Albert Einstein',
      description: 'Albert Einstein is acknowledged to be one of the greatest and most influential physicists ever. He is most well known for his theory of relativity.',
      quote: 'Wisdom is not a product of schooling, but of the lifelong attempt to acquire it'
    },
    {
      name: 'Mahatma Gandhi',
      description: 'Mahatma Gandhi led the successful campaign for Indian independence from British rule, via non violent action. He became a global spiritual leader.',
      quote: 'Live as if you were to die tomorrow. Learn as if you were to live forever'
    },
    {
      name: 'Chinese Proverb',
      description: 'Chinese proverbs, encapsulate the life long lessons of ancient philosophers and other revered individuals, including Confucius and Lao Tzu. ',
      quote: 'A man who asks is a fool for 5 mins. A man who never asks is a fool for life'
    },
  ];

  const buttons = [
    {
      'title' : 'Music',
      'link' : '',
    },
    {
      'title' : 'Languages',
      'link' : '',
    },
    {
      'title' : 'Food and Wine',
      'link' : '',
    },
    {
      'title' : 'Health and Wellness',
      'link' : '',
    },
    {
      'title' : 'Sport and Fitness',
      'link' : '',
    },
    {
      'title' : 'Art and Design',
      'link' : '',
    },
    {
      'title' : 'Crafts and Hobbies',
      'link' : '',
    },
    {
      'title' : 'Culture and History',
      'link' : '',
    },
    {
      'title' : 'Literature',
      'link' : '',
    },
    {
      'title' : 'Travel and Geography',
      'link' : '',
    },
    {
      'title' : 'Technical and Practical',
      'link' : '',
    },
    {
      'title' : 'IT and Media',
      'link' : '',
    },
  ];

  return (
    <>
      <MetaTags title="Learning" pathname={props.location.pathname}/>

      <div className="overflow-hidden background-shapes">
        <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
          <div className="flex items-center mb-8">
            <div
              className="rounded-full bg-white h-[50px] aspect-square flex items-center justify-center mr-4">
              <img className="inline-block h-[35px]" src="/images/mastery-icon.svg" alt="Mastery"/>
            </div>
            <div className="uppercase text-14">
              Mastery
            </div>
          </div>
          <div className="mb-10">
            <strong className="block text-40 lg:text-60 font-heading font-bold">Learning</strong>
          </div>
          <p className="lg:text-22 text-18 max-w-lg">Research shows that the learning process begins in the womb and continues until death. Yet some of us experience boredom, and seemingly have no desire to learn, passing our days away on repeat.</p>

          <p className="mt-4 max-w-lg">All it takes is some time and reflection to break out of this humdrum existence and discover that our world could be flooded with rewarding, exciting, awe-inspiring and gratifying experiences.</p>
          <p className="mt-12">
            <a className="bg-yellow rounded-md py-2 px-4 inline-block text-white">Coming soon</a>
          </p>
        </div>
        {/* IMAGE AND TEXT */}
        <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px] xl:flex">
          {/* TEXT - RIGHT*/}
          <div className="order-2 flex-1 xl:ml-[150px] lg:mt-20">
            <h2 className="block text-40 lg:text-60 font-heading font-bold max-w-lg">The foundation of all growth is curiosity and learning</h2>
            <p className="mt-4 max-w-lg">Too often we associate learning with academia or enforced activities. This may have turned us away from so many wonderful practical and theoretical, literary and artistic, challenging and relaxing pastimes and accomplishments. Now is your time to ignite that passion for life; and at Grandudes we are here to show you how.</p>
          </div>
          {/* IMAGE ROUNDED - LEFT */}
          <div className="relative rounded-xl w-screen h-screen max-w-[270px] max-h-[353px] md:max-w-[445px] md:max-h-[595px] xl:max-w-[559px] xl:max-h-[732px] overflow-hidden xl:mt-0 mt-14">
            <img  src="/images/man-at-desk.jpg" alt="Photo" className="absolute inset-0 h-full w-full object-cover"/>
          </div>
        </div>
        <div className="bg-yellow mt-12 text-white">
          <div
            className="container md:p-pad-lg p-pad-md mx-auto md:gap-32 gap-16 py-gap-sm md:py-36 items-center overflow-hidden">
            <Flickity
              className={'slider slider-with-btns dots overflow-hidden'}
              options={flickityConfig}
            >
              {quotes.map(quote => <LearningQuote {...quote} />)}
            </Flickity>
          </div>
        </div>
        {/* TEXT AND BUTTONS */}
        <div className="md:mt-36 my-gap-sm">
          <div
              className="max-w-[1200px] px-pad-sm mx-auto py-14">
            <div className="max-w-[625px] mb-20">
              <h2 className="font-heading font-bold text-36 lg:text-50 mb-6">At <strong className="text-yellow">Grandudes</strong>, we will soon have information and resources for an array of learning opportunities</h2>
            </div>
            <div className="flex lg:flex-row flex-col lg:gap-40 gap-12 items-start">
              <div className="max-w-[477px]">
                <p>Don’t think that because you are on the other side of 50 that there is anything off-limits. Armed with an open mind and imagination to explore things you may not have considered before, and our upcoming resources, now is the time to light a new fire within.</p>
              </div>
              {/* LIST OF BUTTONS */}
              {/*<div className="flex flex-col gap-4 w-full">*/}
              {/*  {buttons.map(button => <ButtonLink {...button} />)}*/}
              {/*</div>*/}
            </div>
            </div>
        </div>
      </div>
    </>
  );
};
export default generalContentPage(Learning);
