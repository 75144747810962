import React from 'react';
import { Link } from 'react-router-dom';
import generalPage from '../../containers/generalPage';
import MetaTags from "../../../atoms/MetaTags";
import generalContentPage from "../../containers/generalContentPage";
import Button from "../../../atoms/Button";

export const NotFound = props => {
  return (
    <>
      <MetaTags title="Page not found" />

      <div className="bg-yellow background-shapes-dark h-screen max-h-screen flex justify-center items-center py-gap-sm md:py-gap-md lg:py-gap-lg">
        <div className="container mx-auto px-pad-sm">
          <div className="max-w-sm mx-auto text-white text-center">
            <h1 className="font-heading font-bold md:text-[100px] text-40 lg:mb-32 md:mb-28 mb-16">Ooops!</h1>
            <p className="font-heading text-22 mb-8">404 - page not found</p>
            <p className="lg:mb-16 mb-12"></p>
            <Link to={'/'} component={Button} colour="bg-dark-navy">
              Go to home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default generalContentPage(NotFound);
