import React, {useContext, useEffect, useState} from 'react';
import Address from "./Address";
import {BasketContext} from "../../../providers/BasketProvider";
import LoadingSpinner from "../../../atoms/LoadingSpinner";
import InfoIcon from '../../../Icon/assets/icon-info.svg';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const ShippingOptions = ({shipping, availableShippingOptions, countryShipping, setShipping, basketItems}) => {
  if(shipping && Object.keys(shipping).length > 0) {
    return (
      <>
        <span className="col-span-2 font-bold">Delivery Options</span>
        {
          countryShipping.map(option => {
            let unavailableProducts = Object.values(basketItems).filter(item => item.shipping && item.shipping.length > 0 && !item.shipping.includes(parseInt(option.id))).map(item => item.name).join(', ');
            return (<>
              <div className="col-span-1 rounded-[10px] py-3.5 px-5 border-[#ffd23f] border grow"
                  key={option.id}>
                <label
                  className={`flex flex-row items-center hover:cursor-pointer grow ${availableShippingOptions.includes(parseInt(option.id, 10)) ? '' : 'opacity-50'}`}
                  htmlFor={`delivery_${option.id}`}>
                  <input name="delivery_options" id={`delivery_${option.id}`} type="radio"
                        value={option.id} checked={shipping.id === option.id}
                        onChange={e => {
                          if (e.target.checked) {
                            setShipping(option.id, option.name, option.price);
                          }
                        }}
                        disabled={!availableShippingOptions.includes(parseInt(option.id, 10))}
                  />
                  <span
                    className="ml-6 hover:cursor-pointer grow">{option.name}</span>
                  <span
                    className="ml-6 block">{option.price == 0 ? '' : `£${option.price.toLocaleString('en-GB', {minimumFractionDigits: 2})}`}</span>
                </label>
              </div>
              <div className="col-span-1 flex items-center">
                {!availableShippingOptions.includes(parseInt(option.id, 10)) && (<>
                  <Tippy content={`This option is not available for product${unavailableProducts.length > 1 ? 's' : ''} ${unavailableProducts}`}>
                    <img src={InfoIcon} alt="Info" className="w-6 h-6"/>
                  </Tippy>
                </>)}
              </div>
            </>);
          })
        }
      </>
    )
  }
  return null;
}

export default function Delivery({ checkout, handleInputChange, handleSetSameAsBilling, handleNextStep, handlePrevStep }) {
  const {
    setShipping,
    shipping,
    shippingOptions,
    availableShippingOptions,
    items,
  } = useContext(BasketContext);
  const [validation, setValidation] = useState({
    address: false,
  })

  if (!shippingOptions) {
    return <LoadingSpinner />;
  }

  // let countryShipping = checkout.billing_country ? Object.values(shippingOptions).filter(option => [option.country].includes(checkout.billing_country)) : Object.values(shippingOptions);
  let countryShipping = Object.values(shippingOptions);
  countryShipping = countryShipping.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))

  return <>
    <a
      href="#"
      onClick={handlePrevStep}
      className={`col-span-2 block w-full rounded-[10px] py-3.5 px-5 mb-5 text-white font-bold bg-dark-navy`}>Details</a>

    <a
      className={`col-span-2 block w-full rounded-[10px] py-3.5 px-5 mb-5 bg-yellow text-dark-navy`}>Delivery</a>

    <form className="full grid grid-cols-2 mx-auto gap-y-6 gap-x-5 mb-8" onSubmit={handleNextStep}>
      {/*BILLING ADDRESS*/}
      <div className="col-span-2 mt-4">
        <input id="billing" type="checkbox" name="same_as_billing" value="1" checked={checkout.same_as_billing} onChange={handleSetSameAsBilling} />
          <label htmlFor="billing"><span className="ml-3">Use same as billing address</span></label>
      </div>
      {/*DELIVERY ADDRESS*/}
      {!checkout.same_as_billing && <>
        <span className="col-span-2 font-bold">Delivery Address*</span>
        <Address prefix="shipping_" checkout={checkout} handleInputChange={handleInputChange} validationDetails={validation} />
      </>}
      {/* DELIVERY OPTIONS */}
      <ShippingOptions shipping={shipping} setShipping={setShipping} availableShippingOptions={availableShippingOptions} countryShipping={countryShipping} basketItems={items} />
      
      <div className="col-span-2 flex justify-end">
        <input
          className={"hover:cursor-pointer bg-yellow text-dark-navy py-3 px-4 rounded-[8px]" + ( !checkout.same_as_billing && validation.address == false ? ' opacity-50' : '' )}
          type="submit" value="Review and Pay" 
          disabled={ !checkout.same_as_billing && validation.address == false }
          />
      </div>
    </form>

    <span
      className={`col-span-2 block w-full rounded-[10px] py-3.5 px-5 mb-5 text-[#91989F] font-bold bg-[#F5F5F5]`}>Review &amp; pay</span>
  </>;
}
