// Polyfills must be first
import 'react-app-polyfill/ie11';
import './polyfills';

import React from 'react';
import {hydrate, render} from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import App from './App';
import rootReducer from './reducers';
import { getInitMenu } from './reducers/menu';

import './scss/index.scss';
import * as serviceWorker from './serviceWorker';

import { getMe } from './utils/api/get';
import { getJWT } from './utils';

const Init = () => {
  return getJWT() ? getMe() : Promise.resolve(false);
};

Init().then(data => {
  const user = { isLoading: false, isError: null, signed: true, current: data };

  const initialUser = data ? { user, menu: getInitMenu(user.current.id) } : {};

  const initialState = {
    ...initialUser,
  };
  const logger = createLogger({
    collapsed: true,
  });
  const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware, logger)));

  let app = <Provider store={store}>
    <App />
  </Provider>;

  const rootElement = document.getElementById("root");
  if (rootElement.hasChildNodes()) {
    hydrate(app, rootElement);
  } else {
    render(app, rootElement);
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
