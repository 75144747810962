import React from "react"
import ImageWithSmallBackgroundShape from "./Partials/ImageWithSmallBackgroundShape";

function PageBlockHangingImageAndText({img_src, image_side = 'left', children}) {

    let img_on_right = image_side === 'right';
    console.log(image_side, img_on_right);

    return (
        <div className="bg-mid-grey relative">
            <div className="max-w-[1200px] px-pad-sm mx-auto lg:grid lg:grid-cols-2 items-center py-24 mb-[625px] xl:mb-0 xl:gap-32 lg:gap-20">
                {/* text */}
                <div className={img_on_right ? 'order-0' : 'order-2' + ' flex-1'}>
                    {children}
                </div>
                {/* image */}
                <div className={'rountheded-xl max-w-[559px] mb-[-500px] lg:translate-x-0 xl:mb-0 mt-14 xl:mt-0 xl:h-[700px] xl:translate-y-[200px] ' + (img_on_right ? 'lg:translate-x-[100px] translate-x-[calc(100vw-100%)]' : 'translate-x-[-100px]')}>
                    <ImageWithSmallBackgroundShape 
                        image_url={img_src}
                        shape_position={img_on_right ? 'right' : 'left'} 
                        image_classes="rounded-xl" 
                        shape_classes={img_on_right ? '!right-0 lg:translate-x-[calc(50vw-600px)]' : ''}
                    />
                </div>
            </div>
        </div>
    );
}

export default PageBlockHangingImageAndText;