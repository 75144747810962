import moment from 'moment';

export const isServer = () => typeof localStorage === 'undefined';

export const isTest = () => process.env.NODE_ENV === 'test';

export const urlGetParametersParser = (url, key) => {
  const regex = /[?&]+([^=&]+)=([^&]*)/gi;
  let m;
  // eslint-disable-next-line
  while ((m = regex.exec(url)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    if (m[1] === key) {
      return m[2];
    }
  }
  return false;
};

export const getUserProfileRoutes = (menu, aliases, userId) => {
  let arr = [];
  const getItem = onItem => menu.filter(i => i.alias === onItem)[0];
  const isArray = Array.isArray(aliases);
  if (isArray) {
    aliases.forEach(item => {
      arr.push(getItem(item));
    });

    const _arr = arr.map(item => {
      const resultUrl = item.url
        .split('/')
        .map((item, index) => {
          if (index === 2) {
            return userId;
          }
          return item;
        })
        .join('/');
      return { ...item, url: resultUrl };
    });
    arr = userId ? [..._arr] : [...arr];
    return arr.length > 1 ? arr : arr[0];
  }

  return getItem(aliases);
};

export const randomID = () =>
  Math.random()
    .toString(36)
    .substr(2, 9); // Create custom random ID

export const formatDate = (inputDate = moment().format('YYYY-MM-DD HH:mm:ss'), format = 'Do MMM. YY') => {
  return moment(inputDate, 'YYYY-MM-DD HH:mm:ss').format(format);
};

export const catchExpiredToken = error => {
  if (error.response && error.response.data) {
    const { status } = error.response.data;
    // Redirect to signIn when user token is expired
    if (status === 'token_is_expired') {
      localStorage.removeItem('token');
      window.location.reload();
    }
  }
};


export const dataUrlToFile = dataURI => {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const stripStringToWordBoundary = (body, n, useWordBoundary) => {
    if (!body) return;
    if (body.length <= n) { return body; }
    const subString = body.substr(0, n-1);
    return (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(' '))
        : subString) + "&hellip;";
};


const isObject = val => {
    return typeof val === 'object' && val !== null;
};

export const classnames = (...args) => {
    const classes = [];
    args.forEach(arg => {
        if (typeof arg === 'string') {
            classes.push(arg);
        } else if (isObject(arg)) {
            Object.keys(arg).forEach(key => {
                if (arg[key]) {
                    classes.push(key);
                }
            });
        } else {
            throw new Error(
                '`classnames` only accepts string or object as arguments'
            );
        }
    });

    return classes.join(' ');
};

export const calculateDistanceInKm = (lat1, lon1, lat2, lon2) => {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat2 - lat1) * p) / 2
        + c(lat1 * p) * c(lat2 * p)
        * (1 - c((lon2 - lon1) * p)) / 2;

    return 12742 * Math.asin(Math.sqrt(a));
};
