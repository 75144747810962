import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import authPage from '../../../containers/authPage';
import {Link} from "react-router-dom";
import MetaTags from "../../../../atoms/MetaTags";

class Verification extends Component {
  componentDidMount() {
    const { token } = this.props.match.params;
    const _token = localStorage.getItem('token');
    if (_token) {
      localStorage.clear();
    }
    // check if there token in url
    if (token) {
      // run verify request
      this.props.verifyAction({ token }).then(data => {
        if (data) {
          // set 'token' to localstorage
          localStorage.setItem('token', data.accessToken);
          // get 'me'(current user) data
          this.props.getMe(data.accessToken).then(user => {
            // redirect to homepage
            this.props.history.push(`/`);
          });
        }
      });
    }
  }

  render() {
    const {
      auth: { error },
    } = this.props;

    if (error) {
      return <>
        <MetaTags title="Verification" pathname={this.props.location.pathname} />

        <div className="auth-form auth-form--sign-up">
          <div className="auth-form__vertical-frame">
            <div className="auth-form__title">Verification failed</div>
            <div className="mb-3 text-xl leading-tight">
                Sorry but the verification link you have clicked is no longer valid. Please try re-verifying your email address using the button below:
            </div>
            <Link to={"/resend-verification"}
                  className="btn btn--secondary">
              Resend verification email
            </Link>
          </div>
        </div>
      </>
    }

    return <></>;
  }
}

Verification.propTypes = {
  auth: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  verifyAction: PropTypes.func,
  getMe: PropTypes.func,
};

export default authPage(Verification);
