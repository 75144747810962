import React, { useState, useEffect } from 'react';

import generalPage from '../../containers/generalPage';
import SliderArticlesContainer from '../../containers/SliderArticlesContainer';
import HelpfulLinks from '../../OneCategory/HelpfulLinks';

import { getArticles } from '../../../utils/api/get';
import MetaTags from "../../../atoms/MetaTags";

const ContactNumbers = props => {
  const [articles, setArticles] = useState([]);
  const [author, setAuthor] = useState({
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQl7MrNjo4Nq_sudOlP8AnGQLZJ4Hxj6fCRaKqnB9-6OX2KnpX-',
    fullName: 'John Applewood',
    url: 'http://localhost:8080/profile',
    date: '15 hrs ago',
  });
  const [links, setLinks] = useState([
    {
      id: 1,
      title: 'Link Title',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sed vulputate erat, et porttitor elit. Curabitur nec euismod diam. Aliquam erat volutpat. Donec non purus dui. In lobortis elementum nisl ac tempor.',
      url: 'www.link.com',
    },
    {
      id: 2,
      title: 'Link Title',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sed vulputate erat, et porttitor elit. Curabitur nec euismod diam. Aliquam erat volutpat. Donec non purus dui. In lobortis elementum nisl ac tempor.',
      url: 'www.link.com',
    },
    {
      id: 3,
      title: 'Link Title',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sed vulputate erat, et porttitor elit. Curabitur nec euismod diam. Aliquam erat volutpat. Donec non purus dui. In lobortis elementum nisl ac tempor.',
      url: 'www.link.com',
    },
  ]);

  useEffect(() => {
    getArticles().then(data => {
      setArticles(data);
    });
  }, []);

  return (
    <div>
      <MetaTags title="Contact numbers" pathname={props.location.pathname} />

      <SliderArticlesContainer title="All I Can Tell You about…" items={articles} />
      <HelpfulLinks author={author} items={links} title="Contact Numbers" />
    </div>
  );
};

export default generalPage(ContactNumbers);
