import { apiUrl } from '../constants';
import serviceAPI from '../../services/serviceAPI';
import { catchExpiredToken } from '../helpers';

require('es6-promise').polyfill();

export const POST_ENDPOINTS = {
  signUp: `${apiUrl}/register`,
  signIn: `${apiUrl}/login`,
  signInGoogle: `${apiUrl}/login/google`,
  signInFacebook: `${apiUrl}/login/facebook`,
  signOut: `${apiUrl}/logout`,
  verify: `${apiUrl}/verify`,
  verifyService: `${apiUrl}/verify/service`,
  resetPassword: `${apiUrl}/password/send-reset-token`,
  resendVerificationLink: `${apiUrl}/resend-verification`,
  newPassword: `${apiUrl}/password/reset`,
  patchCurrentUser: `${apiUrl}/me`,
  postUserAvatar: `${apiUrl}/me/avatar`,
  postUserPhotos: `${apiUrl}/me/photos`,
  postAnswer: `${apiUrl}/bio/answers`,
  postQuestionAnswer: `${apiUrl}/bio/question-answer`,
  createDiary: `${apiUrl}/diary-posts`,
  follow: `${apiUrl}/follow`,
  react: `${apiUrl}/react`,
  notificationsSeen: `${apiUrl}/notifications/seen`,
  forumTopic: `${apiUrl}/forum-topics`,
  forumMessages: `${apiUrl}/forum-messages`,
  rsvpEvent: `${apiUrl}/events/add-attendee`,
  cancelRsvpEvent: `${apiUrl}/events/remove-attendee`,
  contact: `${apiUrl}/contact`,
  charge: `${apiUrl}/charge`,
  intent: `${apiUrl}/intent`,
  enquireProduct: `${apiUrl}/products/enquire`,
  phoneValidation: `${apiUrl}/validate/phone`,
  discountCodeLookup: `${apiUrl}/discount-code`
};

const postData = (data, endpoint) => {
  return new Promise((resolve, reject) => {
    serviceAPI
      .post(endpoint, data)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.response);
        }
      })
      .catch(error => {
        catchExpiredToken(error);
        reject(error.response.data);
      });
  });
};

const patchData = (data, endpoint) => {
  return new Promise((resolve, reject) => {
    serviceAPI
      .patch(endpoint, data)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.response);
        }
      })
      .catch(error => {
        catchExpiredToken(error);
        reject(error.response.data);
      });
  });
};

export const sendSignUp = data => postData(data, POST_ENDPOINTS.signUp);
export const sendSignIn = data => postData(data, POST_ENDPOINTS.signIn);
export const sendSignOut = data => postData(data, POST_ENDPOINTS.signOut);
export const sendVerify = data => postData(data, POST_ENDPOINTS.verify);
export const sendVerifyService = data => postData(data, POST_ENDPOINTS.verifyService);
export const sendResetPassword = data => postData(data, POST_ENDPOINTS.resetPassword);
export const sendNewPassword = data => postData(data, POST_ENDPOINTS.newPassword);

export const resendVerification = data => postData(data, POST_ENDPOINTS.resendVerificationLink);

export const patchCurrentUser = data => patchData(data, POST_ENDPOINTS.patchCurrentUser);
export const postUserAvatar = data => postData(data, POST_ENDPOINTS.postUserAvatar);
export const postUserPhotos = data => postData(data, POST_ENDPOINTS.postUserPhotos);

export const postAnswer = data => postData(data, POST_ENDPOINTS.postAnswer);
export const postCustomQuestionAnswer = data => postData(data, POST_ENDPOINTS.postQuestionAnswer);

export const createDiary = data => postData(data, POST_ENDPOINTS.createDiary);
export const updateDiary = (data, id) => patchData(data, `${POST_ENDPOINTS.createDiary}/${encodeURIComponent(id)}`);

export const followUser = userId => postData({ userId }, POST_ENDPOINTS.follow);
export const reactPost = (entityType, entityId, reaction = 'like') =>
  postData({ entityType, entityId, reaction }, POST_ENDPOINTS.react);

export const sendNotificationsSeen = idsArray => postData({ ids: idsArray }, POST_ENDPOINTS.notificationsSeen);

export const createForumTopic = data => postData(data, POST_ENDPOINTS.forumTopic);
export const updateForumTopic = (data, id) => patchData(data, `${POST_ENDPOINTS.forumTopic}/${id}`);
export const createForumMessages = data => postData(data, POST_ENDPOINTS.forumMessages);
export const updateForumMessages = (data, id) => patchData(data, `${POST_ENDPOINTS.forumMessages}/${id}`);

export const rsvpEvent = (data) => postData(data, POST_ENDPOINTS.rsvpEvent);
export const cancelRsvpEvent = (data) => postData(data, POST_ENDPOINTS.cancelRsvpEvent);
export const contact = (data) => postData(data, POST_ENDPOINTS.contact);
export const checkoutIntent = (checkout, items, shippingId, couponCode) => {
  return postData({
    ...checkout,
    items,
    shipping_id: shippingId,
    couponCode: couponCode,
  }, POST_ENDPOINTS.intent);
};

export const enquireProduct = (data) => postData(data, POST_ENDPOINTS.enquireProduct);

export const validatePhone = (phone_number) => postData(phone_number, POST_ENDPOINTS.phoneValidation);

export const discountCodeLookup = (data) => postData(data, POST_ENDPOINTS.discountCodeLookup);
