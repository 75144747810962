import React, { useEffect, useState } from 'react';

import generalContentPage from '../../containers/generalContentPage';
import { Link } from "react-router-dom";
import Icon from "../../../Icon";
import MetaTags from "../../../atoms/MetaTags";
import BlockSignUp from "../../../atoms/BlockSignUp";
import Button from "../../../atoms/Button";

function ImageWithSmallBackgroundShape({image_url, image_classes, shape_position}) {
  let base_shape_classes = 'absolute lg:h-80 sm:h-56 h-48 -z-10 lg:-bottom-56 -bottom-24';
  if(shape_position === 'right') {
    base_shape_classes += ' -scale-x-100 -right-[80px] right-12';
  } else {
    base_shape_classes += ' lg:hidden lg:-left-[80px] left-12';
  }
  return (
    <div className="relative">
      <img src={image_url} alt="Photo"  className={image_classes}/>
      <img src="/images/yellow-background-shape.svg" className={base_shape_classes}/>
    </div>
  );
}

const AboutUs = props => {
  return (
    <div className='about-us'>
      <MetaTags title="About Us" pathname={props.location.pathname} />

      <div className="overflow-hidden">
        <div
          className="bg-[url('../../public/images/photo-aboutbanner-mobile.jpg')] sm:bg-[url('../../public/images/photo-aboutbanner.jpg')] bg-cover bg-center relative">
          <div
            className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-gradient-to-l xl:bg-gradient-to-b from-[transparent] to-dark-navy opacity-70 xl:opacity-20 sm:hidden"></div>
          <div
            className="h-[70vh] max-w-[1000px] p-pad-sm mx-auto relative relative flex items-center justify-start">
            <div className="max-w-[450px]">
              <h1 className="font-bold font-heading text-white text-40 lg:text-60 mb-5">
                <span className="block">Welcome to the only global platform for</span>
                <strong className="block text-yellow">Grandudes</strong>
              </h1>
              <p className="text-white text-16 xl:text-20">
                Are you ready to change your life and start living to the fullest? This is just the
                beginning, not the end...
              </p>
              {/*<div className="mt-14">*/}
              {/*  <Link to="/sign-up" component={Button} colour="bg-yellow">Sign up for free</Link>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div
          className="max-w-[1200px] px-pad-sm mx-auto xl:flex items-center xl:space-x-[150px] py-14">
          <div className="rounded-xl overflow-hidden mb-14 xl:mb-0 xl:h-[700px]">
            <img src="/images/about_1.jpeg" alt="Photo"/>
          </div>
          <div className="flex-1">
            <h2 className="font-heading font-bold text-36 lg:text-50 mb-6">What is a <strong
              className="text-yellow">Grandude</strong></h2>
            <p>
              A Grandude is technically of age to have grandchildren - we're talking over 50s - but
              whether or not he does, he defies the stereotype in the way he lives, views the world
              and, most importantly, himself. It's time to enjoy being the person you have become.
            </p>
            <ul>
              <li>He embraces the opportunities and riches of this time of life, what we call
                'Golden Years' where experience and maturity marry with a spirit of openness and
                curiosity.
              </li>
              <li>He is respected and included.</li>
              <li>His opinion is valued, and his enthusiasm is unwavering.</li>
              <li>He is the leader of the pack and others follow in his tracks.</li>
            </ul>
          </div>
        </div>
        <div className="bg-light-grey">
          <div
            className="max-w-[1200px] px-pad-sm mx-auto flex lg:flex-row flex-col lg:items-center items-end lg:space-x-[150px] py-14 xl:mb-0 md:mb-[250px] mb-[450px]">
            <div className="flex-1">
              <h2 className="font-heading font-bold text-36 lg:text-50 mb-6">If you are a <strong
                className="text-yellow">Grandude</strong></h2>
              <p>
                or aspire to be one; if you intend to enjoy the rest of your life to the fullest and
                leave a meaningful legacy, my friend, this is where you will find your like-minded
                tribe of men who have seen most of it and been around the block.
              </p>
              <p>
                There is a global movement of this new generation of men who are still young in
                mind and body although not as youthful as they once felt. Maybe a little grey around
                the edges, but everyone loves a silver fox.
              </p>
            </div>
            <div
              className="translate-x-[100px] mb-[-500px] lg:translate-x-0 xl:mb-0 mt-14 xl:mt-0 xl:h-[700px] xl:translate-y-[200px]">
              <div className="relative">
                <img src="/images/about_2.jpeg" alt="Photo" className="rounded-xl w-full"/>
                <img src="/images/yellow-background-shape.svg" className="absolute md:w-full w-1/2 2xl:-bottom-60 xl:-bottom-48 lg:-bottom-32 md:-bottom-8 sm:-bottom-32 -bottom-24 left-10 -scale-x-100 translate-x-1/2 -z-10"/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="max-w-[1000px] px-pad-sm mx-auto flex items-center space-x-[150px] py-[150px]">
            <div className="text-50 lg:text-90 font-heading font-bold">
              <strong className="block">“</strong>
              <strong className="block">your life,</strong>
              <strong className="block">your <span className="text-yellow">legacy</span></strong>
            </div>
          </div>
        </div>
        <div className="bg-yellow">
          <div
            className="max-w-[1200px] px-pad-sm mx-auto xl:flex items-center py-14 pb-[300px] mb-[300px] sm:pb-pad-sm sm:mb-0">
            <div className="order-2 wysiwyg flex-1 text-dark-navy xl:ml-[150px]">
              <p>
                They still want to travel, meet new people, keep in shape, laugh and enjoy
                everything the world has to offer - in fact now more than ever, with the benefit of
                hindsight and the appreciation of the value of time, relationships and experiences,
                over things.
              </p>
            </div>


            <div
              className="rounded-xl translate-x-[-100px] mb-[-500px] lg:translate-x-0 xl:mb-0 mt-14 xl:mt-0 xl:h-[700px] xl:translate-y-[200px]">
              {/*<img src="https://via.placeholder.com/500x700" alt="Photo" />*/}
              <ImageWithSmallBackgroundShape image_url="/images/about_3.jpeg"
                                             shape_position="left" image_classes="rounded-xl"/>
            </div>
          </div>
        </div>
        <div
          className="max-w-[1200px] p-pad-sm mx-auto xl:flex items-center xl:space-x-[150px] xl:pt-[300px]">
          <div className="wysiwyg flex-1">
            <h2 className="font-heading font-bold text-50">Grandudes have <strong
              className="text-yellow">a lust for life</strong></h2>
            <p>
              They want to look good and go out and be sociable with people they love. They want to
              be close to their family and emotionally connected to those they care about. We really
              mean it, using everything you’ve built, seen, and felt in your life so far, these
              could be your best years.
            </p>
          </div>
          <div className="rounded-xl overflow-hidden mt-14 xl:mt-0 xl:h-[700px]">
            <img src="/images/about_4.jpeg" alt="Photo"/>
          </div>
        </div>
        <div className="my-14 xl:my-[150px]">
          <BlockSignUp />
        </div>
      </div>
    </div>
  );
};

export default generalContentPage(AboutUs);
