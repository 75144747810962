import { getNotifications } from '../utils/api';

export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE';
export const notificationsUpdate = data => dispatch => dispatch({ type: NOTIFICATIONS_UPDATE, payload: data });

export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING';
export const notificationsLoad = () => dispatch => {
  dispatch({
    type: NOTIFICATIONS_LOADING,
  });
  return getNotifications()
    .then(response => {
      if (response) {
        dispatch(notificationsUpdate(response));
      }
    })
    .catch(err => {
      console.warn(err);
    });
};
