import React from 'react';
import PropTypes from 'prop-types';
import ArticleItem from '../../atoms/ArticleItem';

const ArticlesContainer = ({ items, title }) => (
  <section className="articles">
    {/*<div className="articles__head">*/}
      {/*<h1 className="articles__title">{title}</h1>*/}
    {/*</div>*/}
    <div className="articles__list-wrap">
      <div className="articles__list">
        {items && items.map(item => (
          <ArticleItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  </section>
);

ArticlesContainer.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};
export default ArticlesContainer;
