import React, {Component, useContext, useState} from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useLocation, withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from '../../Icon';
import UserDropdown from './UserDropdown';
import Search from '../Search';
import Menu from '../Menu';
import { setOpened } from '../../actions/configAction';
import { routes } from '../../routes';
import { LoginSingup } from "./LoginSingup";
import Button from "../../atoms/Button";
import Headroom from "react-headroom";
import {BasketContext, BasketProvider} from "../../providers/BasketProvider";

function MobileMenu({ setShowMenu, user, isDarkBg }) {
  const location = useLocation();
  const lifestyleSlug = location.pathname.match(/^\/lifestyle\/(.+)/)?.[1];
  const experiencesSlug = location.pathname.endsWith('/experiences') || location.pathname.endsWith('/experiences/') ? 'experiences' : null;
  const { numItems, items } = useContext(BasketContext);

  const headerClasses = classNames('fixed top-0 left-0 right-0 z-50 xl:hidden', {
    'bg-dark-navy text-white': lifestyleSlug != null || experiencesSlug != null,
    'bg-yellow text-dark-navy': lifestyleSlug == null && experiencesSlug == null
  });

  return <div className={headerClasses}>
    <div className="container px-pad-sm mx-auto background-shapes-dark">
      <div className="h-screen flex flex-col justify-between">
        <div className="w-full pt-[38px] flex justify-between items-center">
          <div className="mr-7">
            <img className="h-[25px]" src="/images/grandudes-logo.svg?1" alt="Grandudes"/>
          </div>
          <div className="flex">
            <Link to="/basket" className="relative">
              <img className="inline-block h-[20px]" src="/images/icon-basket.svg"
                   alt="Basket"/>
              {numItems > 0 && (
                <span
                  className={`block absolute top-[-10px] right-[-10px] ${isDarkBg ? 'bg-white text-dark-navy' : 'bg-dark-navy text-white'} text-center min-w-[20px] min-h-[20px] rounded-full text-14 px-[1px]`}>
                    {numItems}
                  </span>
              )}
            </Link>
            <button className="xl:hidden ml-7" onClick={() => setShowMenu(show => !show)}>
              <img className="h-[20px] w-[30px]" src={lifestyleSlug || experiencesSlug ? "/images/icon-close.svg" : "/images/icon-close-dark.svg"} alt="Close menu"/>
            </button>
          </div>
        </div>
        <div className="space-y-3 flex flex-col">
          <Menu/>
        </div>
        {/*<div className="flex flex-col space-y-4">*/}
        {/*  {!user.signed && <LoginSingup/>}*/}
        {/*  {user.signed && <UserDropdown/>}*/}
        {/*</div>*/}
        <div className="mb-pad-sm z-20">
          <a className="inline-block" href="https://twitter.com/grandudes2" target="_blank"><img
            src="/images/icon-twitter.svg" alt="Grandudes on Twitter"/></a>
          <a className="inline-block ml-5" href="https://www.instagram.com/grandudes1/" target="_blank"><img
            src="/images/icon-instagram.svg" alt="Grandudes on Instagram"/></a>
          <a className="inline-block ml-5" href="https://www.facebook.com/profile.php?id=100086698687994" target="_blank"><img
            src="/images/icon-facebook.svg" alt="Grandudes on Facebook"/></a>
        </div>
      </div>
    </div>
  </div>;
}

function Basket({ handleToggleBasket, handleHideBasket }) {
  const {
    items,
    shipping,
    calculateSubTotal,
    calculateTotal,
    itemIsDiscounted,
    coupon,
  } = useContext(BasketContext);

  return <>
    <div className="top-[60px] md:top-[100px] px-10 w-full max-w-[500px] right-0 absolute z-50">
      <div className="bg-white p-10 rounded-b-2xl">
        <div
          className={`w-full rounded-[10px] py-3.5 px-5 mb-5 font-bold bg-[#F5F5F5]`}>Your basket</div>
        <span className="block pt-4 pb-2 px-4 text-[14px]">Product</span>
        <div className="max-h-[300px] pt-2 overflow-y-auto">
          {Object.entries(items).map(([id, item]) => (
            <div className="flex border-b border-[#F5F5F5] pb-10 ml-4">
              <div><img className="h-[80px] bg-[#F5F5F5]"
                        src={item.thumbUrl} /></div>
              <div className="flex flex-col text-[14px] ml-10">
                <div className="font-bold text-[16px]">{item.name}</div>
                {/*<span className="py-1">Design: 1</span>*/}
                <div className="py-1">Qty: {item.qty} &pound;{(item.price * item.qty).toLocaleString('en-GB', { minimumFractionDigits: 2 })}</div>
                {item.variations && item.variations.map(variation => (
                  <div>{variation.name}: &pound;{(variation.price * item.qty).toLocaleString('en-GB', { minimumFractionDigits: 2 })}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between border-b border-[#F5F5F5] text-[14px]">
          <span className="py-4 px-4">Subtotal</span>
          <span className="py-4 px-4">£{calculateSubTotal().toLocaleString('en-GB', { minimumFractionDigits: 2 })}</span>
        </div>
        {coupon.code !== '' && <>
          <div className="flex justify-between border-b border-[#F5F5F5] text-[14px]">
            <span className="py-4 px-4">Coupon: {coupon.code}</span>
            <span
              className="py-4 px-4">{coupon.percent.toLocaleString('en-GB')}% off{coupon.products.length > 0 ? ' selected' : ''}</span>
          </div>
        </>}
        {shipping && <>
          <div className="flex justify-between border-b border-[#91989F] text-[14px]">
            <span className="py-4 px-4">{shipping.name}</span>
            <span className="py-4 px-4">{shipping.price === 0 ? 'Free' : `£${shipping.price.toLocaleString('en-GB', { minimumFractionDigits: 2 })}`}</span>
          </div>
        </>}
        <div
          className="flex justify-between border-b border-[#91989F] text-[16px] font-bold">
          <span className="py-4 px-4">Total</span>
          <span className="py-4 px-4">£{calculateTotal().toLocaleString('en-GB', { minimumFractionDigits: 2 })}</span>
        </div>
        <div className="flex flex-row justify-between pt-10">
          <Link to="/basket" className="bg-dark-grey rounded-md py-2 px-4 inline-block text-white" onClick={handleHideBasket}>Edit basket</Link>
          <Link to="/checkout" className="bg-yellow rounded-md py-2 px-4 inline-block text-dark-navy" onClick={handleHideBasket}>Go to checkout</Link>
        </div>
      </div>
    </div>
  </>;
}

const Header = ({ user }) => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const lifestyleSlug = location.pathname.match(/^\/lifestyle\/(.+)/)?.[1];
  const experiencesSlug = location.pathname.endsWith('/experiences') || location.pathname.endsWith('/experiences/') ? 'experiences' : null;

  let isDarkBg = lifestyleSlug != null || experiencesSlug != null;

  const headerClasses = classNames('fixed top-0 left-0 right-0 z-50', {
    'bg-dark-navy text-white': isDarkBg,
    'bg-yellow text-dark-navy': !isDarkBg,
  });

  const [showBasket, setShowBasket] = useState(false);
  const { numItems, items } = useContext(BasketContext);

  const handleToggleBasket = e => {
    e.preventDefault();
    setShowBasket(show => !show);
  };

  const handleHideBasket = e => {
    setShowBasket(false);
  };

  return <>
    {showBasket && (
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity z-40" onClick={handleHideBasket}></div>
    )}
    <Headroom disableInlineStyles className={`${showMenu ? 'hidden' : ''}`}>
      <div className="h-[60px] md:h-[100px] bg-yellow"></div>
      <header className={headerClasses}>
        <div className="container px-pad-sm mx-auto">
          <div className="flex justify-between items-center">
            <div className="flex justify-between py-[13px] md:py-[33px]">
              <div className="mr-7">
                <Link to={'/'}>
                  <img className="h-[35px]" src={isDarkBg ? "/images/logo-yellow.svg" : "/images/grandudes-logo.svg?1"} alt="Grandudes"/>
                </Link>
              </div>
            </div>
            <div className="hidden xl:block flex-1 flex px-4">
              <Menu/>
            </div>
            {/*<div className="hidden xl:flex xl:items-center">*/}
            {/*  {!user.signed && <LoginSingup/>}*/}
            {/*  {user.signed && <UserDropdown/>}*/}
            {/*</div>*/}
            <div className="flex">
              <a href="#" onClick={handleToggleBasket} className="relative">
                <img className="inline-block h-[20px]" src="/images/icon-basket.svg" alt="Basket"/>
                {numItems > 0 && (
                  <span
                  className={`block absolute top-[-10px] right-[-10px] ${isDarkBg ? 'bg-white text-dark-navy' : 'bg-dark-navy text-white'} text-center min-w-[20px] min-h-[20px] rounded-full text-14 px-[1px]`}>
                    {numItems}
                  </span>
                )}
              </a>
              <button className="xl:hidden ml-7" onClick={() => setShowMenu(show => !show)}>
                <img className="h-[20px] w-[30px]" src={isDarkBg ? "/images/icon-menu.svg" : "/images/icon-menu-dark.svg"} alt="Menu"/>
              </button>
            </div>
          </div>
        </div>
      </header>
      {showBasket && <Basket handleToggleBasket={handleToggleBasket} handleHideBasket={handleHideBasket} />}
    </Headroom>
    {showMenu && <MobileMenu setShowMenu={setShowMenu} user={user} isDarkBg={isDarkBg} />}
  </>
}

Header.propTypes = {
  setOpened: PropTypes.func,
};

const mapStateToProps = store => ({
    user: store.user
});

const mapDispatchToProps = dispatch => ({
  setOpened: type => dispatch(setOpened(type)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Header));
