import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import Input from '../../../../atoms/form/Input';
import formValidator from '../../../../utils/form';

import authPage from '../../../containers/authPage';
import ErrorParser from '../../../../atoms/ErrorParser';
import MetaTags from "../../../../atoms/MetaTags";

const ResetPassword = props => {
  const {
    sendResetPassword,
    auth: { success, error },
    pristine,
    submitting,
    handleSubmit,
    authClearData,
    reset,
  } = props;
  const resetHandler = values => {
    sendResetPassword(values).then(data => {
      if (data) {
        reset();
        // get 'me'(current user) data
        // props.getMe(data.accessToken).then(user => {
        //   // redirect to homepage
        //   props.history.push('/');
        // });
      }
    });
  };
  return (
    <div className="auth-form auth-form--sign-up">
      <MetaTags title="Reset Password" pathname={props.location.pathname} />

      <form>
        <div className="auth-form__vertical-frame">
          <div className="auth-form__top">
            <div className="auth-form__title">Reset Password</div>
            {success && (
              <div className="auth-form__success-validation">
                Password reset instructions have been sent to your email
              </div>
            )}
            {error ? <ErrorParser data={error} /> : ''}
            <Field
              title="Email"
              name="email"
              component={Input}
              type="text"
              validate={[formValidator.required, formValidator.email]}
            />
          </div>
          <div>
            <button
              className="btn btn--primary"
              type="submit"
              onClick={handleSubmit(resetHandler)}
              disabled={submitting}
            >
              <span className="text-el">Reset Password</span>
            </button>
            <Link
              to={'/login'}
              className="btn btn--secondary"
              onClick={() => {
                authClearData();
              }}
            >
              Log in
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

const form = reduxForm({
  form: 'resetPassword', // a unique identifier for this form
})(ResetPassword);

export default authPage(form);
