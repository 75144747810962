import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setOpened } from '../../actions/configAction';

const Item = ({ item, active, history, setOpened, userSigned, setModalOpen }) => {
  return <>
    {(!item.userRequired || userSigned) ?
      <a
        href={item.url}
        onClick={(e) => {
          e.preventDefault();

          history.push(item.url);
          document.body.className.replace('no-scroll', '');
          setOpened(null);
        }}
        className={classNames('mr-7 text-20', {
          'menu__item--active': active,
        })}
      >
        {item.name}
      </a> :
        <a
          href={item.url}
          onClick={(e) => {
              e.preventDefault();
              setOpened(null);
              setModalOpen();
          }}
          className={classNames('mr-7', {
            'menu__item--active': active,
          })}
        >
            {item.name}
        </a>
      }
  </>
};


Item.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
};

class Menu extends React.Component {
  state = {
    modalOpen: false,
    groupMenuItem: {
        name: 'Groups',
        url: '/groups',
        alias: 'groups',
    }
  };

  onOpenChange = () => {
    this.setState({
        modalOpen: false,
    })
  };

  setModalOpen = () => {
    this.setState({
        modalOpen: true,
    })
  };

  render() {
    const { signed } = this.props.user;
    return (
      <>
        {this.props.menu.map((item, index) => {
          return (
            <Item
              key={index}
              item={item}
              setOpened={this.props.setOpened}
              history={this.props.history}
              active={item.url === this.props.location.pathname}
              userSigned={signed}
              setModalOpen={this.setModalOpen}
            />
          );
        })}
        {this.props.user.current && this.props.user.current.spaces &&
          <Item
              key="groups"
              item={this.state.groupMenuItem}
              setOpened={this.props.setOpened}
              history={this.props.history}
              active={this.state.groupMenuItem.url === this.props.location.pathname}
              userSigned={signed}
              setModalOpen={this.setModalOpen}
          />
        }
      </>
    );
  }
}

Menu.propTypes = {
  menu: PropTypes.array,
  location: PropTypes.object,
};

const mapStateToProps = store => ({
  menu: store.menu, user: store.user,
});

const mapDispatchToProps = dispatch => ({
  setOpened: type => dispatch(setOpened(type)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Menu),
);
