import React, {useEffect, useState} from 'react';
import {contact} from "../../../utils/api";
import MetaTags from "../../../atoms/MetaTags";
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import generalContentPage from "../../containers/generalContentPage";

function ContactForm({ contactDetails, handleChange, sendContactForm, formErrors, isLoading }) {
  console.log(formErrors);
  return <form action="#" method="POST">
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-3 sm:col-span-3">
        <input className={`placeholder-black py-4 px-6 border border-[#23323F] rounded-md w-full ${formErrors.first_name ? 'failed-validation' : ''}`}
               type="text" placeholder="First Name*" name="first_name"
               id="first-name"
               value={contactDetails.first_name} onChange={handleChange}
               autoComplete="given-name"/>
        {formErrors.first_name && <span className="text-[#EA4751]"><img src={"images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.first_name}</span>}
      </div>
      <div className="col-span-3 sm:col-span-3">
        <input className={`placeholder-black py-4 px-6 border border-[#23323F] rounded-md w-full ${formErrors.last_name ? 'failed-validation' : ''}`}
               type="text" placeholder="Last Name*" name="last_name" id="last-name"
               value={contactDetails.last_name} onChange={handleChange}
               autoComplete="family-name"/>
        {formErrors.last_name && <span className="text-[#EA4751]"><img src={"images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.last_name}</span>}
      </div>
      <div className="col-span-3 sm:col-span-3">
        <input className={`placeholder-black py-4 px-6 border border-[#23323F] rounded-md w-full ${formErrors.email ? 'failed-validation' : ''}`}
               type="text" placeholder="Email*" name="email"
               value={contactDetails.email} onChange={handleChange}
               id="email-address" autoComplete="email"/>
        {formErrors.email && <span className="text-[#EA4751]"><img src={"images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.email}</span>}
      </div>
      <div className="col-span-3 sm:col-span-3">
        <input className={`placeholder-black py-4 px-6 border border-[#23323F] rounded-md w-full ${formErrors.email_confirmation ? 'failed-validation' : ''}`}
               type="text" placeholder="Confirm Email*" name="email_confirmation"
               value={contactDetails.email_confirmation} onChange={handleChange}
               id="email-address" autoComplete="email"/>
        {formErrors.email_confirmation && <span className="text-[#EA4751]"><img src={"images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.email_confirmation}</span>}
      </div>
      <div className="col-span-3 sm:col-span-3">
        <input className={`placeholder-black py-4 px-6 border border-[#23323F] rounded-md w-full ${formErrors.phone_number ? 'failed-validation' : ''}`}
               type="text" placeholder="Phone" name="phone_number"
               onChange={handleChange} value={contactDetails.phone_number}
               id="phone-number" autoComplete="phone"/>
        {formErrors.phone_number && <span className="text-[#EA4751]"><img src={"images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.phone_number}</span>}
      </div>
      <div className="col-span-6">
        <div className="mt-2">
                      <textarea id="contact-about" name="message" rows="3"
                                className={`placeholder-black mt-1 block w-full rounded-md border border-[#23323F] py-2 px-2 sm:text-sm sm:leading-6 h-[120px]  ${formErrors.message ? 'failed-validation' : ''}`}
                                onFocus={e=> e.target.placeholder = ''}
                                onBlur={e => e.target.placeholder = 'Type your message here...'}
                                onChange={handleChange}
                                placeholder="Type your message here..." defaultValue={contactDetails.message}></textarea>
          {formErrors.message && <span className="text-[#EA4751]"><img src={"images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.message}</span>}
        </div>
      </div>
    </div>
    <div className="flex justify-end lg:pb-16 mt-8 pb-0">
      <button
        onClick={(e) => {
          e.preventDefault();
          sendContactForm(contactDetails);
        }}
        className="bg-yellow hover:bg-[#91989F] rounded-md py-4 lg:py-[10px] px-4 w-full lg:w-auto text-white text-[16px] transition">
        {isLoading && (<><LoadingSpinner size={5} /> Submitting</>)}
        {!isLoading && (<>Submit</>)}
      </button>
    </div>
  </form>
}

const Contact = ({ location, perPage = 12 }) => {
  let emptyFormState = {
    first_name: '',
    last_name: '',
    email: '',
    email_confirmation: '',
    phone_number: '',
    message: '',
  };
  const [contactDetails, setContactDetails] = useState(emptyFormState);
  const [formErrors, setFormErrors] = useState(emptyFormState);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);

  const handleChange = (e) => {
    setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
  }

  const sendContactForm = (details) => {
    setLoading(true);
    contact(details).then((response) => {
      setLoading(false);
      if (!response.success) {
        setFormErrors({ ...emptyFormState, ...response.errors });
        setErrorMessage('Sorry, your message could not be sent. Please correct the errors and try again');
        setSuccessMessage(undefined);
      }
      else {
        setErrorMessage(undefined);
        setSuccessMessage('Your message has been sent. We will be in touch shortly, please check your spam if you have not heard within 24 hours.');
        setContactDetails(emptyFormState);
        setFormErrors(emptyFormState);
      }
    })
    .catch((error) => {
      setErrorMessage('Sorry, an unknown error occurred while sending your message. Please try again later.');
    });
  }

  return (
    <>
      <MetaTags title="Contact Us" pathname={location.pathname}/>
      <div className="relative">
        <div className="absolute left-0 bottom-0 z-[-1]">
          <img className="max-h-[300px]" src="/images/man-image.svg"/>
        </div>
        <div
          className="absolute z-[-1] right-[0] top-[0] lg:top-[-34px]">
          <img className="h-[255px] w-[250px] md:h-[408px] md:w-[400px] lg:h-full lg:w-full"
               src="/images/background-letter-logo.svg"/>
        </div>
        <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
          <div className="py-[60px] flex justify-around xl:py-[100px] flex-col lg:flex-row mx-auto">
            <div className="flex-1">
              <div className="lg:mt-0 mt-12 lg:min-h-[450px]">
                <span className="uppercase text-sm">Contact Us</span>
                <h2 className="font-bold text-[50px] leading-[60px] max-w-[300px] mt-4">How can
                  we <span className="text-[#FFD23F]">help?</span></h2>
                <p className="text-xl pt-10 max-w-[540px]">Whatever your query, we are real people, and will get back to you within 24 hours.</p>
              </div>
            </div>
            <div className="flex-1">
              <div className="static pb-[40vw] md:pb-[200px] lg:pb-0">
                <div className="bg-white text-sm py-8 px-2 md:px-10">
                  {successMessage && (<div className="passed-validation py-4 px-6 rounded relative mb-8" role="alert">
                    {successMessage}
                  </div>)}
                  {errorMessage && (<div className="failed-validation py-4 px-6 rounded relative mb-8" role="alert">
                    <img src={"images/warning.svg"} className="inline mr-2" alt="" />
                    {errorMessage}
                  </div>)}
                  {!successMessage && (<ContactForm contactDetails={contactDetails} handleChange={handleChange} sendContactForm={sendContactForm} formErrors={formErrors} isLoading={loading} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default generalContentPage(
  Contact
);
