import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { setOpened } from '../../actions/configAction';

import Header from '../Header';
import Footer from '../Footer';
import Feed from '../Feed';
import FeedToolbar from '../Feed/FeedToolbar';
import Followed from '../Followed';

import InspirationsToolbar from '../shared/InspirationsToolbar';
import MobileFooterMenu from "../../atoms/MobileFooterMenu";

const generalPage = (WrappedComponent, pageType = 'general') => {
  class HOC extends Component {
    componentDidMount() {
      document.body.className = document.body.className.replace('no-scroll', '');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.config.opened !== this.props.config.opened) {
        const objRef = document.body;
        if (this.props.config.opened) {
          objRef.classList.add('no-scroll');
        } else {
          objRef.className = objRef.className.replace('no-scroll', '');
        }
      }
    }

    render() {
      return (
        <div
          className={classNames('main-body', `main-body--${pageType}`, {
            [this.props.config.opened]: this.props.config.opened,
          })}
        >
          <Header />
          <MobileFooterMenu/>
          {/*<div className="content">*/}
          {/*  {pageType !== 'single-article' && <FeedToolbar {...{ title: 'Feed', current: 'Articles' }} />}*/}

          {/*  <div className="container-fluid">*/}
          {/*    <Feed currentUser={this.props.currentUser} />*/}
          {/*    /!*<Followed />*!/*/}
          {/*    <div className="main-frame">*/}
          {/*      {false && <InspirationsToolbar />}*/}
          {/*      <WrappedComponent {...this.props} />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div*/}
          {/*    className="bg-feed-mob"*/}
          {/*    onClick={() => {*/}
          {/*      this.props.setOpened(null);*/}
          {/*    }}*/}
          {/*    onKeyDown={() => {*/}
          {/*      this.props.setOpened(null);*/}
          {/*    }}*/}
          {/*    role="button"*/}
          {/*    tabIndex="0"*/}
          {/*  />*/}
          {/*</div>*/}
          <Footer />
        </div>
      );
    }
  }

  HOC.propTypes = {
    articles: PropTypes.array,
    setOpened: PropTypes.func,
    config: PropTypes.object,
  };

  const mapStateToProps = store => ({
    config: store.config,
    currentUser: store.user.current,
  });

  const mapDispatchToProps = dispatch => ({
    setOpened: type => dispatch(setOpened(type)),
  });

  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(HOC),
  );
};

export default generalPage;
