import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import HeartIcon from '../Icon/assets/icon-heart.svg';
import {Link} from "react-router-dom";
import Button from "./Button";
import Mailchimp from "../components/Mailchimp/Mailchimp";

function BlockSignUp({ fullWidth = false }) {

  return (
    <div id="target-section" className={`bg-dark-navy background-shapes-dark border-b-4 border-b-white ${fullWidth ? '' : 'container px-pad-sm mx-auto rounded-xl'}`}>
      <div className="container px-pad-sm mx-auto">
        <div
          className="pt-10 px-10 flex flex-col lg:flex-row h-auto lg:px-[100px] lg:items-center relative overflow-hidden h-[890px] sm:h-[636px] sm:pt-0 pt-[100px]">
          <div
            className="text-white text-body text-16 xl:text-20 mb-10 xl:mr-[100px] xl:max-w-[400px]">
            <h2
              className="text-white font-bold font-heading text-40 xl:text-56 xl:leading-[60px] mb-4 relative z-1">Sign
              up for free</h2>
            <p className="relative z-1">If you want to receive discounts, special offers and unique
              opportunities, all we need is your email. We never share your personal
              information.</p>
            <div className="mt-9">
              <Mailchimp
                action='https://grandudes.us9.list-manage.com/subscribe/post?u=e534e99cbf3cf126b0dc6f9b4&amp;id=8bd09a7934&amp;f_id=00af1ce1f0'
                fields={[
                  {
                    name: 'EMAIL',
                    placeholder: 'Email',
                    type: 'email',
                    required: true,
                    className: 'placeholder-black text-black py-4 px-4 border rounded-md w-full'
                  }
                ]}
                messages={{
                  button: "Sign up",
                }}
                buttonClassName="bg-yellow hover:bg-[#91989F] rounded-md mt-6 py-4 lg:py-[10px] px-4 w-full lg:w-auto text-dark-navy text-[16px] transition"
              />
            </div>
          </div>
          <div className="self-end z-10">
            <img className="" src="/images/illustration-wave.svg" alt="Wave"/>
          </div>
        </div>
      </div>
    </div>
  );
}

BlockSignUp.propTypes = {
};

export default BlockSignUp;
