import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PostThumb from '../../atoms/PostThumb';

class HelpfulLinks extends Component {
  render() {
    const { author, body } = this.props;
    return (
      <div className="helpful-links">
        <div className="articles__head">
          <h1 className="articles__title">Helpful Links</h1>
        </div>
        <div className="helpful-links__frame">
          <PostThumb img={author.avatar} fullName={author.fullName} url={author.id} date={author.date} />
          <div className="single-article__frame">
            <div className="single-article__description text fr-view" dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </div>
      </div>
    );
  }
}

HelpfulLinks.propTypes = {
  author: PropTypes.object,
  items: PropTypes.array,
};

export default HelpfulLinks;
