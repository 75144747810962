import React, {useEffect, useState} from 'react';

import MetaTags from "../../../atoms/MetaTags";
import generalContentPage from "../../containers/generalContentPage";
import Flickity from "react-flickity-component";
import flickityConfig from "../../../config/flickity-config";

function ImageWithSmallBackgroundShape({image_url, image_classes, shape_position}) {
  let base_shape_classes = 'absolute lg:h-80 sm:h-56 h-48 -z-10 lg:-bottom-56 sm:-bottom-36 -bottom-24';
  if(shape_position === 'right') {
    base_shape_classes += ' -scale-x-100 -right-[80px] right-12';
  } else {
    base_shape_classes += ' lg:-left-[80px] left-12';
  }
  return (
    <div className="relative">
      <img src={image_url} alt="Photo"  className={image_classes}/>
      <img src="/images/yellow-background-shape.svg" className={base_shape_classes}/>
    </div>
  );
}

function CoachingQuote({ name, description, quote}) {
  return <div className="w-full grid md:grid-cols-3 gap-x-40 lg:pb-36">
    <div className="w-full">
      <h2 className="block text-40 lg:text-2xl font-heading font-bold">{name}</h2>
      <p className="pt-4">{description}</p>
    </div>

    <div className="w-full col-span-2">
      <strong className="block text-50 lg:text-90 font-heading font-bold">“</strong>
      <figure>
        <blockquote className="block text-20 lg:text-30 font-heading font-bold">
          <p>{quote}</p>
        </blockquote>
      </figure>
    </div>
  </div>;
}

const Coaching = (props) => {
  const quotes = [
    {
      name: 'Anthony Robbins',
      description: 'Coach to 4 USA presidents, Nelson Mandela and many elite athletes, actors and celebrities.',
      quote: 'A coach just has a different perspective. Still to this day, the best have coaches because the coach can see what you can\'t see because you\'re in the forest, they\'re outside of it.'
    },
    {
      name: 'Carl Jung',
      description: 'Influential Swiss Psychiatrist, artist, writer, social critic,  and founder of Analytical Psychology.',
      quote: 'Your vision will become clear only when you can look into your own heart. Who looks outside, dreams; who looks inside, awakes.'
    },
    {
      name: 'Andrew Carnegie',
      description: 'Scottish Industrialist and philanthropist, who helped build the American steel industry, making him the richest man in the world.',
      quote: 'If you want to be happy, set a goal that commands your thoughts, liberates your energy, and inspires your hopes.'
    },
    {
      name: 'Steven Spielberg',
      description: 'World’s highest grossing film director, whose box office hits include ‘Jurassic Park’, ‘Schindlers List’ and ‘Saving Private Ryan’.',
      quote: 'The delicate balance of mentoring someone is not creating them in your own image, but giving them the opportunity to create themselves.'
    }
  ];

  return (
    <>
      <MetaTags title="Coaching" pathname={props.location.pathname}/>

      <div className="overflow-hidden background-shapes">
        <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
          <div className="flex items-center mb-8">
            <div
              className="rounded-full bg-white h-[50px] aspect-square flex items-center justify-center mr-4">
              <img className="inline-block h-[35px]" src="/images/mastery-icon.svg" alt="Mastery"/>
            </div>
            <div className="uppercase text-14">
              Mastery
            </div>
          </div>
          <div className="mb-10">
            <strong className="block text-40 lg:text-60 font-heading font-bold">Coaching</strong>
          </div>
          <p className="lg:text-22 text-18 max-w-lg">In an ideal world, everyone would have a well
            matched life coach or mentor, to guide us down the path of true Mastery.</p>

          <p className="mt-4 max-w-lg">Regular coaching sessions can help to improve your
            well-being, relationships and decision making. This will lead to greater joy and
            appreciation, and a deeper sense of purpose and fulfilment.</p>
          <p className="mt-12">
            <a className="bg-yellow rounded-md py-2 px-4 inline-block text-white">Coming soon</a>
          </p>
        </div>
        {/* IMAGE AND TEXT */}
        <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px] xl:flex">
          {/* TEXT - RIGHT*/}
          <div className="order-2 flex-1 xl:ml-[150px] lg:mt-20">
            <h2 className="block text-40 lg:text-60 font-heading font-bold max-w-lg">Many men,
              particularly those of a certain age</h2>
            <p className="mt-4 max-w-lg">Many men, aged over 50, have either never experienced life
              coaching, or have only done so via a Mentor in a work environment and with the sole
              purpose of improving work performance. Coaching and Mentoring is so much broader this
              this, and potentially life transforming.</p>
            <p className="mt-4 max-w-lg">Many studies cite the benefits as including increased
              self-confidence, more effective communications, greater happiness and improved social
              interactions.</p>
            <p className="mt-4 max-w-lg">Coaching empowers, motivates, encourages, and results in
              greater physical and mental wellbeing. It is time to take life up a notch!</p>
          </div>
          {/* IMAGE ROUNDED - LEFT */}
          <div className="relative rounded-xl w-screen h-screen max-w-[270px] max-h-[353px] md:max-w-[445px] md:max-h-[595px] xl:max-w-[559px] xl:max-h-[732px] overflow-hidden xl:mt-0 mt-14">
            <img  src="/images/man-jumping.jpg" alt="Photo" className="absolute inset-0 h-full w-full object-cover"/>
          </div>
        </div>
        <div className="bg-yellow mt-12 text-white">
          <div
            className="container md:p-pad-lg p-pad-md mx-auto md:gap-32 gap-16 py-gap-sm md:py-36 items-center overflow-hidden">
            <Flickity
              className={'slider slider-with-btns dots overflow-hidden'}
              options={flickityConfig}
            >
              {quotes.map(quote => <CoachingQuote {...quote} />)}
            </Flickity>
          </div>
        </div>
        <div className="bg-light-grey">
          <div
              className="max-w-[1200px] px-pad-sm mx-auto flex lg:flex-row flex-col lg:items-center items-end lg:space-x-[150px] py-14 mb-[450px]">
            <div className="flex-1">
              <h2 className="font-heading font-bold text-36 lg:text-50 mb-6">At <strong className="text-yellow">Grandudes</strong>, we help you to find that perfect match</h2>
              <p>A coach or mentor will work with you to identify and set goals, along with the steps to achieve them. They will guide and encourage, and provide objective and independent feedback.</p>
              <p className="mt-4">Grandudes have selected coaches and mentors with experience in helping men over 50 find and achieve their specific goals and wider dreams. These can be physical and mental attainments, and they can help us to achieve greater purpose in our daily lives. We want to help you to create and enjoy your best years yet.</p>
            </div>
            <div
                className="translate-x-[100px] mb-[-500px] lg:translate-x-0 xl:mb-0 mt-14 xl:mt-0 xl:h-[700px] xl:translate-y-[200px]">
              <div className="relative">
                <div className="relative rounded-xl w-screen h-screen max-w-[270px] max-h-[353px] md:max-w-[445px] md:max-h-[595px] xl:max-w-[559px] xl:max-h-[732px] overflow-hidden">
                  <img src="/images/coaching.jpg" alt="Photo" className="absolute inset-0 h-full w-full object-cover"/>
                </div>
                <img src="/images/yellow-background-shape.svg" className="absolute md:w-full w-1/2 2xl:-bottom-60 xl:-bottom-48 lg:-bottom-32 md:-bottom-8 sm:-bottom-32 -bottom-24 left-10 -scale-x-100 translate-x-1/2 -z-10"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default generalContentPage(Coaching);
