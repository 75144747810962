import React from 'react';

import policyPage from '../../containers/policyPage';
import MetaTags from "../../../atoms/MetaTags";

const Rules = props => {
    return (
        <div className="policy-page">
            <MetaTags title="Rules of engagement" pathname={props.location.pathname} />

            <p className="policy-page__title">Rules of engagement:</p>

            <p>
                AICTY does not allow content or actions that threaten, encourage, or
                incite violence against anyone, directly or indirectly.
            </p>

            <p className="policy-page__subtitle">Hate speech</p>
            <p>
                We do not allow content that promotes violence or hatred against people
                based on characteristics like race, ethnicity, national origin, religion,
                disability, disease, age, sexual orientation, gender, or gender identity.
            </p>
            <p>
                We do not allow posts or accounts that glorify, celebrate, downplay, or
                trivialise violence, suffering, abuse, or deaths of individuals or
                groups. This includes the use of scientific or pseudoscientific claims
                to pathologise, dehumanise, or disempower others. We do not allow calls
                for intolerance, exclusion, or segregation based on protected
                characteristics, nor do we allow the glorification of groups which do
                any of the above.
            </p>
            <p>
                We do not allow hateful text, images, symbols, or other content in your
                username, profile, or bio.
            </p>

            <p className="policy-page__subtitle">Harassment</p>
            <p>We don’t tolerate harassment, which includes:</p>
            <ul>
                <li>
                    - Bullying, threatening, or shaming someone, or posting things likely
                    to encourage others to do so
                </li>
            </ul>

            <p className="policy-page__subtitle">Privacy and Reputation</p>
            <p>We do not allow the following:</p>
            <ul>
                <li>
                    - Posting copies of private communications between private individuals
                    without the explicit consent of all parties to the communication
                </li>
                <li>
                    - Doxing, which includes not only private or obscure personal
                    information but also the aggregation of publicly available information
                    to target, shame, blackmail, harass, intimidate, threaten, or endanger
                </li>
                <li>
                    - Posting intimate or explicit images taken or posted without the
                    subject’s express consent
                </li>
                <li>
                    - Content that violates others’ privacy, including sensitive or
                    confidential information such as credit card numbers, social security
                    numbers, non-public phone numbers, physical addresses, email addresses,
                    or other non-public information
                </li>
            </ul>

            <p className="policy-page__subtitle">Related conduct</p>
            <p>
                We do not allow posts or accounts that engage in on-platform,
                off-platform, or cross-platform campaigns of targeting, harassment,
                hate speech, violence, or disinformation.
            </p>

            <p className="policy-page__subtitle">Spam</p>
            <p>All spam will be immediately removed. For example:</p>
            <ul>
                <li>
                    - Posting content primarily to drive traffic to, or increase the
                    search rankings of, an external site, product, or service
                </li>
                <li>
                    - Scraping and reposting content from other sources for the primary
                    purpose of generating revenue or other personal gains
                </li>
                <li>
                    - Posting duplicate content, whether from a single account or across
                    multiple accounts
                </li>
                <li>
                    - Stories where the content is clipped with the sole purpose of linking
                    to the rest of the article on a different website
                </li>
                <li>
                    - Repeatedly using responses or other interactions as a method of promotion
                </li>
            </ul>

            <p className="policy-page__subtitle">Copyright and trademark infringement</p>
            <p>
                Respect the copyrights and trademarks of others. Unless you’re
                authorised to use someone else’s copyrighted work or trademark (either
                expressly or by legal exceptions and limitations like fair use), don’t
                do it.
            </p>

            <p className="policy-page__subtitle">Deceptive conduct</p>
            <p>This includes:</p>
            <ul>
                <li>
                    - Posting content or impersonating a person or organisation in a way
                    likely to deceive people. Parody and satire are fine, but make clear
                    that is what you’re doing.
                </li>
                <li>
                    - Phishing or fraud. Don’t use tags, links, titles, or other metadata
                    in a misleading way. Don’t link to or embed malicious or harmful
                    code or software in your posts
                </li>
                <li>
                    - Don’t use deception to generate revenue or traffic
                </li>
                <li>
                    - If you have received compensation, free goods/services or anything of
                    value in connection with the topic of a post, you must make this clear
                </li>
            </ul>

            <p className="policy-page__subtitle">Ads, Promotions, and Marketing</p>
            <ul>
                <li>
                    - Third-party advertising and sponsorships are not allowed. You may not
                    advertise or promote third-party products, services, or brands.
                    This includes images that indicate brand sponsorship in a post or letter,
                    or as part of a publication name or logo.
                </li>
                <li>
                    - Images functioning as third-party ads are not allowed. Inline images
                    or embeds that link out and function as banner ads for third-party
                    brands are not allowed.
                </li>
            </ul>

            <p className="policy-page__subtitle">Embedded Content</p>
            <p>
                In ongoing compliance with the European GDPR, we are restricting the
                behaviour of certain embedded content:
                <ul>
                    <li>
                        - Embeds that directly collect data through form fields are not allowed. This
                        includes embeds that facilitate the submission of email addresses and other
                        personally identifying information through forms and the submission of credit
                        card information.
                    </li>
                    <li>- Amazon Web Services (EU, Ireland)</li>
                    <ul className="policy-page__sublist">
                        <li>DynamoDB</li>
                        <li>ElasticSearch</li>
                    </ul>
                    <li>- Mailchimp</li>
                </ul>
            </p>

            <p className="policy-page__subtitle">Graphic content</p>
            <p>
                We do not allow pornographic images or videos. We do allow erotic
                writing and non-graphic erotic images.
                We do not allow gratuitously graphic or disturbing media, even
                if it’s not pornographic.
            </p>

            <p className="policy-page__title">Privacy Policy:</p>
            <p>
                www.allicantellyou.com is committed to protecting the privacy of visitors to
                our website, subscribers to our newsletters, and those providing contact
                information. Please read the following privacy policy to understand how
                your personal information is treated. From time to time,
                www.allicantellyou.com may offer new information or services that may
                affect this policy. If you have any questions or concerns regarding this
                privacy policy, please send an e-mail to privacy@allicantellyou.com.
            </p>

            <p className="policy-page__subtitle">
                What information do we collect and how is it used?
            </p>
            <p className="policy-page__mt">
        <span className="policy-page__subtitle">
          Information you voluntarily submit to the website:&nbsp;
        </span>
                We may collect personal information from you such as your name or e-mail address, and
                interests. For example, you may voluntarily submit personal information to&nbsp;
                <a href="https://www.allicantellyou.com">www.allicantellyou.com</a> when you
                register for the site and create your
                personal profile. We will ask you to create a user name and a password.
                We will store the user name, but your password will not be visible in your
                records. When you register any of your personal information on the site,
                make a comment or publish any information, if you have opted for
                anonymity or to use a pseudonym, then your personal details will not be
                shown on our records.
            </p>

            <p className="policy-page__mt">
                Data we collect from you and hold on the site will also be encrypted using
                SSL, to prevent access and a breach of privacy.
            </p>

            <p className="policy-page__mt">
        <span className="policy-page__subtitle">
          Information we collect from others:&nbsp;
        </span>
                We may receive information about
                you from other sources. For example, if you use a third-party software
                through the site, they may transfer information to us for fulfilment.
            </p>

            <p className="policy-page__mt">
                <span className="policy-page__subtitle">Automatically-collected Information:&nbsp;</span>
                We automatically collect certain
                information about you and the device with which you access
                www.allicantellyou.com. For example, when you use
                www.allicantellyou.com, we will log your IP address, operating system
                type, browser type, referring website, pages you viewed, and the
                dates/times when you accessed the website. We may also collect
                information about actions you take when using www.allicantellyou.com,
                such as links clicked.
            </p>

            <p className="policy-page__mt">
                <span className="policy-page__subtitle">Cookies:&nbsp;</span>
                We may log information using cookies, which are small data files
                stored on your browser by www.allicantellyou.com. We may use both
                session cookies, which expire when you close your browser, and
                persistent cookies, which stay on your browser until deleted, to provide
                you with a more personalised experience on the website.
            </p>
            <span className="policy-page__subtitle">
          How your personal information may be used:
      </span>

            <p className="policy-page__mt">
                www.allicantellyou.com may use your personal information in the following ways:
                <ul className="policy-page__mt">
                    <li>- To operate and maintain the website;</li>
                    <li>- To create your account, identify you as a user of the website, and
                        customise the website to your account;</li>
                    <li>- To send you promotional information, such as emails. Each e-mail
                        newsletter will provide information on how to opt-out of future mailings
                        by unsubscribing;</li>
                    <li>- To send you administrative communications, such as administrative e-
                        mails, confirmation e-mails, technical notices, updates on policies, or
                        security alerts;</li>
                    <li>- To respond to your comments or inquiries;</li>
                    <li>- To provide you with user support;</li>
                    <li>- To track and measure website performance;</li>
                    <li>- To protect, investigate, and deter against unauthorised or illegal
                        activity;</li>
                    <li>- To review the effectiveness of our marketing programs and analyse
                        other general demographic trends;</li>
                    <li>- To notify you of new information or services that may be of interest to
                        you.</li>
                </ul>
            </p>

            <p className="policy-page__subtitle">
                Does www.allicantellyou.com share my information?
            </p>
            <p className="policy-page__mt">
                www.allicantellyou.com will not share, rent, or sell your personally
                identifiable information with third-parties. We may, however, share your
                information with third-parties when we are authorised by you to share such
                information, or if required to do so for legal reasons.
            </p>
            <p className="policy-page__mt">
                However, where you have placed information on the site and not opted for
                anonymity, then this data may be available for public view.
            </p>
            <p className="policy-page__mt">
                Additionally, www.allicantellyou.com may use third-party service providers
                to service various aspects of the website. Each third-party service
                provider’s use of your personal information is dictated by their respective
                privacy policy. www.allicantellyou.com currently uses the following third-
                party service providers:
            </p>
            <p className="policy-page__mt">
        <span className="policy-page__subtitle">
          Tracking:&nbsp;
        </span>
                www.allicantellyou.com uses various marketing tools that help
                us create a better user experience for people visiting our site. These tools
                allow us to look at aggregated data such as scrolling patterns, clicks, and
                allow us to run tests to determine which pages result in the best actions.
                However, your personally identifiable information is not used by any of
                these tools.
            </p>

            <p className="policy-page__mt">
        <span className="policy-page__subtitle">
          Surveys:&nbsp;
        </span>
                Periodically, we may invite you to complete a voluntary survey.
                All surveys are completed through Survey Monkey. All survey responses
                may be submitted anonymously and only your IP address is stored. You
                may voluntarily enter your name and e-mail address. All data submitted
                through Survey Monkey is stored on their servers and is subject to
                the survey monkey privacy policy.
            </p>

            <p className="policy-page__mt">
        <span className="policy-page__subtitle">
          Contact information and website support:&nbsp;
        </span>
                We offer multiple ways to contact <a href="https://www.allicantellyou.com">
                www.allicantellyou.com</a> including contact forms and e-mails. If at
                any time, you attempt to contact us through a contact form, the information
                you provide will be stored on our servers. If you submit a form or email us,
                we process all these inquiries and track responses.
            </p>

            <p className="policy-page__mt">
                Except when required by law, we will not sell, distribute, or reveal your e-
                mail address(es) or other personal information without your consent;
                however, we may disclose or transfer personal information collected
                through www.allicantellyou.com to third-parties who acquire all or a portion
                of our business, which may be the result of a merger, consolidation, or
                purchase of all or a portion of our assets, or in connection with any
                bankruptcy or reorganisation proceeding brought by or against us.
            </p>

            <p className="policy-page__subtitle">Cookies:</p>
            <p>
                www.allicantellyou.com uses cookies to: store visitors’ preferences; record
                user-specific information on what pages users access or customise our
                content based on visitors’ browser type or other information that the visitor
                sends.
            </p>
            <p className="policy-page__mt">
                At any time, you may prevent the setting of cookies by the website, by
                using a corresponding setting of your internet browser and may thus
                permanently deny the setting of cookies. Furthermore, already set cookies
                may be deleted at any time via an Internet browser or other software
                programs. This is possible in all popular Internet browsers. However, if
                users deactivate the setting of cookies in your Internet browser, not all
                functions of www.allicantellyou.com may be entirely usable.
            </p>

            <p className="policy-page__subtitle">
                What is if there are changes to this privacy policy?
            </p>
            <p>
                If we change our privacy policy, we will post those changes on our privacy
                page at www.allicantellyou.com/privacy.
            </p>

            <p className="policy-page__subtitle">Rights relating to your personal data:</p>
            <p className="policy-page__mt">
                <span className="policy-page__subtitle">Opt-out:</span>
                You may opt-out of future e-mail communications by following
                the unsubscribe links in our e-mails. You may also notify us at
                privacy@allicantellyou.com to be removed from our mailing list.
            </p>
            <p className="policy-page__mt">
                <span className="policy-page__subtitle">Delete:</span>
                In certain situations, you may request that we delete your personal
                data. To do so, please submit a request to privacy@allicantellyou.com.
                Please note that we may need to retain certain information for record-
                keeping purposes or to complete transactions, or when required by law.
            </p>
            <p className="policy-page__mt">
                <span className="policy-page__subtitle">Children’s information:</span>
                www.allicantellyou.com does not knowingly collect any personally
                identifiable information from children under the age of 16. If a parent or
                guardian believes that www.allicantellyou.com has personally identifiable
                information of a child under the age of 16 in its database, please contact
                us immediately at www.allicantellyou.com and we will use our best efforts
                to promptly remove such information from our records.
            </p>

            <p className="policy-page__mt">
                <span className="policy-page__subtitle">Spam:</span>
                www.allicantellyou.com is strongly opposed to spam. We will only send e-
                mails to registered users or registered subscribers and all such individuals
                can choose to opt out at an time by clicking on an unsubscribe link or
                emailing <a href="mailto:privacy@allicantellyou.com">privacy@allicantellyou.com</a>
                and asking to unsubscribe. Examples of subscribers include:
                <ul className="policy-page__mt">
                    <li>- Opt-in subscribers to the newsletters</li>
                    <li>- Opt-in subscribers to informative and promotional emails</li>
                    <li>- Users that complete a contact form</li>
                </ul>
            </p>

            <p className="policy-page__mt">
                <span className="policy-page__subtitle">Web links:</span>
                www.allicantellyou.com contains links to other sites. Please be aware that
                www.allicantellyou.com is not responsible for the privacy practices or the
                content of such other sites. We encourage our users to be aware when
                they leave our site to read the privacy and spam policies of each and
                every website that collects personally identifiable information. This privacy
                policy applies solely to information collected by www.allicantellyou.com.
            </p>

            <p className="policy-page__mt">
                <span className="policy-page__subtitle">How do I contact www.allicantellyou.com?</span>
                If you have any further questions or comments regarding the
                www.allicantellyou.com website or this privacy policy, you may
                contact privacy@allicantellyou.com
            </p>

            <p className="policy-page__mt">
                <span className="policy-page__subtitle">Legal disclaimer:</span>
                We may disclose personal information when required by law or in the
                good-faith belief that such action is necessary in order to conform to the
                edicts of the law or comply with a legal process served on our site.
            </p>
        </div>
    );
};

export default policyPage(Rules);
