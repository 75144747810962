import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { setOpened } from '../../actions/configAction';

import Header from '../Header';

import InspirationsToolbar from '../shared/InspirationsToolbar';
import generalContentPage from "./generalContentPage";
import {compose} from "redux";

const policyPage = (WrappedComponent, pageType = 'policy') => {
  class HOC extends Component {
    render() {
      return (
        <div className="max-w-[1200px] px-pad-sm mx-auto xl:flex items-center py-14 mb-[500px] xl:mb-0">
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  }

  return HOC;
};

export default compose(
  generalContentPage,
  policyPage
);
