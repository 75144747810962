/* eslint-disable */
export default {
  cellAlign: 'left',
  contain: true,
  freeScroll: false,
  groupCells: true,
  percentPosition: true,
  prevNextButtons: false,
  adaptiveHeight: false,
  watchCSS: true
}
