import React, {useEffect, useState} from 'react';


import {getArticles, searchArticlesForCategory, getFeed} from '../../../utils/api/get';
import ButtonViewMore from "../../../atoms/ButtonViewMore";
import ButtonFilter from "../../../atoms/ButtonFilter";
import generalContentPage from "../../containers/generalContentPage";
import Button from "../../../atoms/Button";
import {Link} from "react-router-dom";
import TopArticles from "../../Articles/TopArticles";
import LoadingSpinner from "../../../atoms/LoadingSpinner";
import ArticleItem from "../../../atoms/ArticleItem";

const FilterPanel = ({ slug, showDropdown, setShowDropdown }) => {
  return (
    <>
      <div>
        <div className="hidden xl:block">
          Filter by
        </div>
        <div className="xl:hidden">
          <a className="bg-yellow rounded-md py-2 px-4 block text-dark-navy flex items-center"
             href="#" onClick={(e) => {
            e.preventDefault();
            setShowDropdown(current => !current);
          }}>
            Filter
            <img className="ml-2" src="/images/icon-chevron-down-active.svg" alt="Down"/>
          </a>
          <div className={"bg-light-grey py-2 " + (showDropdown ? '' : 'hidden')}>
            <Link to="/lifestyle" component={ButtonFilter} active={!slug}>All</Link>
            <Link to="/lifestyle/travel/articles" component={ButtonFilter} active={slug === 'travel'}>Travel</Link>
            <Link to="/lifestyle/leisure/articles" component={ButtonFilter} active={slug === 'leisure'}>Leisure</Link>
            <Link to="/lifestyle/health/articles" component={ButtonFilter} active={slug === 'health'}>Health</Link>
            <Link to="/lifestyle/purpose/articles" component={ButtonFilter} active={slug === 'purpose'}>Purpose</Link>
            <Link to="/lifestyle/relationships/articles" component={ButtonFilter} active={slug === 'relationships'}>Relationships</Link>
          </div>
        </div>
      </div>
      <div className="hidden xl:block xl:space-x-4 xl:flex-1 grow">
        <Link to="/lifestyle" component={Button} colour={!slug ? 'bg-yellow text-dark-navy' : 'bg-dark-grey text-white'}>
          All
        </Link>
        <Link to="/lifestyle/travel/articles" component={Button} colour={slug === 'travel' ? 'bg-yellow text-dark-navy' : 'bg-dark-grey text-white'}>
          Travel
        </Link>
        <Link to="/lifestyle/leisure/articles" component={Button} colour={slug === 'leisure' ? 'bg-yellow text-dark-navy' : 'bg-dark-grey text-white'}>
          Leisure
        </Link>
        <Link to="/lifestyle/health/articles" component={Button} colour={slug === 'health' ? 'bg-yellow text-dark-navy' : 'bg-dark-grey text-white'}>
          Health
        </Link>
        <Link to="/lifestyle/purpose/articles" component={Button} colour={slug === 'purpose' ? 'bg-yellow text-dark-navy' : 'bg-dark-grey text-white'}>
          Purpose
        </Link>
        <Link to="/lifestyle/relationships/articles" component={Button} colour={slug === 'relationships' ? 'bg-yellow text-dark-navy' : 'bg-dark-grey text-white'}>
          Relationships
        </Link>
      </div>
    </>
    )
}

const Explore = ({ match: { params: { slug } }, perPage = 30 }) => {
  const [ articles, setArticles ] = useState([]);
  const [ search, setSearch ] = useState('');
  const [ loading, setLoading ] = useState(undefined);
  const [ hasMore, setHasMore ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ showDropdown, setShowDropdown ] = useState(false);

  const fetchArticles = (page) => {
    return searchArticlesForCategory(slug, search, perPage, page).then(data => {
      setLoading(false);
      setHasMore(data.total.value > (perPage * page));

      return data;
    });
  }

  // New search - replace
  useEffect(() => {
    setLoading('search');

    fetchArticles(1).then(data => {
      setArticles(data.hits.posts ? data.hits.posts : []);
      setPage(1);
    })
  }, [slug, search]);

  // New page - concat
  useEffect(() => {
    setLoading('page');

    if (page > 1) {
      fetchArticles(page).then(data => {
        setArticles(articles => [...articles, ...(data.hits.posts ? data.hits.posts : [])]);
      })
    }
  }, [page]);

  return (
    <div className="overflow-hidden background-shapes">
      <div className="container px-pad-sm mx-auto py-[80px] xl:py-[120px]">
        <h2 className="font-heading font-bold text-30 lg:text-50 pb-8 xl:pb-14 max-w-[700px]">
          Be inspired and informed by our interesting, <strong
          className="text-yellow">relevant articles and blogs.</strong>
        </h2>
        <div
          className="flex items-start space-x-5 xl:space-x-10 xl:items-center justify-between mb-8 xl:mb-14 relative">
          <FilterPanel slug={slug} showDropdown={showDropdown} setShowDropdown={setShowDropdown} />
          <label
            className="xl:max-w-[400px] bg-mid-grey rounded-md pl-4 flex items-center overflow-hidden"
            onClick={e => { setShowDropdown(false) }}
          >
            {loading === 'search' && <LoadingSpinner size={5} />}
            {loading !== 'search' && <img src="/images/icon-search.svg" className="w-5 h-5" alt="Search" />}
            <input className={`appearance-none bg-mid-grey py-2 ${showDropdown ? 'w-0 p-0 pr-4' : 'w-full px-4'}`} type="text"
                   placeholder="Keyword search" onChange={e => setSearch(e.target.value)}/>
          </label>
        </div>
        <div className="grid gap-10 md:grid-cols-2 xl:grid-cols-3">
          {!loading && articles.length === 0 && <h3 className="text-10">Sorry, no results were found for your search</h3>}
          {articles.length > 0 && articles.map((item, i) => (
            <ArticleItem key={i} {...item} publishedAt={item.publishedAt.date} style={'short'} />
          ))}
        </div>
        <div className="mt-8 xl:mt-14 text-right">
          {loading === 'page' && <LoadingSpinner size={8} />}

          {!loading && hasMore && <ButtonViewMore onClick={(e) => {
            e.preventDefault();
            setPage(page => page + 1);
          }} />}
        </div>
      </div>
    </div>
  );
};
export default generalContentPage(Explore);
