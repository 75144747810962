import {
  CardCvcElement, CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {BasketContext} from "../../../providers/BasketProvider";
import {Link} from "react-router-dom";
import {checkoutCharge, checkoutIntent} from "../../../utils/api";
import LoadingSpinner from "../../../atoms/LoadingSpinner";

export default function Payment({ checkout, handleNextStep, handleStepChange, handleInputChange }) {
  const stripe = useStripe();
  const elements = useElements();
  const [showPayment, setShowPayment] = useState(false);
  const [paymentError, setPaymentError] = useState();
  const [loading, setLoading] = useState(true);
  const [charging, setCharging] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [finalAmount, setFinalAmount] = useState();
  const {
    items,
    shipping,
    coupon,
  } = useContext(BasketContext);
  const options = {
  };

  const [paymentRequest, setPaymentRequest] = useState(null);

  const handleShowPayment = (e) => {
    e.preventDefault();
    setShowPayment(true);
  }

  useEffect(() => {
    checkoutIntent(checkout, items, shipping.id, coupon?.code)
      .then(res => {
        if (res.success === true) {
          setFinalAmount(res.finalAmount);
          setClientSecret(res.clientSecret);
        }
        else {
          setPaymentError("An unknown error occurred, please try again later.");
        }

        setLoading(false);
      })
  }, []);


  useEffect(() => {

    if (stripe && clientSecret) {
      const pr = stripe.paymentRequest({
        country: checkout.billing_country,
        currency: 'gbp',
        total: {
          label: 'Order total',
          amount: finalAmount,
        },
        requestPayerName: true,
      });

      pr.on('paymentmethod', async (ev) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
          clientSecret,
          {payment_method: ev.paymentMethod.id},
          {handleActions: false}
        );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
          setPaymentError('An unknown error occurred, please try again.');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          let paymentMethod = ev.walletName === "googlePay" ? 'Google Pay' : 'Apple Pay';
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const {error} = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              setPaymentError(error.message);
            } else {
              handleNextStep(paymentMethod);
            }
          } else {
            handleNextStep(paymentMethod);
          }
          //_buttonTypeName
        }
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, clientSecret]);

  const handlePayment = async (e) => {
    e.preventDefault();
    setCharging(true);
    // Let Stripe.js handle the rest of the payment flow.
    const {error} = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });

    setCharging(false);

    if (error) {
      setPaymentError(error.message);
    } else {
      // The payment has succeeded.
      handleNextStep('Credit/Debit Card');
    }
  }

  const handlePaymentRequestShow = (e) => {
    e.preventDefault();
    paymentRequest.show();
  };

  return <>
    <div className="flex-1 flex lg:order-first order-last mt-6 lg:mt-0">
      <div className="w-full mb-5">
        <div className="flex justify-between block w-full rounded-[10px] py-3.5 px-5 bg-dark-navy text-white">
          <span className="font-bold">Details</span>
          <a className="underline hover:cursor-pointer text-[12px]" href="#" onClick={handleStepChange.bind(this, 1)}>Edit</a>
        </div>
        <div className="my-6">
          <div className="flex lg:flex-row flex-col">
            <div className="ml-4 mr-24">
              <div>{checkout.fname} {checkout.lname}</div>
              <div>{checkout.phone}</div>
              <div>{checkout.email}</div>
            </div>
            <div className="ml-4 mt-6 lg:mt-0">
              <div>{checkout.billing_address1}</div>
              <div>{checkout.billing_address2}</div>
              <div>{checkout.billing_town}</div>
              <div>United Kingdom (UK)</div>
              <div>{checkout.billing_postcode}</div>
            </div>
          </div>
        </div>
        <div className="flex justify-between block w-full rounded-[10px] py-3.5 px-5 bg-dark-navy text-white">
          <span className="font-bold">Delivery</span>
          <a className="underline hover:cursor-pointer text-[12px]" href="#" onClick={handleStepChange.bind(this, 2)}>Edit</a>
        </div>
        <div className="my-6">
          <div className="flex lg:flex-row flex-col">
            <div className="ml-4 mr-24">
              <div>{checkout.fname} {checkout.lname}</div>
              <div>{checkout.phone}</div>
              <div>{checkout.email}</div>
            </div>
            <div className="ml-4 mt-6 lg:mt-0">
              <div>{checkout.shipping_address1}</div>
              <div>{checkout.shipping_address2}</div>
              <div>{checkout.shipping_town}</div>
              <div>United Kingdom (UK)</div>
              <div>{checkout.shipping_postcode}</div>
            </div>
          </div>
        </div>
        <div className="flex justify-between col-span-2 block w-full rounded-[10px] py-3.5 px-5 bg-yellow text-dark-navy font-bold mb-6">
          <span>Payment</span>
        </div>

        {loading && (<LoadingSpinner />)}
        {!loading && (
          <>
            <div className="flex sm:flex-row flex-col justify-between items-center mb-6">
              <a onClick={handleShowPayment} className="flex justify-center rounded-[10px] w-[100%] lg:w-[45%] bg-yellow py-3.5 px-5 text-dark-navy hover:cursor-pointer">Credit/Debit Card</a>
              {paymentRequest && ['GOOGLE_PAY', 'APPLE_PAY'].includes(paymentRequest._activeBackingLibraryName) && (
                <>
                  <div className="hidden lg:flex justify-between my-8 lg:my-0">
                    <img className="sm:hidden block" src="/images/line.svg" />
                    <span className="text-center mx-4 block">Or</span>
                    <img className="sm:hidden block" src="/images/line.svg" />
                  </div>
                  <a onClick={handlePaymentRequestShow} className="hidden lg:flex justify-center rounded-[10px] w-[100%] lg:w-[45%] bg-dark-navy py-3.5 px-5 text-white hover:cursor-pointer">
                    {paymentRequest._activeBackingLibraryName === "GOOGLE_PAY" && (
                      <img src="/images/google-pay-icon.svg" />
                    )}
                    {paymentRequest._activeBackingLibraryName === "APPLE_PAY" && (
                      <><img src="/images/apple-icon.svg" /><span className="ml-1">Pay</span></>
                    )}
                  </a>
                </>
              )}
            </div>
            {showPayment && (<>
              {paymentError && (<div className="failed-validation py-4 px-6 rounded relative mb-8" role="alert">
                <img src={"images/warning.svg"} className="inline mr-2" alt="" />
                {paymentError}
              </div>)}
              <form id="debit" className="grey-input grid grid-cols-2 gap-x-10 gap-y-4">
                <div className="col-span-2 flex flex-col">
                  <label className="block mb-2">Card Number*</label>
                  <CardNumberElement options={options} className="rounded-[10px] py-3.5 px-5 border-[#ffd23f] border" />
                </div>
                <div className="col-span-1">
                  <label className="block mb-2">Expiry Date*</label>
                  <CardExpiryElement options={options} className="rounded-[10px] w-full py-3.5 px-5 border-[#ffd23f] border" />
                </div>
                <div className="col-span-1">
                  <label className="block mb-2">Card Number*</label>
                  <CardCvcElement options={options} className="rounded-[10px] w-full py-3.5 px-5 border-[#ffd23f] border" />
                </div>
                {/*<div className="col-span-2 text-[12px]">*/}
                {/*  <input type="checkbox" className="mr-1" name="newsletter_subscripe" value="Newsletter" />*/}
                {/*    <label>Subscribe to the Grandudes newsletter.</label>*/}
                {/*</div>*/}
                <div className="col-span-2 text-[12px]">
                    <label for="terms">
                      <input type="checkbox" className="mr-1" id="terms" name="terms" required value="1" onChange={handleInputChange} checked={checkout.terms} />
                      I’ve read and accept the terms and conditions*
                    </label>
                </div>
                <div className="col-span-2 flex justify-end">
                  {charging && (<button className="bg-dark-navy text-white py-3 px-4 rounded-[8px]"><LoadingSpinner size={6} colour="white" /> Taking payment</button>)}
                  {!charging && checkout.terms && (<input onClick={handlePayment} className="hover:cursor-pointer bg-yellow text-dark-navy py-3 px-4 rounded-[8px]" type="submit" value="Place Order" />)}
                  {!charging && !checkout.terms && (<input disabled={true} className="bg-[#ffd23fa1] text-dark-navy py-3 px-4 rounded-[8px]" type="submit" value="Please accept the terms to continue" />)}
                </div>
              </form>
            </>)}
            <div className="flex flex-col justify-between items-center mb-6 lg:hidden">
              {paymentRequest && ['GOOGLE_PAY', 'APPLE_PAY'].includes(paymentRequest._activeBackingLibraryName) && (
                <>
                  <div className="flex justify-between my-8 lg:my-0">
                    <img className="block" src="/images/line.svg" />
                    <span className="text-center mx-4 block">Or</span>
                    <img className="block" src="/images/line.svg" />
                  </div>
                  <a onClick={handlePaymentRequestShow} className="flex justify-center rounded-[10px] w-[100%] lg:w-[45%] bg-dark-navy py-3.5 px-5 text-white hover:cursor-pointer">
                    {paymentRequest._activeBackingLibraryName === "GOOGLE_PAY" && (
                      <img src="/images/google-pay-icon.svg" />
                    )}
                    {paymentRequest._activeBackingLibraryName === "APPLE_PAY" && (
                      <><img src="/images/apple-icon.svg" /><span className="ml-1">Pay</span></>
                    )}
                  </a>
                </>
              )}
            </div>
          </>)}
      </div>
    </div>
  </>;
}
