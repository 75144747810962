import React, { useState, useEffect } from 'react';

import generalPage from '../../containers/generalPage';
import SliderArticlesContainer from '../../containers/SliderArticlesContainer';
import HelpfulLinks from '../../OneCategory/HelpfulLinks';

import { getArticles, getArticle } from '../../../utils/api/get';
import MetaTags from "../../../atoms/MetaTags";

const articlesMap = {
  'mental-health': 'effbe44b-b827-4b0e-b75c-c4481f7d8a65',
  'happiness-and-humour': 'd68ca60d-965d-4598-84b2-66f3bec620a1',
  relationships: '7bdf1bc2-9609-47fd-a49e-71fee78f3e59',
  'meaning-of-life-and-facing-death': '3316e956-fff3-4e85-b0e4-3bc2b1e0f3aa',
  challenges: '0b550020-4b70-4634-bb4f-5d287be6380f',
  'self-esteem': '6eb01a97-4d14-4bc2-835d-3b69198f89d9',
};

const Links = props => {
  const [article, setArticle] = useState({});
  const [related, setRelated] = useState([]);
  useEffect(() => {
    const { slug } = props.match.params;
    getArticle(articlesMap[slug]).then(data => {
      setArticle(data);
      setRelated(data.relatedPosts);
    });
  }, [props.match.params.slug]);

  const author = {
    avatar: article.author && article.author.avatar ? article.author.avatar : '',
    fullName: article.author && article.author.displayName ? article.author.displayName : '',
    id: article.author && article.author.id ? article.author.id : '',
    date: article.createdAt,
  };

  const [links, setLinks] = useState([
    {
      id: 1,
      title: 'Link Title',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sed vulputate erat, et porttitor elit. Curabitur nec euismod diam. Aliquam erat volutpat. Donec non purus dui. In lobortis elementum nisl ac tempor.',
      url: 'www.link.com',
    },
    {
      id: 2,
      title: 'Link Title',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sed vulputate erat, et porttitor elit. Curabitur nec euismod diam. Aliquam erat volutpat. Donec non purus dui. In lobortis elementum nisl ac tempor.',
      url: 'www.link.com',
    },
    {
      id: 3,
      title: 'Link Title',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sed vulputate erat, et porttitor elit. Curabitur nec euismod diam. Aliquam erat volutpat. Donec non purus dui. In lobortis elementum nisl ac tempor.',
      url: 'www.link.com',
    },
  ]);

  // useEffect(() => {
  //   getArticles().then(data => {
  //     setArticles(data);
  //   });
  // }, []);

  return (
    <div>
      <MetaTags title="Links" pathname={props.location.pathname} />
      {related.length > 0 && <SliderArticlesContainer title="All I Can Tell You about…" items={related} />}
      <HelpfulLinks author={author} items={links} title={article.title} body={article.body} />
    </div>
  );
};

export default generalPage(Links);
