import { useEffect, useState } from "react";
import { getProductsById } from "../../utils/api";
import Flickity from "react-flickity-component";
import flickityConfig from "../../config/flickity-config";

const DisplayProductsByIDs = ({ ids, className = "", productsPerSlide = null }) => {
  const [data, setData] = useState([]);
  const shortcodeProductsFlickity = flickityConfig
  shortcodeProductsFlickity.prevNextButtons = true
  let productStyleSize = 'w-1/2 h-[208px] w-[162px] lg:h-[379px] lg:w-[297px]';
  if(productsPerSlide) {
    shortcodeProductsFlickity.groupCells = productsPerSlide
    productStyleSize = `w-[calc((100%-48px)/3)]`
  }
  useEffect(() => {
    getProductsById(ids).then(response => {
      setData(response.data)
    });
  }, [ids]);
    return (
      <div className={`overflow-hidden pr-[20px] py-8 yellow-slider ${className}`}>
        {
          data.length > 2 && (
            <Flickity
              className={'slider slider-with-btns yellow-flickity yellow-with-buttons related-products ' + ( (data.length > 2) ? ' !space-x-1' : ' products-on-article' )}
              options={shortcodeProductsFlickity}
            >
              {data.map(product => (
                <div key={product.id} className={`${productStyleSize}`}>
                  <a className='block h-full' href={`/shop/${product.slug}`}>
                    <img src={product.images[0]['thumbPath']} className="object-cover w-full h-full rounded-md !m-0" loading="lazy"/>
                  </a>
                </div>
              ))}
            </Flickity>
            )
        }
        {
          data.length <= 2 && (
            <div className="flex lg:gap-8 gap-4">
              {data.map(product => (
                <div key={product.id} className={productsPerSlide ? productStyleSize : 'w-1/2 h-[208px] w-[162px] lg:h-[379px] lg:w-[297px]'}>
                  <a className='block h-full' href={`/shop/${product.slug}`}>
                    <img
                      src={product.images[0]['thumbPath']}
                      className="object-cover w-full h-full rounded-md !m-0"
                      loading="lazy"
                      alt={product.title}
                    />
                  </a>
                </div>
              ))}
            </div>
          )
        }
      </div>
    )
  };

export default DisplayProductsByIDs