import { AUTH_START, AUTH_SUCCESS, AUTH_ERROR, AUTH_CLEAR_DATA } from '../actions/authAction';

const initialState = {
  isLoading: false,
  error: null,
  success: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        success: null,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        success: action.payload,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        success: null,
      };
    case AUTH_CLEAR_DATA:
      return {
        ...state,
        isLoading: false,
        error: null,
        success: null,
      };

    default:
      return state;
  }
};
