import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { routes } from '../../routes';
import { setOpened, setFollowedTab } from '../../actions/configAction';

class FeedToolbar extends Component {
  getCurrentPageTitle = () => {
    const pathMatch = this.props.match.path;
    return routes.filter(item => item.path === pathMatch)[0].title;
  };

  render() {
    const { title = 'Feed' } = this.props;
    return (
      <div className="feed-toolbar">
        <div className="feed-toolbar__wrapper feed-toolbar__wrapper--first">
          <div className="feed-toolbar__frame">
            <div className="feed-toolbar__title" onClick={() => {
                this.props.setOpened(this.props.config.opened ? null : 'feed-opened');
            }}>
                {this.getCurrentPageTitle() || 'Articles'}
            </div>
            <button
              className="feed-toolbar__btn"
              onClick={() => {
                this.props.setOpened(this.props.config.opened ? null : 'feed-opened');
              }}
            >
              {/*<span className="feed-toolbar__btn-text">{this.getCurrentPageTitle() || 'Articles'}</span>*/}
              <i
                  className={`${this.props.config.opened === 'feed-opened' ? 'fas fa-minus' : 'fas fa-plus'}`}
              />
            </button>
            {/*<button*/}
              {/*className="feed-toolbar__btn-notify"*/}
              {/*onClick={() => {*/}
                {/*this.props.setOpened(this.props.config.opened ? null : 'notification-opened');*/}
              {/*}}*/}
            {/*>*/}
              {/*<i className="far fa-bell" />*/}
            {/*</button>*/}
          </div>
        </div>
        <div className="feed-toolbar__wrapper feed-toolbar__wrapper--second">
          <div className="feed-toolbar__frame">
            <button className="feed-toolbar__btn--back" onClick={this.props.setOpened}>
              <i className="fas fa-chevron-down fa-rotate-90" />
            </button>
            <div className="notify-tabs">
              <button
                className={classNames('notify-tabs__btn', {
                  'notify-tabs__btn--active': !this.props.followedTab,
                })}
                onClick={() => {
                  this.props.setFollowedTab(0);
                }}
              >
                Followed
              </button>
              <button
                className={classNames('notify-tabs__btn', {
                  'notify-tabs__btn--active': this.props.followedTab,
                })}
                onClick={() => {
                  this.props.setFollowedTab(1);
                }}
              >
                Notifications
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FeedToolbar.propTypes = {
  config: PropTypes.object.isRequired,
  followedTab: PropTypes.string,
  setOpened: PropTypes.func,
  setFollowedTab: PropTypes.func,
  title: PropTypes.string,
};

const mapStateToProps = store => ({
  config: store.config,
});

const mapDispatchToProps = dispatch => ({
  setOpened: type => dispatch(setOpened(type)),
  setFollowedTab: number => dispatch(setFollowedTab(number)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FeedToolbar));
