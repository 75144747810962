import React from 'react';

import policyPage from '../../containers/policyPage';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import MetaTags from "../../../atoms/MetaTags";

const Cookie = props => {
    return (
        <div className='policy-page wysiwyg'>
            <MetaTags title="Cookie" pathname={props.location.pathname} />

            <h1><strong>Cookie Policy</strong></h1>
            <h2><strong>What is a cookie?</strong></h2>
            <p>A cookie is a small text file that is sent by a website server and stored on your computer. Cookies are needed for a number of functions which make websites work, or work more efficiently, and they can provide information to website owners about how you use their website. Cookies can also be used to personalise your experience on a website.</p>
            <h2><strong>How do we use cookies?</strong></h2>
            <p>We use cookies to personalise some of the information that you see on our site and for authentication purposes when you log in to your account.</p>
            <p className='policy-page__mt'>The cookies used on our site have been categorised based on definitions given in the ICC UK Cookie Guide. We use the following cookies:</p>
            <ul>
                <li><strong>Strictly necessary cookies</strong>. These are cookies that are required for the operation of our site, including for authentication purposes when you are logged in.</li>
                <li><strong>Functionality cookies</strong>. These are used to recognise you when you return to our site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region &ndash; or your cookies and marketing preferences).</li>
            </ul>
            <h2><strong>What cookies do we use?</strong></h2>
            <p>We use the following first party cookies (and similar technologies) on our site.</p>
            <div class="overflow-x-scroll md:overflow-hidden">
            <table>
                <tbody>
                <tr>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p><strong>Cookie Name</strong></p>
                    </td>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p><strong>Cookie Description</strong></p>
                    </td>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p><strong>Expiration</strong></p>
                    </td>
                </tr>
                <tr>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p>seen_join_message</p>
                    </td>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p class="min-w-[280px] lg:min-w-full"><strong>Functionality. </strong>This cookie remembers whether our pop-up sign up message has been displayed to you and, if so, prevents the same message from being displayed to you again.</p>
                    </td>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p>1 hour</p>
                    </td>
                </tr>
                <tr>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p>seen_welcome_message</p>
                    </td>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p class="min-w-[280px] lg:min-w-full"><strong>Functionality. </strong>This cookie remembers whether our pop-up welcome message has been displayed to you and, if so, prevents the same message from being displayed to you again.</p>
                    </td>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p>1 week</p>
                    </td>
                </tr>
                <tr>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p>token</p>
                        <p>(Local Storage)</p>
                    </td>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p class="min-w-[280px] lg:min-w-full"><strong>Strictly necessary. </strong>This Local Storage token remembers you when you are logged in, so that you don&rsquo;t have to enter your details again when navigating our site.</p>
                    </td>
                    <td class="lg:px-[35px] py-[20px] px-[20px]">
                        <p>None</p>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
            <h2><strong>Changing your cookie preferences</strong></h2>
            <p>You can configure cookie settings in your web browser, to block all cookies (or just third party cookies), and to clear local storage when you close your browser. However, please note that our site may not function correctly if you block the use of cookies.</p>
            <p className='policy-page__mt'>You can find out more about how to configure your cookie preferences in different browsers at the following links:</p>
            <ul>
                <li><a href='https://support.google.com/chrome/answer/95647'>Chrome</a></li>
                <li><a href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'>Firefox</a></li>
                <li><a href='https://support.apple.com/kb/ph21411?locale=en_US'>Safari</a></li>
                <li><a href='https://www.opera.com/help/tutorials/security/privacy/'>Opera</a></li>
                <li><a href='https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies'>IE</a> and <a href='https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy'>Edge</a></li>
            </ul>
            <h2><strong>Further information about cookies</strong></h2>
            <p>There&rsquo;s a lot of good information about cookies available online. For more information about cookies generally, visit <a href='http://www.allaboutcookies.org/'>allaboutcookies.org</a>.</p>
            <h2><strong>Get in touch</strong></h2>
            <p>Contact us on the details below if you have any questions about how we use cookies or about your privacy.</p>
            <h2><strong>Post</strong>:</h2>
            <p>20-22 Wenlock Road</p>
            <p>London</p>
            <p>England</p>
            <p>N1 7GU</p>
            <p><strong>Telephone </strong><strong>number</strong>: <a href='tel:+447944656758'>(+44) 07944 656758</a></p>
            <p><strong>Email</strong>: <a href='mailto:privacy@grandudes.com'>privacy@grandudes.com</a></p>
            <p><strong>Website</strong>: <a href='http://www.grandudes.com'>www.grandudes.com</a></p>
        </div>
    );
};

export default policyPage(Cookie);
