import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import Input from '../../../../atoms/form/Input';
import formValidator from '../../../../utils/form';

import ErrorParser from '../../../../atoms/ErrorParser';
import authPage from '../../../containers/authPage';
import {POST_ENDPOINTS} from "../../../../utils/api";
import MetaTags from "../../../../atoms/MetaTags";

const SignIn = props => {
  const {
    signInAction,
    auth: { error },
    pristine,
    submitting,
    handleSubmit,
    authClearData,
  } = props;

  const signInHandler = values => {
    signInAction(values).then(data => {
      if (data) {
        // set 'token' to localstorage
        localStorage.setItem('token', data.accessToken);
        // get 'me'(current user) data
        props.getMe(data.accessToken).then(user => {
          // redirect to homepage
          props.history.push(`/`);
        });
      }
    });
  };
  return (
    <div className="auth-form auth-form--sign-up">
      <MetaTags title="Sign in" pathname={props.location.pathname} />

      <form>
        <div className="auth-form__vertical-frame">
          <div className="auth-form__top">
            <div className="auth-form__title">Welcome back!</div>

            {error && <ErrorParser data={error} />}
            <Field title="Email" name="email" component={Input} type="text" validate={[formValidator.required]} />
            <Field
              title="Password"
              name="password"
              component={Input}
              type="password"
              validate={[formValidator.required]}
            />
            <Link
              to={'/resetPassword'}
              className="forgot-link"
              onClick={() => {
                authClearData();
              }}
            >
              Forgot your password?
            </Link>
          </div>
          <div>
            <button
              className="btn btn--primary"
              type="submit"
              onClick={handleSubmit(signInHandler)}
              disabled={submitting}
            >
              <span className="text-el">Log in</span>
            </button>
            <Link
              to={'/sign-up'}
              className="btn btn--secondary"
              onClick={() => {
                authClearData();
              }}
            >
              Create an account
            </Link>
            <div className="or">
              Or
            </div>
            <a
              href={POST_ENDPOINTS.signInGoogle}
              className="btn btn--secondary"
              onClick={() => {
                authClearData();
              }}
            >
              <i className="icon fab fa-google"></i>Login with Google
            </a>
            <a
              href={POST_ENDPOINTS.signInFacebook}
              className="btn btn--secondary"
              onClick={() => {
                authClearData();
              }}
            >
              <i className="icon fab fa-facebook" /> Login with Facebook
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

SignIn.propTypes = {
  signInAction: PropTypes.func,
  auth: PropTypes.object,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
};

const form = reduxForm({
  form: 'signIn', // a unique identifier for this form
})(SignIn);

export default authPage(form);
