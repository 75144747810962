import { LOADER_START, LOADER_END } from '../actions/loaderAction';

const init = {
  isLoading: false,
};

function loader(state = init, action) {
  switch (action.type) {
    case LOADER_START:
      return {
        isLoading: true,
      };
    case LOADER_END:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
}

export default loader;
