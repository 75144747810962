import React from "react";
import {Link} from "react-router-dom";

function PageBlockPackagesGrid({title, products, route, bg_color = 'navy', className, padding = true, current_id = null}) {
    const bg_colour_class = 'bg-dark-' + bg_color;
    const text_colour = ['yellow', 'white'].includes(bg_color) ? 'text-dark-navy' : 'text-white';
    const padding_class = padding ? 'py-gap-sm md:py-gap-md lg:py-gap-lg' : '';

    return (
      <div className={className + ' ' + bg_colour_class}>
        <div className={'container px-pad-xs xl:px-pad-sm mx-auto md:gap-32 gap-16 items-center relative ' + padding_class }>
          <div className={'lg:text-22 lg-18 ' + text_colour}>
            <h2 className="font-heading lg:text-40 text-30 font-bold mb-16 max-w-3xl">{title}</h2>
              <div className="w-full grid gap-y-10 ld:gap-y-0 md:grid-cols-2 lg:grid-cols-3 gap-x-14 lg:pb-8 text-dark-navy">
                {Object.values(products).map((product, i) => <>
                  <Link to={`${route}/${product.slug}`} className={`group ${current_id === product.id ? 'hidden lg:flex' : 'flex'} flex-col col-span-1 bg-light-grey hover:bg-yellow group rounded-3xl p-10 2xl:p-14`}>
                    <h4 className="font-heading text-30 font-bold mb-5">{product.name}</h4>
                    <h4 className="lg:text-50 font-heading text-30 font-extrabold mb-10">{product.slug === 'gold-package' ? 'From ' : ''}&pound;{parseInt(product.price)}</h4>
                    <div className="grow text-18 mastery-list" dangerouslySetInnerHTML={{__html: product.description }}></div>
                    <span className={`bg-yellow text-dark-navy group-hover:bg-dark-navy group-hover:text-light-grey rounded-lg text-center py-2 px-4 mt-12 text-18 inline-block`}>Choose package</span>
                  </Link>
                </>)}
              </div>
          </div>
        </div>
      </div>
    );
}

export default PageBlockPackagesGrid;
