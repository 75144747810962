import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LoadingSpinner from "./LoadingSpinner";

const LoadingBlock = ({ size= 8, colour = 'black', classNames = '', text = undefined, ...props }) => (
  <div className={`align-middle text-center text-${colour} text-${size * 2} ${classNames}`} {...props}>
    <LoadingSpinner size={size} colour={colour} />
    {text && (
      <h3>{text}</h3>
    )}
  </div>
);

LoadingBlock.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.number,
};

export default LoadingBlock;
