import React, {useEffect, useState} from 'react';

import MetaTags from "../../../atoms/MetaTags";
import generalContentPage from "../../containers/generalContentPage";
import Button from "../../../atoms/Button";
import NextArrow from "../../../atoms/NextArrow";
import BlockSignUp from "../../../atoms/BlockSignUp";
import {Link} from "react-router-dom";

function ImageWithSmallBackgroundShape({image_url, image_classes, shape_position}) {
  let base_shape_classes = 'absolute lg:h-80 sm:h-56 h-48 -z-10 lg:-bottom-56 sm:-bottom-36 -bottom-24';
  if(shape_position === 'right') {
    base_shape_classes += ' -scale-x-100 -right-[80px] right-12';
  } else {
    base_shape_classes += ' lg:-left-[80px] left-12';
  }
  return (
    <div className="relative">
      <img src={image_url} alt="Photo"  className={image_classes}/>
      <img src="/images/yellow-background-shape.svg" className={base_shape_classes}/>
    </div>
  );
}

const Mastery = (props) => {
  return (
    <>
      <MetaTags title="Mastery" pathname={props.location.pathname} />

      <div className="background-shapes-dark">
        <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
          <div className="flex items-center mb-8">
            <div className="rounded-full bg-white h-[50px] aspect-square flex items-center justify-center mr-4">
              <img className="inline-block h-[35px]" src="/images/mastery-icon.svg" alt="Mastery"/>
            </div>
            <div className="uppercase text-14">
              Mastery
            </div>
          </div>
          <div className="mb-10">
            <strong className="block leading-[14px] lg:text-[100px] font-heading font-bold mt-14">“</strong>
            <figure>
              <blockquote className="block text-40 lg:text-60 font-heading font-bold">
                <p>Rule your mind or it will rule you</p>
              </blockquote>
              <figcaption className="mt-4 text-18">Buddha</figcaption>
            </figure>
          </div>
          <p className="lg:text-22 text-18 mt-4 max-w-lg">Mastery is about achieving the pinnacle of understanding and control.</p>
          <p className="mt-4 max-w-lg">There are 2 types of Mastery:</p>

          <p className="mt-4 max-w-lg"><strong>Internal Mastery</strong> This is where we have established our values. We then think and act congruently, and in alignment with them.</p>

          <p className="mt-4 max-w-lg"><strong>External Mastery</strong> This is where we acquire skills and knowledge. We then seek to become highly proficient in them, such as playing an instrument or learning a language.</p>

          <div className="bottom-[50px] right-[20px] lg:right-[50%] md:mr-[-60px] absolute">
            <NextArrow />
          </div>
        </div>
      </div>
      <div className="bg-mid-grey relative">
        <div className="max-w-[1200px] px-pad-sm mx-auto xl:flex items-center py-14 mb-[700px] xl:mb-0">
          <div className="order-2 flex-1 xl:ml-[150px]">
            <h2 className="font-heading text-dark-navy lg:text-50 text-36 font-bold mb-6">Benefits of<br/> <span className="text-yellow">Mastery</span></h2>
            <p className="lg:text-22 text-18 mb-6">We all face challenges in life. How we navigate each of these determines our experience and our subsequent path.</p>
            <p className="mb-6">If we use our opportunities to acquire Mastery, we will know a life well lived. We will find inner peace, self-respect, and meaningful relationships. We will have awesome experiences, plenty of laughter and a sense of achievement.</p>
            <p>If Mastery is one of your core values, then you will be on a purposeful and fulfilling path.</p>
          </div>
          <div
            className="rountheded-xl w-[559px] translate-x-[-100px] mb-[-500px] lg:translate-x-0 xl:mb-0 mt-14 xl:mt-0 xl:h-[700px] xl:translate-y-[200px]">
            <ImageWithSmallBackgroundShape image_url="/images/mastery-image1.jpeg"
                                           shape_position="left" image_classes="rounded-xl" shape_classes=""/>
          </div>
        </div>
      </div>
      <div className="container p-pad-sm mx-auto xl:pt-[500px] grid md:grid-cols-2 md:gap-28 gap-20 items-center">
        <div>
          <h2 className="font-heading lg:text-50 text-36 font-bold mb-8">It’s time to tackle  <br></br><span className="text-yellow">what matters</span></h2>
          <p className="lg:text-22 text-18 mb-6">You have probably had some great times In life so far, and undoubtedly those that weren’t so good.</p>
          <p>For most of us, adult life has largely entailed managing practicalities such as work, finances, and
            family. However, mid age onwards is usually a time of transition for many men, when new horizons
            lie ahead. It is time to take stock and pursue a life of considered choice, creating a living legacy and
            departing without regrets.</p>
        </div>
        {/*<div>*/}
        {/*  <div className="relative">*/}
        {/*    <iframe src="https://player.vimeo.com/video/771922040?h=fe1bbe80a0" width="640"*/}
        {/*            height="360" className="w-full rounded-xl" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"*/}
        {/*            allowFullScreen></iframe>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className="bg-yellow">
        <div className="container p-pad-sm mx-auto grid md:grid-cols-2 md:gap-32 gap-16 py-gap-sm md:py-gap-md lg:py-gap-lg items-center">
          <div className="flex justify-center order-2">
            <img src="/images/mastery-icon.svg" className="w-full max-w-[229px]"/>
          </div>
          <div className="text-dark-navy lg:text-22 lg-18 md:order-2 order-1">
            <p className="mb-6">Now you can discover that the best is now and still to come. These can truly be the golden years;
              with our tools and support you can start to create the life you may only have dreamed of before.</p>
            <p className="mb-6">We have developed an interactive digital handbook to enable Grandudes to make the very most of life. By providing the handbook electronically, we can ensure that content is always up to date and relevant.</p>
          </div>
        </div>
      </div>
      <div className="bg-dark-navy">
        <div className="container p-pad-sm mx-auto grid md:grid-cols-2 md:gap-32 gap-16 py-gap-sm md:py-gap-md lg:py-gap-lg items-center relative">
          <div className="text-white lg:text-22 lg-18">
            <h2 className="font-heading lg:text-50 text-36 font-bold mb-6">The <span className="text-yellow">Grandudes</span><br/> Handbook</h2>
            <p className="mb-8">The Grandudes’ Handbook is unique in that, not only is it geared solely towards the interests and needs of men over 50, but it has optional features to connect you to real people via videos, zoom calls, one to one meetings and interactive lessons.</p>
            <p className="mb-6">
              <Link to={'/mastery/handbook'} className="bg-yellow text-black rounded-md py-2 px-4 inline-flex items-center text-16">Get your Mastery Handbook
                <span className="ml-6">
                  <svg width="8" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 12.6665L6.66667 6.99984L1 1.33317" stroke="#23323F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </Link>
            </p>
          </div>
          <div className="absolute top-[-160px] right-[-100px]">
            <img src="/images/background-shape3.svg" className="opacity-[5%] w-[600px]"/>
          </div>
        </div>
      </div>
      <div className="my-32">
        <BlockSignUp />
      </div>
    </>
  );
};
export default generalContentPage(Mastery);
