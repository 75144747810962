import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import Input from '../../../../atoms/form/Input';
import formValidator from '../../../../utils/form';

import authPage from '../../../containers/authPage';
import ErrorParser from '../../../../atoms/ErrorParser';
import MetaTags from "../../../../atoms/MetaTags";

const ResendVerification = props => {
    const {
        resendVerification,
        auth: { success, error },
        pristine,
        submitting,
        handleSubmit,
        authClearData,
        reset,
    } = props;
    const resetHandler = values => {
        resendVerification(values).then(data => {
            if (data) {
                reset();
                // get 'me'(current user) data
                // props.getMe(data.accessToken).then(user => {
                //   // redirect to homepage
                //   props.history.push('/');
                // });
            }
        });
    };

    useEffect(() => {
        authClearData();
    },[]);

    return (
        <div className="auth-form auth-form--sign-up">
          <MetaTags title="Resend Verification" pathname={props.location.pathname} />

          <form>
                <div className="auth-form__vertical-frame">
                    <div className="auth-form__top">
                        <div className="auth-form__title">Resend your email verification</div>
                        {success && (
                            <div className="auth-form__success-validation">
                                The verification email has been resent
                            </div>
                        )}
                        {error ? <ErrorParser data={error} /> : ''}
                        <Field
                            title="Email"
                            name="email"
                            component={Input}
                            type="text"
                            validate={[formValidator.required, formValidator.email]}
                        />
                    </div>
                    <div>
                        <button
                            className="btn btn--primary"
                            type="submit"
                            onClick={handleSubmit(resetHandler)}
                            disabled={submitting}
                        >
                            <span className="text-el">Resend</span>
                        </button>
                        <Link
                            to={'/login'}
                            className="btn btn--secondary"
                            onClick={() => {
                                authClearData();
                            }}
                        >
                            Log in
                        </Link>
                    </div>
                </div>
            </form>
        </div>
    );
};

const form = reduxForm({
    form: 'resendVerification', // a unique identifier for this form
})(ResendVerification);

export default authPage(form);
