import React, {useContext, useEffect, useState} from 'react';

import {Link, Redirect} from "react-router-dom";
import MetaTags from "../../../atoms/MetaTags";
import generalContentPage from "../../containers/generalContentPage";
import {BasketContext} from "../../../providers/BasketProvider";
import {loadStripe} from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardElement, CardExpiryElement, CardNumberElement,
  Elements,
  PaymentRequestButtonElement,
  useStripe
} from "@stripe/react-stripe-js";
import {AddressElement} from '@stripe/react-stripe-js';
import Details from "./Details";
import Payment from "./Payment";
import Delivery from "./Delivery";
import Complete from "./Complete";
import Button from "../../../atoms/Button";

export function Sidebar({ isOrder = false }) {
  const [updating, setUpdating] = useState(false);
  const [quantities, setQuantities] = useState({});

  let {
    items,
    order,
    updateItem,
    shipping,
    calculateSubTotal,
    calculateTotal,
    coupon,
    itemIsDiscounted,
  } = useContext(BasketContext);

  let total;
  let subTotal;

  if (isOrder && order.items && Object.keys(order.items).length > 0) {
    items = order.items;
    shipping = order.shipping;
    subTotal = order.subTotal;
    total = order.total;
    coupon = order.coupon;
  }
  else {
    subTotal = calculateSubTotal();
    total = calculateTotal();
  }

  const handleUpdate = e => {
    e.preventDefault();
    setUpdating(true);
  };

  const handleDoneUpdate = e => {
    e.preventDefault();

    Object.entries(quantities).forEach(([id, qty]) => {
      updateItem(id, qty);
    });

    setUpdating(false);
  };

  return <div className="flex-1 lg:justify-end lg:flex hidden">
    <div className="bg-white lg:max-w-[440px] rounded-[10px] w-full">
      <div
        className="flex justify-between bg-[#F5F5F5] text-dark-navy py-3.5 px-5 rounded-[10px]">
        <span className="font-bold">Your order</span>
        {!isOrder && !updating && <>
          <a className="hover:cursor-pointer underline"
             onClick={handleUpdate}>Edit</a>
        </>}
        {!isOrder && updating && <>
          <a className="hover:cursor-pointer underline"
             onClick={handleDoneUpdate}>Done</a>
        </>}
      </div>
      <div className="lg:block hidden">
        <div>
          <span className="block py-4 px-4 text-[14px]">Product</span>
          {Object.entries(items).map(([id, item]) => (
            <div className="flex border-b border-[#F5F5F5] pb-10 ml-4">
              <div><img className="h-[80px] bg-[#F5F5F5]"
                        src={item.thumbUrl} /></div>
              <div className="flex flex-col text-[14px] ml-10">
                <div className="font-bold text-[16px]">{item.name}</div>
                {/*<span className="py-1">Design: 1</span>*/}
                {!updating && <>
                  <div className="py-1">Qty: {item.qty} &pound;{(item.price * item.qty).toLocaleString('en-GB',{ minimumFractionDigits: 2 })}</div>
                  {item.variations && item.variations.map(variation => (
                    <div>{variation.name}: &pound;{(variation.price * item.qty).toLocaleString('en-GB', { minimumFractionDigits: 2 })}</div>
                  ))}
                  {itemIsDiscounted(parseInt(id), coupon) && (
                    <span className="py-1">Discount: {coupon.percent}%</span>
                  )}                
                  </>
                }
                {updating && <>
                  <div>
                    <span className="mr-2">Qty:</span>
                    <input
                      className={`placeholder-black p-1 border border-[#ffd23f] rounded-md w-[100px]`}
                      type="number" value={quantities[id] === undefined ? items[id].qty : quantities[id]} onChange={e => setQuantities({ ...quantities, [id]: e.currentTarget.value})}/>
                  </div>
                </>}
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between border-b border-[#F5F5F5] text-[14px]">
          <span className="py-4 px-4">Subtotal</span>
          <span className="py-4 px-4">£{subTotal.toLocaleString('en-GB', { minimumFractionDigits: 2 })}</span>
        </div>
        {coupon.code !== '' && <>
          <div className="flex justify-between border-b border-[#F5F5F5] text-[14px]">
            <span className="py-4 px-4">Coupon: {coupon.code}</span>
            <span
              className="py-4 px-4">{coupon.percent.toLocaleString('en-GB')}% off{coupon.products.length > 0 ? ' selected' : ''}</span>
          </div>
        </>}
        {shipping && <>
          <div className="flex justify-between border-b border-[#91989F] text-[14px]">
            <span className="py-4 px-4">{shipping.name}</span>
            <span className="py-4 px-4">{shipping.price === 0 ? 'Free' : `£${shipping.price.toLocaleString('en-GB',{ minimumFractionDigits: 2 })}`}</span>
          </div>
        </>}
        <div
          className="flex justify-between border-b border-[#91989F] text-[16px] font-bold">
          <span className="py-4 px-4">Total</span>
          <span className="py-4 px-4">£{total.toLocaleString('en-GB', { minimumFractionDigits: 2 })}</span>
        </div>
        <span className="mt-4 pl-4 block font-bold">Shipping charges</span>
        <p className="pl-4 text-[14px] pb-10">Standard shipping (£2.99) is delivered within 3 to 7 working days of order processing. Express delivery (£4.99) is delivered within 12 to 48 hours of order processing for deliveries within the UK. Outside of the UK will be up to 3 days longer depending on location.</p>
      </div>
    </div>
  </div>
}

function Checkout({location}) {
  let initialCheckoutState = {
    billing_country: 'GB',
    shipping_country: 'GB',
    terms: false,
  };
  const [checkout, setCheckout] = useState(initialCheckoutState);
  const [step, setStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const {
    convertToOrder,
    order,
    resetOrder,
    basket,
  } = useContext(BasketContext);

  useEffect(() => {
    if (step === 1) {
      resetOrder();
    }
  }, [step]);

  if ((!basket.items || Object.keys(basket.items).length === 0) && (!order.items || Object.keys(order.items).length === 0)) {
    return <Redirect to={"/basket"} />;
  }

  const handleInputChange = (event) => {
    setCheckout({
      ...checkout,
      [event.target.name]: (!['radio', 'checkbox'].includes(event.target.type) || event.target.checked) ? event.target.value : undefined,
    });
  }

  let handleStepChange = (step, e) => {
    e.preventDefault();
    setStep(step);
  }

  let handleFinalStep = (paymentMethod) => {
    window.scrollTo(0, 0);
    convertToOrder();
    setPaymentMethod(paymentMethod);

    // Must go last
    setStep(4);
  }

  let handleSetSameAsBilling = (e) => {
    setCheckout({
      ...checkout,
      same_as_billing: e.target.checked,
      shipping_address1: checkout.billing_address1,
      shipping_address2: checkout.billing_address2,
      shipping_town: checkout.billing_town,
      // shipping_county: checkout.billing_county,
      shipping_postcode: checkout.billing_postcode,
      shipping_country: checkout.billing_country,
    });
  }

  return (
    <>
      <MetaTags title="Checkout" pathname={location.pathname}/>

      <div className="container mx-auto px-[40px]">
        <div className="flex items-center max-w-[1200px] mx-auto mx-auto px-[40px] bg-dark-navy lg:mt-[112px] mt-10 h-auto lg:py-[40px] py-[25px]">
          <span className="text-white lg:text-20">Please do not checkout, the shop is not operational until 1st October 2023.</span>
        </div>
      </div>
      <div className="container mx-auto px-[40px]">

        {step < 4 && (<>
          <div className="block max-w-[1200px] mx-auto mb-[71px] mt-[50px]">
            <p className="mb-4"><Link to="/basket" className="text-14 text-dark-grey mb-5">&lsaquo; Back to basket</Link></p>
            <span className="uppercase">Checkout</span>
          </div>
        </>)}
        {step === 4 && (
          <div className="flex max-w-[1200px] pb-[71px] mx-auto">
            <img className="w-[48px] aspect-square self-end"
                 src="/images/smiley-thanks.svg" />
            <h2 className="mt-[112px] text-[30px] ml-6 font-heading font-bold">Thank you for your order</h2>
          </div>
        )}
      </div>
      <div className="lg:bg-white">
        <div className="container mx-auto px-[40px]">
          <img className="absolute right-0 top-[-100px] z-[-1] lg:block hidden"
               src="/images/background-letter-logo.svg" />
            <div className="flex flex-col lg:flex-row max-w-[1200px] mx-auto bg-white">
              {/*Left content*/}
              <div className="flex-1">
                {step === 1 ? <Details checkout={checkout} handleInputChange={handleInputChange} handleNextStep={handleStepChange.bind(this, 2)} /> : ''}
                {step === 2 ? <Delivery checkout={checkout} handleInputChange={handleInputChange} handleSetSameAsBilling={handleSetSameAsBilling} handleNextStep={handleStepChange.bind(this, 3)} handlePrevStep={handleStepChange.bind(this, 1)} /> : ''}
                <Elements stripe={stripePromise}>
                  {step === 3 ? <Payment checkout={checkout} handleNextStep={handleFinalStep} handleStepChange={handleStepChange} handleInputChange={handleInputChange} /> : ''}
                </Elements>
                {step === 4 ? <Complete checkout={checkout} paymentMethod={paymentMethod} /> : ''}

              </div>
              {/*Right content*/}
              {/*Should appear on desktop, hidden on mobile */}
              <Sidebar isOrder={step === 4} />
            </div>
        </div>
      </div>
    </>
  );
};
export default generalContentPage(Checkout);
