import React from 'react';

const Checkbox = ({ title, input, tickedByDefault, meta: { touched, error, warning } }) => (
  <label className="form__field">
    <span className="form__label-input">
      <input type="checkbox" className="checkbox__input" onChange={input.onChange} defaultChecked={tickedByDefault}/>
      <span className="checkbox__icon" />
      <span className="checkbox__text">{title}</span>
      {touched &&
        ((error && <span className="error">{error}</span>) || (warning && <span className="error">{warning}</span>))}
    </span>
  </label>
);

export default Checkbox;
