import React, { useState, useEffect } from 'react';

import generalPage from '../../containers/generalPage';
import Icon from '../../../Icon'

import { getHelpfulLinks } from "../../../utils/api";


import helpfulLinkCategories from '../../../config/helpful-links-config';
import MetaTags from "../../../atoms/MetaTags";
import generalContentPage from "../../containers/generalContentPage";
import Button from "../../../atoms/Button";
import {Link} from "react-router-dom";
import NextArrow from "../../../atoms/NextArrow";
import LoadingBlock from "../../../atoms/LoadingBlock";
import flickityConfig from "../../../config/flickity-config";
import Flickity from "react-flickity-component";
import BlockSignUp from "../../../atoms/BlockSignUp";

function Card({slug, icon, title, description, buttonText, iconPadding, classes}) {
    classes = classes + ' rounded-xl p-16 bg-light-grey';
    return (
        <div className={classes}>
          <div className="w-full pb-6 h-[160px] align-middle table-cell">
            <img src={icon} />
          </div>
          <h6 className="font-heading font-bold text-30 mb-4">{title}</h6>
          <div className="mb-4 grow">{description}</div>

          <div>
            <Link to={`/toolkit/${slug}`} component={Button} colour="bg-yellow text-dark-navy">
              {buttonText}
            </Link>
          </div>
        </div>
    );
}

const HelpfulLinks = props => {
    const [ loading, setLoading] = useState(true);
    const [ helpfulLinks, setHelpfulLinks] = useState([]);
    const [ flickityRef, setFlickityRef] = useState(null);

    useEffect(() => {
        getHelpfulLinks().then(result => {
            setHelpfulLinks(result);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
      if (flickityRef) {
        let resize = function() {
          let maxHeight = Math.max(...flickityRef.slides.map(slide => slide.height));

          flickityRef.cells.forEach(cell => cell.element.style.minHeight = maxHeight + 'px');
        };
        flickityRef.on( 'ready', resize);
        flickityRef.on( 'resize', resize);
        flickityRef.on( 'deactivate', function() {
          flickityRef.cells.forEach(cell => cell.element.style.minHeight = '');
        });
      }
    }, [flickityRef]);

    return (

        <>
          <MetaTags title="Toolkit" pathname={props.location.pathname} />

          <div className="overflow-hidden background-shapes">
              <div className="container px-pad-sm mx-auto py-[80px] xl:py-[120px]">
                <div className="flex items-center">
                    <div className="uppercase text-14">
                        Toolkit
                    </div>
                </div>
                <h2 className="font-heading font-bold text-30 lg:text-50 pt-3 pb-6 xl:pb-4 xl:pb-8 max-w-[650px]">
                    So that you can make life relevant decisions, we have a wealth of <span className="sm:text-yellow">useful information</span> to benefit and support you.
                </h2>
                <div className="max-w-[728px] md:mb-24 mb-12 flex justify-between md:flex-row flex-col items-end">
                  <p className="text-20 max-w-[588px]">We provide you with resources that are aimed at removing age as a barrier to ambition and happiness to make sure you have all the skills necessary to design a life you enjoy.</p>
                  <NextArrow classes="md:ml-12 md:mt-0 mt-12 md:mr-0 mr-24 shrink-0" />
                </div>
              </div>
              <div className="bg-dark-navy background-shapes-bottom-left-dark">
                  <div className="container px-pad-sm mx-auto py-[80px] xl:py-[120px] flex relative justify-center items-center lg:flex-row flex-col">
                      <div className="lg:mr-20 lg:w-1/2 order-2 lg:order-1 lg:mt-0 sm:mt-24 mt-16 ">
                          <img src="/images/illustration-man-in-suit-with-icon.svg" className="lg:absolute bottom-0 lg:max-w-[653px] lg:w-1/2 w-full lg:mb-0 -mb-20"/>
                      </div>
                      <div className="wysiwyg text-white max-w-md flex-1 order-1">
                          <p>From finances to grief and death, some things in life are inevitable, yet we don't like to deal with them or even think of them sometimes. We will meet them head-on with you. We're all in the same boat, just some of us are further downstream.</p>
                          <p>Then we have resources for all the things to celebrate and look forward to in your golden years, such as courses for personal growth, learning an instrument or language, finding social groups and likeminded people, selecting the right life therapist or personal trainer.</p>
                          <p>Here’s your toolkit of valuable resources and advice to help you maintain a happy, healthy life, and live it to the fullest on your terms.</p>
                      </div>
                  </div>
              </div>
              <div className="container px-pad-sm mx-auto py-[80px] xl:py-[120px]">
                {loading && <LoadingBlock colour="black" size={10} text="Loading" classNames="align-center" />}
                {!loading && helpfulLinks.byCategory.length === 0 && (
                  <div>
                    Sorry, nothing has been added to the toolkit yet.
                  </div>
                )}
                {!loading && (
                  <Flickity
                    flickityRef={c => setFlickityRef(c)}
                    className={'lg:grid xl:grid-cols-3 lg:grid-cols-2 gap-16 slider xl:no-slider'}
                    static={true}
                    options={flickityConfig}
                  >
                    {helpfulLinkCategories.map(category => (helpfulLinks.byCategory[category.title] != null) && (
                      <Card key={category.title} slug={category.slug} title={category.title} description={category.description} buttonText="Read more" icon={category.icon} iconPadding="mb-24" classes="lg:mr-0 mr-6 flex flex-col"/>
                    ))}
                  </Flickity>
                )}
              </div>
          </div>
          <div className="mb-32">
            <BlockSignUp />
          </div>
        </>
    );

  /**
   *
   *           {
   *                 helpfulLinks && helpfulLinkCategories.map(item =>
   *                     <div className="helpfullinks--item">
   *
   *                         <a className="helpful-link" href={`/toolkit/${item.slug}`}>
   *                             <div className="icon"><Icon name={item.icon} /></div>
   *                             <div className="body">
   *                                 <div className="text">{item.title}</div>
   *                                 <div className="resources">{helpfulLinks['byCategory'][item.title] ? helpfulLinks['byCategory'][item.title].length : "0" } Resources</div>
   *                             </div>
   *                         </a>
   *                     </div>
   *                 )
   *             }
   */
};

export default generalContentPage(HelpfulLinks);
