import React, { Fragment, useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import ArticleItem from '../../atoms/ArticleItem';

const SliderArticlesContainer = ({ items, title }) => {
  const [ slideToFade, setSlideToFade ] = useState(3);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 3,
    nextArrow: <div className="slider-arrow-chevron"><i className={`fas fa-chevron-right`} style={{ paddingLeft: 3 }}></i></div>,
    prevArrow: <div className="slider-arrow-chevron"><i className={`fas fa-chevron-left`} style={{ paddingRight: 3 }}></i></div>,
    responsive: [
      {
        breakpoint: 776,
        settings: {
            slidesToShow: 2.2,
            slidesToScroll: 1,
            arrows: false,
        }
        },
        {
          breakpoint: 500,
          settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1,
              arrows: false,
          },
      }
    ],
    beforeChange: function(currentSlide , nextSlide) {
      setSlideToFade(nextSlide + 3)
    }
  };
  return (
    <Fragment>
      {items.length > 0 && (
        <section className="top-articles">
          {/*<div className="articles__head">*/}
            {/*<h1 className="articles__title">{title}</h1>*/}
          {/*</div>*/}
          <div className="top-articles__list-wrap">
            <Slider {...settings}>
              {items.map((item, index) => (
                <ArticleItem key={item.id} makeTransparent={index === slideToFade} {...item} />
              ))}
            </Slider>
          </div>
        </section>
      )}
    </Fragment>
  );
};

SliderArticlesContainer.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
};

export default SliderArticlesContainer;
