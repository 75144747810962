import React from 'react';

const Loader = props => (
  <div className="loader">
    <div className="loader__el">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loader;
