import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { closeInspiration } from '../../actions/inspirationsAction';

const Inspirations = props => {
  const {
    inspirations: { closed, current },
    close,
  } = props;
  return !closed ? (
    <div className="inspirations">
      <div className="inspirations__text">{current}</div>
      <button type="button" className="inspirations__btn" onClick={close}>
        <span className="inspirations__btn-text">Dismiss</span>
        <i className="fas fa-times" />
      </button>
    </div>
  ) : null;
};

Inspirations.propTypes = {
  inspirations: PropTypes.object,
  close: PropTypes.func,
};

const mapStateToProps = store => ({
  inspirations: store.inspirations,
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeInspiration()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Inspirations);
