import React, { useState, useEffect } from 'react';

import generalPage from '../../containers/generalPage';
import SliderArticlesContainer from '../../containers/SliderArticlesContainer';
import ArticlesContainer from '../../containers/ArticlesContainer';

import ItemsWithPagination from '../../containers/ItemsWithPagination';

import { getArticles } from '../../../utils/api/get';
import MetaTags from "../../../atoms/MetaTags";

const Articles = props => {
  const [articles, setArticles] = useState([]);

  const getArticlesWithSeverity = (severity) => {
    return (limit = 10, startKey) => {
      return getArticles(limit, startKey, severity);
    };
  };

  useEffect(() => {
    getArticles(8, '', 'top').then(data => {
      setArticles(data);
    });
  }, []);

  return (
    <div>
      <MetaTags title="Articles" pathname={props.location.pathname} />

      <div style={{ marginBottom: 16 }}>
        <p>
          These personal stories are from our community and selected authors. You can search by author or
          topic.
        </p>
        <p>
          If you would like to share your own stories please email us at <a href="mailto:hello@allicantellyou.com">hello@allicantellyou.com</a>
        </p>
      </div>
      <SliderArticlesContainer title="Today's Top Articles" items={articles} />
      <ItemsWithPagination getItems={getArticlesWithSeverity('!top')} title="Latest Articles" wrappedComponent={ArticlesContainer} />
    </div>
  );
};

export default generalPage(Articles);
