import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// start_key  ----- це ід з якої буде починатись вибірка
// direction ------- top_to_bottom або bottom_to_top
// limit ------- дефолтний ліміт по аплікусі це 20 (але для постів дефолтний змінений на 6 , для фіда на 3)

const ItemsWithPagination = props => {
  const [items, setItems] = useState([]);
  const [moreAviable, setMoreAviable] = useState(false);
  // const [loading, setLoading] = useState(false);

  const { getItems, wrappedComponent: WrappedComponent, limit = 12, title, loadMoreText = 'See more...' } = props;

  const loadItems = (itemsLimit, id) => {
    return getItems(itemsLimit, id).then((data = []) => {
      setItems([...items, ...data]);
      setMoreAviable(data.length === limit);
    });
  };

  useEffect(() => {
    loadItems(limit);
  }, []);

  const loadMore = () => {
    const paginationId = items[items.length - 1].paginationKey;
    loadItems(limit, paginationId);
  };

  return (
    <div className="items-with-pagination">
      {items.length > 0 && <WrappedComponent title={title} items={items} />}
      {moreAviable && (
        <div className="see-more">
          <button className="see-more__btn" onClick={loadMore}>
            {loadMoreText}
          </button>
        </div>
      )}
    </div>
  );
};

ItemsWithPagination.propTypes = {
  getItems: PropTypes.func,
  wrappedComponent: PropTypes.func,
  limit: PropTypes.number,
  title: PropTypes.string,
  loadMoreText: PropTypes.string,
};

export default ItemsWithPagination;
