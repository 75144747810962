import React from 'react';

import PropTypes from 'prop-types';

import SliderArticlesContainer from '../containers/SliderArticlesContainer';
import ArticleItem from "../../atoms/ArticleItem";
import flickityConfig from "../../config/flickity-config";
import Flickity from "react-flickity-component";


const TopArticles = ({ items, align = 'left' }) => {
  let big_index;

  if (items.length < 4) {
    big_index = undefined;
  }
  else if (align === 'left') {
    big_index = 0;
  }
  else {
    big_index = 1;
  }

  if (!items.length) {
      return (
        <div className="text-black">
          Sorry, no items were found.
        </div>
      )
  }

  return <>
    <Flickity
      className={'slider md:no-slider md:grid md:gap-10 md:grid-cols-3'}
      static={true}
      options={flickityConfig}
    >
    {items.map((item, i) => (
      <ArticleItem key={i} {...item} publishedAt={item.publishedAt.date} style={i === big_index ? 'large' : 'short'} />
    ))}
    </Flickity>
  </>
};

TopArticles.propTypes = {
  items: PropTypes.array,
};

export default TopArticles;
