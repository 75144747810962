import {
  sendSignUp,
  sendSignIn,
  sendVerify,
  sendResetPassword,
  sendNewPassword,
  resendVerification,
  sendVerifyService
} from '../utils/api/post';
import TEXT_CONFIG from '../config/text-config';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CLEAR_DATA = 'AUTH_CLEAR_DATA';


export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const signUp = data => {
  return dispatch => {
    dispatch({
      type: AUTH_START,
    });
    return sendSignUp(data)
      .then(res => {
        dispatch({
          type: AUTH_SUCCESS,
          payload: { text: TEXT_CONFIG.signUpSuccess },
        });
      })
      .catch(err => {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };
};

export const verify = code => {
  return dispatch => {
    dispatch({
      type: AUTH_START,
    });
    return sendVerify(code)
      .then(res => {
        dispatch({
          type: AUTH_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };
};

export const verifyService = (service, code) => {
  return dispatch => {
    dispatch({
      type: AUTH_START,
    });
    return sendVerifyService({
      service,
      code,
    })
      .then(res => {
        dispatch({
          type: AUTH_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };
};

export const signIn = data => {
  return dispatch => {
    dispatch({
      type: AUTH_START,
    });
    return sendSignIn(data)
      .then(res => {
        dispatch({
          type: AUTH_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };
};

export const resetPassword = data => {
  return dispatch => {
    dispatch({
      type: AUTH_START,
    });
    return sendResetPassword(data)
      .then(res => {
        dispatch({
          type: AUTH_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };
};

export const userVerification = data => {
    return dispatch => {
        dispatch({
            type: AUTH_START,
        });
        return resendVerification(data)
            .then(res => {
                dispatch({
                    type: AUTH_SUCCESS,
                    payload: res,
                });
                return res;
            })
            .catch(err => {
                dispatch({
                    type: AUTH_ERROR,
                    payload: err,
                });
            });
    };
};

export const newPassword = data => {
  return dispatch => {
    dispatch({
      type: AUTH_START,
    });
    return sendNewPassword(data)
      .then(res => {
        dispatch({
          type: AUTH_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };
};

export const authClearData = () => {
  return dispatch => {
    dispatch({
      type: AUTH_CLEAR_DATA,
    });
  };
};
