import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {setOpened} from '../../actions/configAction';

import Header from '../Header';
import Footer from '../Footer';
import Feed from '../Feed';
import FeedToolbar from '../Feed/FeedToolbar';
import MobileFooterMenu from "../../atoms/MobileFooterMenu";

const generalContentPage = (WrappedComponent, pageType = 'general-content') => {
    class HOC extends Component {
        componentDidMount()
        {
            document.body.className = document.body.className.replace('no-scroll', '');
        }

        componentDidUpdate(prevProps, prevState, snapshot)
        {
            if (prevProps.config.opened !== this.props.config.opened) {
                const objRef = document.body;
                if (this.props.config.opened) {
                    objRef.classList.add('no-scroll');
                } else {
                    objRef.className = objRef.className.replace('no-scroll', '');
                }
            }
        }

        render()
        {
            return (
            <div
            className={classNames('main-body', `main-body--${pageType}`, 'general-content-pages', {
                [this.props.config.opened]: this.props.config.opened,
            })}
            >
              <Header />
                <WrappedComponent {...this.props} />
              <Footer />
            </div>
            );
        }
    }

    HOC.propTypes = {
        setOpened: PropTypes.func,
        config: PropTypes.object,
    };

    const mapStateToProps = store => ({
        config: store.config,
        currentUser: store.user.current,
    });

  const mapDispatchToProps = dispatch => ({
        setOpened: type => dispatch(setOpened(type)),
    });

  return withRouter(
      connect(
          mapStateToProps,
          mapDispatchToProps,
      )(HOC),
  );
};

export default generalContentPage;
