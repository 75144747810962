import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import _ from 'lodash';

const ErrorParser = props => {
  const {
    data: { error, message, validationErrors },
  } = props;
  let errors = [];
  if (validationErrors) {
    errors = [..._.flattenDeep(Object.values(validationErrors))];
  }
  if (message) {
    errors.push(message);
  } else if (error) {
    errors.push(error);
  }

  return (
    <div className="errors">
      {errors.map((item, index) => (
          <Fragment>
            <div key={index} className="errors__item">
                {item}
            </div>
            {item.includes('Unverified user') &&
              <div key="unverified" className="info__item">
                <p><Link to={"/resend-verification"}>Click here to resend your verification link</Link></p>
              </div>
            }
          </Fragment>
      ))}
    </div>
  );
};

ErrorParser.propTypes = {
  data: PropTypes.object,
};

export default ErrorParser;
