import React from 'react';
import NextArrow from '../../atoms/NextArrow';

function PageBlockBanner({title, icon_path, icon_color = 'dark', children}) {
  const brand_icon_colour = icon_color === 'dark' ? 'background-shapes-dark' : 'background-shapes yellow';
  return (
    <div className={brand_icon_colour + ' top-only'}>
      <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
        <div className="flex items-center mb-8">
          <div className="rounded-full bg-white h-[50px] aspect-square flex items-center justify-center mr-4">
            <img className="inline-block h-[35px]" src={icon_path} alt="All I can tell you"/>
          </div>
          <div className="uppercase text-14">
            {title}
          </div>
        </div>

        {children}

        <div className="bottom-[50px] right-[20px] lg:right-[50%] md:mr-[-60px] absolute">
            <NextArrow />
        </div>
      </div>
    </div>
  );
}

export default PageBlockBanner;