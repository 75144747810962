import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FollowedList from './FollowedList';

import { followedLoad } from '../../actions/followedAction';
import { notificationsLoad } from '../../actions/notificationsAction';

class Followed extends Component {
  componentDidMount() {
    this.props.followedLoad();
    this.props.notificationsLoad();
  }

  render() {
    const {
      config: { followedTab },
      followed,
      notifications,
    } = this.props;

    return (
      <div
        className={classNames('followed', {
          [`active-${followedTab}`]: true,
        })}
      >
        {followed.items.length > 0 && <FollowedList items={followed.items} type="followed" title="Followed" />}
        {notifications.items.length > 0 && (
          <FollowedList items={notifications.items} type="notifications" title="Notifications" />
        )}
      </div>
    );
  }
}

Followed.propTypes = {
  config: PropTypes.object,
  followed: PropTypes.object,
  notifications: PropTypes.object,
  followedLoad: PropTypes.func,
  notificationsLoad: PropTypes.func,
};

const mapStateToProps = store => ({
  config: store.config,
  followed: store.followed,
  notifications: store.notifications,
});

const mapDispatchToProps = dispatch => ({
  followedLoad: () => dispatch(followedLoad()),
  notificationsLoad: () => dispatch(notificationsLoad()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Followed);
