import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getArticleCount } from '../utils';
import { removeArticleCounter } from '../utils';

import Events from '../components/pages/Events';
import SingleArticle from '../components/pages/SingleArticle';
import NewArticle from '../components/pages/NewArticle';
import HelpfulLinks from '../components/pages/HelpfulLinks';
import SingleHelpfulLink from '../components/pages/HelpfulLinks/SingleHelpfulLink';

import Articles from '../components/pages/Articles';
import Lifestyle from '../components/pages/Lifestyle';
import Links from '../components/pages/Links';
import ContactNumbers from '../components/pages/ContactNumbers';
import Policy from '../components/pages/Policy';
import Terms from '../components/pages/Terms';
import Cookie from '../components/pages/Cookie';
import Rules from '../components/pages/Rules';

import SignIn from '../components/pages/Auth/SignIn';
import Verification from '../components/pages/Auth/Verification';
import ResetPassword from '../components/pages/Auth/ResetPassword';
import ResendVerification from '../components/pages/Auth/ResendVerification';
import NewPassword from '../components/pages/Auth/NewPassword';
import SignUp from '../components/pages/Auth/SignUp';


import NotFound from '../components/pages/NotFound';
import Welcome from "../components/pages/Welcome";
import AboutUs from "../components/pages/AboutUs";
import VerifyService from "../components/pages/Auth/VerifyService";
import ComingSoon from "../components/pages/ComingSoon";
import SingleEvent from "../components/pages/Events/SingleEvent";
import FutureMessages from "../components/pages/FutureMessages";
import Mastery from "../components/pages/Mastery";
import Coaching from "../components/pages/Coaching";
import Learning from "../components/pages/Learning";
import Contact from "../components/pages/Contact";
import Returns from "../components/pages/Returns";
import Shop from "../components/pages/Shop";
import Checkout from "../components/pages/Checkout";
import Basket from "../components/pages/Basket";
import Complete from "../components/pages/Checkout/Complete";
import Handbook from "../components/pages/Handbook";
import HandbookPackage from "../components/pages/HandbookPackage";
import AllICanTellYouPackage from '../components/pages/AllICanTellYouPackage';
import Product from "../components/pages/Product";

export const routes = [
  {
    path: '/',
    exact: true,
    component: Welcome,
    title: 'Welcome',
    pathType: 'opened',
  },
  {
    path: '/all-i-can-tell-you',
    exact: true,
    component: FutureMessages,
    title: 'All I can tell you',
    pathType: 'opened',
  },
  {
    path: '/all-i-can-tell-you/:package',
    exact: true,
    component: AllICanTellYouPackage,
    title: 'All I can tell you',
    pathType: 'opened',
  },
  {
    path: '/mastery',
    exact: true,
    component: Mastery,
    title: 'Mastery',
    pathType: 'opened',
  },
  {
    path: '/mastery/handbook/:package',
    exact: true,
    component: HandbookPackage,
    title: 'Mastery Handbook',
    pathType: 'opened',
  },
  {
    path: '/mastery/handbook',
    exact: true,
    component: Handbook,
    title: 'Mastery Handbook',
    pathType: 'opened',
  },
  {
    path: '/shop',
    exact: true,
    component: Shop,
    title: 'Shop',
    pathType: 'opened',
  },
  {
    path: '/shop/:slug',
    exact: true,
    component: Product,
    title: 'Product',
    pathType: 'opened',
  },
  {
    path: '/checkout',
    exact: true,
    component: Checkout,
    title: 'Checkout',
    pathType: 'opened',
  },
  {
    path: '/checkout/complete',
    exact: true,
    component: Complete,
    title: 'Checkout Complete',
    pathType: 'opened',
  },
  {
    path: '/basket',
    exact: true,
    component: Basket,
    title: 'Basket',
    pathType: 'opened',
  },
  {
    path: '/about-us',
    exact: true,
    component: AboutUs,
    title: 'About Us',
    pathType: 'opened',
  },
  {
    path: '/sign-up',
    exact: true,
    component: ComingSoon,
    // authPath: true,
    title: 'Sign Up',
    pathType: 'opened',
  },
  {
    path: '/login',
    exact: true,
    component: ComingSoon,
    // authPath: true,
    title: 'Sign In',
    pathType: 'opened',
  },
  // {
  //   path: '/signOut',
  //   exact: true,
  //   component: SignOut,
  //   authPath: true,
  //   title: 'Sign Out',
  // },
  {
    path: '/signUp/:service/callback',
    exact: true,
    component: VerifyService,
    authPath: true,
    title: 'Sign In',
  },
  {
    path: '/signUp/verification/:token',
    exact: true,
    component: Verification,
    authPath: true,
    title: 'Verification',
  },
  {
    path: '/resetPassword',
    exact: true,
    component: ResetPassword,
    authPath: true,
    title: 'Reset Password',
  },
  {
      path: '/resend-verification',
      exact: true,
      component: ResendVerification,
      authPath: true,
      title: 'Resend Verification',
  },
  {
    path: '/resetPassword/new/:token',
    exact: true,
    component: NewPassword,
    authPath: true,
    title: 'New Password',
  },
  {
    path: '/lifestyle/new',
    exact: true,
    component: NewArticle,
    title: 'New Article',
  },
  {
    path: '/lifestyle/:slug',
    exact: true,
    component: SingleArticle,
    title: 'Single Article',
    pathType: 'opened',
  },
  {
      path: '/lifestyle/guest/:slug',
      exact: true,
      component: SingleArticle,
      title: 'Single Article',
      pathType: 'opened',
  },
  // {
  //     path: '/toolkit',
  //     exact: true,
  //     component: HelpfulLinks,
  //     title: 'Helpful Links',
  //     pathType: 'opened',
  // },
  // {
  //     path: '/toolkit/:slug',
  //     exact: true,
  //     component: SingleHelpfulLink,
  //     title: 'Toolkit',
  //     pathType: 'opened',
  // },
  // {
  //     path: '/toolkit/:slug/:category',
  //     exact: true,
  //     component: SingleHelpfulLink,
  //     title: 'Toolkit',
  //     pathType: 'opened',
  // },
  {
    path: '/experiences/:slug',
    component: SingleEvent,
    title: 'Experiences',
    pathType: 'opened',
  },
  {
    path: '/experiences',
    component: Events,
    title: 'Experiences',
    pathType: 'opened',
  },
  {
    path: '/lifestyle/:slug/articles',
    component: Lifestyle,
    title: 'Articles',
    pathType: 'opened',
  },
  {
    path: '/lifestyle/:slug/links',
    component: Links,
    title: 'Links',
    pathType: 'opened',
  },
  {
    path: '/lifestyle/:slug/contacts',
    component: ContactNumbers,
    title: 'Contact Numbers',
  },
  {
    path: '/lifestyle',
    component: Lifestyle,
    title: 'Lifestyle',
    pathType: 'opened',
  },
  {
    path: '/contact',
    component: Contact,
    title: 'Contact',
    pathType: 'opened',
  },
  {
    path: '/returns',
    component: Returns,
    title: 'Returns',
    pathType: 'opened',
  },
  {
    path: '/privacy',
    exact: true,
    component: Policy,
    title: 'Privacy Policy',
    pathType: 'opened',
  },
  {
      path: '/policy',
      exact: true,
      component: Policy,
      title: 'Privacy Policy',
      pathType: 'opened',
  },
  {
      path: '/terms',
      exact: true,
      component: Terms,
      title: 'Terms of Use and Acceptable Use',
      pathType: 'opened',
  },
  {
      path: '/cookies',
      exact: true,
      component: Cookie,
      title: 'Cookie Policy',
      pathType: 'opened',
  },
  {
      path: '/rules',
      exact: true,
      component: Rules,
      title: 'Rules',
      pathType: 'opened',
  },

  {
    path: '**',
    component: NotFound,
    pathType: 'opened',
  },
];

const PrivateRoute = ({ component: Component, user, authPath, pathType, ...rest }) => {
  const render = props => {
    const { signed } = user;

    if (pathType === 'opened'){
        if (!getArticleCount()){
            localStorage.setItem('article_count', '0');
        }
        if (signed) removeArticleCounter();
        return <Component {...props} />;
    }

    if ((!signed && authPath)) {
      return <Component {...props} />;
    }

    if (signed && authPath) {
      removeArticleCounter();
      return <Redirect to="/stories" />;
    }

    return signed ? <Component {...props} /> : <Redirect to="/login" />;
  };

  return <Route {...rest} render={render} />;
};

const RouteConfig = props => {
  return (
    <Switch>
      {routes.map((route, i) => (
        <PrivateRoute
          key={i}
          exact={route.exact}
          path={route.path}
          component={route.component}
          title={route.title}
          user={props.user}
          {...route}
        />
      ))}
    </Switch>
  );
};

RouteConfig.propTypes = {
  user: PropTypes.object,
};

export default RouteConfig;
