import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';

import PropTypes from 'prop-types';

import PostThumb from './PostThumb';
import PostType from './PostType';
import Tags from './Tags';
import Ratings from '../components/shared/Ratings';

const OneArticle = ({ title, author, slug, groupId, tags, images, addedStyles, style = 'short' }) => {
  // const { id, title, author, type, shortDescription, images, tags, createdAt, publishedAt, reactions, mainImageOffsetY, slug, groupId, makeTransparent = false } = props;
  let articleUrl = author && author.isGuestAuthor ? `/lifestyle/guest/${slug}` : `/lifestyle/${slug}`;

  if (groupId) {
      articleUrl = `/group/${groupId}/article/${slug}`;
  }

  const getMainPic = picArr => (picArr && picArr.length ? picArr[0].thumbUrl : '/images/landing-welcome-bg-mobile.jpg');

  if (style === 'short') {
    return (
      <a href={articleUrl} className="w-[100%]">
        <div className="bg-dark-navy rounded-xl mb-4 overflow-hidden h-[327px] max-w-[398px]">
          {getMainPic && (
            <img className="w-full h-full object-cover" src={getMainPic(images)} alt={title} loading="lazy" />
          )}
        </div>
        <div class="max-w-[398px]">
          <h5 className="uppercase text-14 text-dark-grey mb-2">{tags.join(', ')}</h5>
          <h4 className="text-16">
            {title}
          </h4>
        </div>
      </a>
    );
  } else {
    return null;
  }
};

OneArticle.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  author: PropTypes.object,
  type: PropTypes.string,
  shortDescription: PropTypes.string,
  images: PropTypes.array,
  tags: PropTypes.array,
  mainImageOffetY: PropTypes.string,
  createdAt: PropTypes.string,
  publishedAt: PropTypes.string,
  reactions: PropTypes.object,
};

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OneArticle);
