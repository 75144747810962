import React from 'react';

import policyPage from '../../containers/policyPage';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import MetaTags from "../../../atoms/MetaTags";

const Returns = props => {
  return (
    <div className='policy-page wysiwyg'>
        <MetaTags title="Returns" pathname={props.location.pathname} />
      <div>
        <h1>Returns Policy</h1>
        <p>
            We want all of our customers to be happy with the products we sell. 
            In the unlikely (hopefully) event that you wish to return your good/s your statutory rights are below.
            However, if you want to discuss any aspect of the order or delivery, please do feel free to email us at <a href='mailto:help@grandudes.com'>help@grandudes.com</a> for a speedy resolution.
        </p>
        <h2>Right to Cancel</h2>
        <ul>
            <li>You have the right to cancel your order without giving any reason within 14 days from the day on which you or a third party indicated by you (other than the carrier) receives the goods purchased.</li>
            <li>To exercise your right to cancel, you must inform us of your decision in writing (by email to <a href='mailto:returns@grandudes.com'>returns@grandudes.com</a>).</li>
        </ul>
        <h2>Effects of Cancellation</h2>
        <ul>
            <li>If you cancel your order, we will reimburse all payments received from you, including the cost of delivery (except for supplementary costs if you chose a type of delivery other than the least expensive type of standard delivery offered by us).</li>
            <li>We will use the same means of payment as you used for the order, and you will not incur any fees for the reimbursement.</li>
            <li>We may withhold the reimbursement until we have received the goods back or you have supplied evidence of having sent back the goods.</li>
        </ul>
        <h2>Returning Goods</h2>
        <ul>
            <li>You should send back the goods to the address we provide without undue delay and in any event not later than 14 days from the day on which you communicate your cancellation from the contract to us.</li>
            <li>You will bear the direct cost of returning the goods.</li>
            <li>You are only liable for any diminished value of the goods resulting from the handling, other than what is necessary to establish the nature, characteristics, and functioning of the goods.</li>
        </ul>
        <h2>Exceptions to the Right to Cancel</h2>
        <p>The right to cancel does not apply to:</p>
        <ul>
            <li>the delivery of products which are not suitable for return due to health protection or hygienic reasons if unsealed by you after delivery, or which are, after delivery, inseparably mixed with other items;</li>
            <li>the supply of goods made to your specifications or clearly personalized;</li>
            <li>the supply of goods which may deteriorate or expire rapidly.</li>
        </ul>
        <h2>Faulty or Damaged Goods</h2>
        <p>If the goods you receive are faulty or damaged in transit, please contact us immediately.</p>
        <p>You will have the right to reject the goods within 30 days and receive a full refund or a replacement.</p>
        <h2>Contact Details</h2>
        <p>If you wish to exercise your right to cancel or have any queries regarding returns, please contact us at: <a href='mailto:returns@grandudes.com'>returns@grandudes.com</a>.</p>
      </div>
    </div>
  );
};

export default policyPage(Returns);