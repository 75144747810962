import React from "react";

function PageBlockIconAndText({bg_colour = 'yellow', icon_src, children}, props) {
    const { className } = props;
    console.log(className);
    return (
        <div className={className + ' bg-' + bg_colour}>
        <div className="container p-pad-sm mx-auto grid md:grid-cols-2 md:gap-32 gap-16 py-gap-sm md:py-gap-md lg:py-gap-lg items-center">
          <div className="flex justify-center order-2">
            <img src={icon_src} className="w-full max-w-[229px]"/>
          </div>
          <div className="text-dark-navy lg:text-22 lg-18 md:order-2 order-1">
            {children}
          </div>
        </div>
      </div>
    );
}

export default PageBlockIconAndText;