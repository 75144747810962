import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';

import ScrollToTop from './components/containers/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';
import {BasketProvider} from "./providers/BasketProvider";

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <BasketProvider>
            <Routes user={this.props.user} />
            <ToastContainer/>
          </BasketProvider>
        </ScrollToTop>
      </Router>
    );
  }
}

App.propTypes = {
  config: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = store => ({
  config: store.config,
  user: store.user,
});

export default connect(mapStateToProps)(App);
