import axios from 'axios';
import { apiUrl } from '../utils/constants';
import { getJWT } from '../utils';

const ServiceAPI = axios.create({
  baseURL: apiUrl,
  responseType: 'json',
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  },
);

if (getJWT()) {
  // eslint-disable-next-line
  ServiceAPI.defaults.headers.common['Authorization'] = `Bearer ${getJWT()}`;
}
ServiceAPI.defaults.headers.get['Content-Type'] = 'application/json';
ServiceAPI.defaults.headers.post['Content-Type'] = 'application/json';

export default ServiceAPI;
