const XMarkIcon = ({color = '#23323F', svgClassName = '', className = '', clickHandler = null}) => {
    return (
        <div className={className} onClick={clickHandler}>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={color} className={svgClassName}>
                <line x1="2.41421" y1="1" x2="16.0588" y2="14.6446" stroke-width="2" stroke-linecap="round"/>
                <line x1="1" y1="-1" x2="20.2964" y2="-1" transform="matrix(-0.707107 0.707107 0.707107 0.707107 17 1)" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
    )
}

export default XMarkIcon;