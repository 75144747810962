import React, {useContext, useEffect, useState} from 'react';
import Address from "./Address";
import Delivery from "./Delivery";
import {Elements} from "@stripe/react-stripe-js";
import Payment from "./Payment";
import PhoneInput from '../../Form/PhoneInput';
import EmailInput from '../../Form/EmailInput';

export default function Details({ checkout, handleInputChange, handleNextStep }) {

  const [validation, setValidation] = useState({
    phone: false,
    address: false,
    email: false
  })

  return <>
    <a
      className={`col-span-2 block w-full rounded-[10px] py-3.5 px-5 mb-5 bg-yellow text-dark-navy`}>Details</a>
    <form className="full grid grid-cols-2 mx-auto gap-y-6 gap-x-5 mb-8" onSubmit={handleNextStep}>
      {/*CONTACT DETAILS*/}
      <div className="lg:col-span-1 col-span-2">
        <input required placeholder="First Name*" type="text" name="fname" id="first name" value={checkout.fname}
               className="block w-full rounded-[10px] py-3.5 px-5 border-[#ffd23f] border" onChange={handleInputChange} />
      </div>
      <div className="lg:col-span-1 col-span-2">
        <input required placeholder="Last Name*" type="text" name="lname" id="last name" value={checkout.lname}
               className="block w-full rounded-[10px] py-3.5 px-5 border-[#ffd23f] border" onChange={handleInputChange} />
      </div>
      <div className="lg:col-span-1 col-span-2">
        <PhoneInput 
          value={checkout.phone} 
          handleInputChange={handleInputChange} 
          validationDetailsSetter={setValidation}
        />
      </div>
      <div className="lg:col-span-1 col-span-2">
        <EmailInput 
          input_name={'email'} 
          value={checkout.email} 
          confirm_email_value={checkout.email_confirmation} 
          handleInputChange={handleInputChange} 
          confirm={true} 
          validationDetailsSetter={setValidation}
        />
      </div>
      {/*BILLING ADDRESS*/}
      <span className="col-span-2 font-bold">Billing Address*</span>
      <Address 
        prefix="billing_" 
        checkout={checkout} 
        handleInputChange={handleInputChange} 
        validationDetailsSetter={setValidation}
      />
      <div className="col-span-2 flex justify-end">
        <input
          className={
            "hover:cursor-pointer bg-yellow text-dark-navy py-2.5 px-5 rounded-[8px] lg:w-auto w-full" + 
            ( (validation.address && validation.email && validation.phone) === false ? ' opacity-50' : '')
          }
          type="submit" 
          value="Continue" 
          disabled={(validation.address && validation.email && validation.phone) === false}
          />
      </div>
    </form>
    <span
      className={`col-span-2 block w-full rounded-[10px] py-3.5 px-5 mb-5 text-[#91989F] font-bold bg-[#F5F5F5]`}>Delivery</span>
    <span
      className={`col-span-2 block w-full rounded-[10px] py-3.5 px-5 mb-5 text-[#91989F] font-bold bg-[#F5F5F5]`}>Payment</span>
  </>;
}
