import React, {useState, useEffect, Component, Fragment, useRef} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import {calculateDistanceInKm, formatDate} from "../../../utils/helpers";

import fullWidthPage from '../../containers/fullWidthPage';
import {getEvent, getEvents, relatedEvents} from "../../../utils/api";
import LocationBar from "../../../atoms/LocationBar";

import infoCircle from "../../../Icon/assets/info-circle-solid.svg";
import MetaTags from "../../../atoms/MetaTags";
import BlockExperiencesSlider from "../../../atoms/BlockExperiencesSlider";
import Button from "../../../atoms/Button";
import generalContentPage from "../../containers/generalContentPage";
import flickityConfig from "../../../config/flickity-config";
import CardExperience from "../../../atoms/CardExperience";
import Flickity from "react-flickity-component";
import LoadingBlock from "../../../atoms/LoadingBlock";

function SvgStar({fill = true}) {
  if(fill) {
    return (
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
        <path
          d="M10 0L12.2451 6.56434H19.5106L13.6327 10.6213L15.8779 17.1857L10 13.1287L4.12215 17.1857L6.36729 10.6213L0.489435 6.56434H7.75486L10 0Z"
          fill="#23323F"/>
      </svg>
    );
  }
  return (
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99982 0V13.1287L4.12197 17.1857L6.36711 10.6213L0.489258 6.56434H7.75468L9.99982 0Z" fill="#23323F"/>
    </svg>

  );
}

function Stars({number}) {
  return Array(Math.floor(number)).fill(0).map((_, i) => <SvgStar key={i} fill={number > i} />);
}

function ExperienceReview({name, rating, title, body, createdAt}) {
  return (
    <div className="max-w-xs text-dark-navy">
      <div className="flex items-center mb-6">
        <Stars number={rating} />
      </div>
      <h6 className="text-30 font-bold font-heading mb-4">{title}</h6>
      <p>{body}</p>
      <div className="flex items-center justify-between mt-10">
        <p>{name}</p>
        <p>{formatDate(createdAt, 'Do MMMM YYYY')}</p>
      </div>
    </div>
  );
}
function ExperienceReviews({ reviews }) {
  return (
    <div className="bg-yellow">
      <div className="container mx-auto px-pad-sm py-[80px] xl:py-[120px] xl:no-slider slider no-slider-nav xl:grid grid-cols-3 gap-20 text-dark-navy">
        {reviews.map(review => (
          <div className="xl:mr-0 mr-12">
            <ExperienceReview {...review} />
          </div>
        ))}
      </div>
    </div>
  );
}

const SingleEvent = ({location, match}) => {
  const [ experience, setExperience ] = useState({});
  const [ selectedTab, setSelectedTab ] = useState(0);

  const [loading, setLoading] = useState(true);

  const moreInfoRef = useRef(null)

  const scrollToMoreInfo = (ev) => {
    ev.preventDefault();
    moreInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const handleSelectTab = (i, ev) => {
    ev.preventDefault();
    setSelectedTab(i);
  }

  useEffect(() => {
    const { slug } = match.params;

    getEvent(slug).then(data => {
      setExperience(data);
      setLoading(false);
    });
  }, []);

  const tabs = [...Array(5).keys()].map(i => ({
    title: experience?.[`tab_${i + 1}_title`],
    content: experience?.[`tab_${i + 1}_content`],
    has2Cols: !!experience?.[`tab_${i + 1}_2cols`],
  })).filter(tab => tab.title && tab.content);

  return (
    <div className="overflow-hidden">
      <MetaTags title="Event" pathname={location.pathname} />

      <div className="background-shapes">
        <div className="overflow-hidden">
          <div className="container px-pad-sm mx-auto py-[80px] xl:py-[120px]">
            {loading && <LoadingBlock colour="black" size={10} text="Loading" classNames="align-center" />}
            {!loading && (
              <>
                <div className="flex items-center">
                  <div className="uppercase text-14">
                    Experiences
                  </div>
                </div>
                <h2 className="font-heading font-bold text-30 lg:text-50 pt-3 pb-6 xl:pb-4 xl:pb-8 max-w-[650px]">
                  {experience.name}
                </h2>
                {experience.intro && (
                  <div className="text-20 max-w-[650px] md:mb-24 mb-12">
                    <div className="wysiwyg" dangerouslySetInnerHTML={{__html: experience.intro }}></div>
                  </div>
                )}
                <div className="flex justify-between items-stretch max-w-[800px] flex-col md:flex-row">
                  {experience.price && (
                    <div className="md:mb-0 mb-8">
                      <h4 className="text-40 font-bold font-heading mr-6">Starting from: {experience.price}</h4>
                      <p>{experience.priceUnit}</p>
                    </div>
                  )}
                  <div className="flex flex-1 items-start justify-between max-w-[300px]">
                    {experience.included && (
                      <Button href="#" onClick={scrollToMoreInfo}>
                        More info
                        <div className="inline-block ml-2 text-white fill-white h-full">
                          <svg height="14px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                        </div>
                      </Button>
                    )}
                    {experience.url && (
                      <a target="_blank" href={experience.url} className="bg-yellow text-dark-navy cursor-pointer rounded-md py-2 px-4 inline-block">
                        Book now
                      </a>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {!loading && (
          <>
            {experience.bannerImage && (
              <img src={experience.bannerImage['originalUrl']} className="mx-auto pb-[80px] xl:pb-[120px] w-full object-cover max-w-[1900px]"/>
            )}
            <div className="container mx-auto px-pad-sm pb-gap-sm md:pb-gap-md lg:pb-gap-lg grid lg:grid-cols-2 lg:gap-60 md:gap-y-20 gap-y-10">
              {experience.secondaryImage && (
                <img src={experience.secondaryImage['originalUrl']} className="rounded w-full"/>
              )}
              {experience.included && (
                <div>
                  <h2 ref={moreInfoRef} className="text-30 lg:text-50 font-bold font-heading text-yellow">What's Included</h2>
                  <div className="wysiwyg" dangerouslySetInnerHTML={{__html: experience.included }}></div>
                </div>
              )}
            </div>
            {tabs.length > 0 && <div className="pb-gap-sm md:pb-gap-md lg:pb-gap-lg">
              <div className="container mx-auto text-dark-navy">
                <div className="flex flex-row space-x-3 px-pad-sm overflow-x-auto">
                  {tabs.map((tab, i) => (
                    <a key={i} className={`block rounded-t-lg bg-light-grey cursor-pointer whitespace-nowrap py-3 px-5 ${selectedTab === i ? '' : 'opacity-30'}`} onClick={handleSelectTab.bind(null, i)}>
                      {tab.title}
                    </a>
                  ))}
                </div>
              </div>
              <div className="bg-light-grey text-dark-navy">
                <div className="container mx-auto py-24">
                  <div className={`wysiwyg px-pad-sm ${tabs[selectedTab]?.has2Cols ? 'md:column-count-2' : ' '}`} dangerouslySetInnerHTML={{__html: tabs[selectedTab]?.content }}></div>
                </div>
              </div>
            </div>}
            <div className="container mx-auto px-pad-sm pb-[80px] xl:pb-[120px] grid lg:grid-cols-2 gap-y-20 lg:gap-40">
              <div className="items-center lg:row-start-auto row-start-1 lg:gap-60 max-w-[85%]">
                <div className="gap-0">
                  <div className="wysiwyg" dangerouslySetInnerHTML={{__html: experience.description }}></div>
                  {experience.url && (
                    <div className="lg:block hidden mt-4">
                      <a href={experience.url} target="_blank" className="bg-yellow text-dark-navy cursor-pointer rounded-md py-2 px-4 inline-block">
                        Book now
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="overflow-hidden yellow-slider">
                {flickityConfig.prevNextButtons = true}
                {flickityConfig.adaptiveHeight = true}
                {experience.images.length > 1 && (
                  <Flickity
                    className={'slider slider-with-btns xl:w-[50%] sm:w-[65%] w-[85%] yellow-flickity yellow-with-buttons'}
                    options={flickityConfig}
                  >
                    {experience.images.map(image => {
                      return (
                        <div className="min-w-full flex justify-start">
                          <img key={image.id} src={image.thumbUrl} className="rounded"/>
                        </div>
                      );
                    })}
                  </Flickity>
                )}
                {experience.images.length <= 1 && (
                  <div className="min-w-full flex justify-start">
                    <img
                      src={experience.images[0].thumbUrl}
                      className="rounded"
                      alt={experience.images[0].alt}
                    />
                  </div>
                )}
              </div>
              <div className="lg:hidden block">
                <a href={experience.url} target="_blank" className="mt-4 cursor-pointer rounded-md py-2 px-4 inline-block bg-yellow text-dark-navy">
                  Book now
                </a>
              </div>
            </div>
          </>
        )}
      </div>
      {experience.relatedEvents && experience.relatedEvents.length > 0 && (
        <BlockExperiencesSlider loading={loading} viewMore={false} title="You might also like" items={experience.relatedEvents} />
      )}
      {experience.reviews && experience.reviews.length > 0 && (
        <ExperienceReviews reviews={experience.reviews} />
      )}
    </div>
  );
}

export default generalContentPage(SingleEvent);
