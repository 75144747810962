import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import HeartIcon from '../Icon/assets/icon-heart.svg';

const Avatar = props => {
  const { src, className, fullName } = props;
  const getInitials = fullN => {
    const initials =
      fullN &&
      fullN
        .split(' ')
        .map(item => item.charAt(0))
        .join('')
        .slice(0, 2);

    const result = fullN ? initials : '';
    return result;
  };
  return (
    <Fragment>
      {src ? (
           <img {...{ src, className }} alt={fullName} />
      ) : (
        fullName === 'All I Can Tell You' ?
              <img src={HeartIcon} style={{ padding: 2 }} className={className} alt="All I Can Tell You" />
            :
            <div className="avatar">
              <div className="avatar__text">{getInitials(fullName)}</div>
            </div>
      )}
    </Fragment>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  fullName: PropTypes.string,
};

export default Avatar;
