import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';

const Footer = props => {
  return (
    <div className="bg-dark-navy text-white border-t border-solid border-[#9FA6AA] border-opacity-20">
      <div className="container px-pad-sm mx-auto">
        <div className="md:flex pt-14">
          <div className="pb-14">
            <Link to="/">
              <img src="/images/grandudes-logo-small.svg?1" alt="Grandudes" />
            </Link>
          </div>
          <div className="flex-1 md:ml-[20%]">
            <div className="flex justify-between pb-14 md:w-[300px]">
              <div className="w-1/2">
                <Link to={'/lifestyle'} className="block mb-1">Lifestyle</Link>
                <Link to={'/experiences'} className="block mb-1">Experiences</Link>
                <Link to={'/mastery'} className="block mb-1">Mastery</Link>
                <Link to={'/about-us'} className="block mb-1">About Us</Link>
                <Link to={'/returns'} className="block mb-1">Returns Policy</Link>
              </div>
              <div className="w-1/2">
                <Link to={'/terms'} className="block mb-1">Terms</Link>
                <Link to={'/privacy'} className="block mb-1">Privacy Policy</Link>
                <Link to={'/cookies'} className="block mb-1">Cookies</Link>
                <Link to={'/contact'} className="block mb-1">Contact</Link>
              </div>
            </div>
            <div className="text-white opacity-70 text-14 pb-14">
              © 2022 grandudes
            </div>
          </div>
          <div className="pb-14">
            <a href="https://twitter.com/grandudes2" className="inline-block" target="_blank"><img src="/images/twitter-yellow.svg" alt="Grandudes on Twitter" /></a>
            <a href="https://www.instagram.com/grandudes1/" className="inline-block ml-5" target="_blank"><img src="/images/instagram-yellow.svg" alt="Grandudes on Instagram" /></a>
            <a href="https://www.facebook.com/profile.php?id=100086698687994" className="inline-block ml-5" target="_blank"><img src="/images/facebook-yellow.svg" alt="Grandudes on Facebook" /></a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Footer));
