// IE11 stuff
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/includes';
import 'core-js/fn/string/includes';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/object/values';
import 'core-js/fn/object/keys';

// SSR stuff
import 'match-media';

if (!window.localStorage) {
  require('localstorage-polyfill');
}
