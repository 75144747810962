import React, { Component } from 'react';
import classNames from 'classnames';

class Input extends Component {
  state = {
    show: false,
  };

  toggleShow = () => {
    this.setState((state, props) => {
      return { show: !state.show };
    });
  };

  render() {
    const {
      title,
      input,
      placeholder,
      type,
      disabled,
      meta: { touched, error, warning, valid },
    } = this.props;
    const isPassword = type === 'password';
    const { show } = this.state;
    const passwordShowBtn = (
      <button type="button" className="form__hide-btn" onClick={this.toggleShow}>
        {show ? 'Hide' : 'Show'}
      </button>
    );

    const classes = classNames('form__field', {
      'form__field--error': touched && error,
      'form__field--warning': touched && warning,
      'form__field--valid': touched && valid,
    });

    return (
      <label className={classes}>
        {title && <span className="form__label-name">{title}</span>}
        <span className="form__label-input">
          {isPassword ? (
            <input
              {...input}
              placeholder={placeholder}
              type={show ? 'text' : 'password'}
              disabled={disabled}
              className="unstyled"
            />
          ) : (
            <input {...input} placeholder={placeholder} type={type} disabled={disabled} className="unstyled" />
          )}

          {isPassword && passwordShowBtn}
          {touched &&
            ((error && <span className="error">{error}</span>) ||
              (warning && <span className="error">{warning}</span>))}
        </span>
      </label>
    );
  }
}

export default Input;
