import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { classnames, getUserLocation } from '../utils/helpers';

class LocationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            errorMessage: '',
            latitude: null,
            longitude: null,
            isGeocoding: false,
            isGettingUserLocation: false,
        };
    }

    handleClick = event => {
        this.setState({ isInput: true })
    };

    handleChange = address => {
        this.setState({
            address,
            latitude: null,
            longitude: null,
            errorMessage: '',
        });
    };

    handlePopularSelect = (lat, lng, address) => {
        this.setState({
            latitude: lat,
            longitude: lng,
            isGeocoding: false,
            isInput: false,
            address
        });
        this.props.setSearchLocation({
            latitude: lat,
            longitude: lng,
        });
    };

    handleSelect = selected => {
        this.setState({ isGeocoding: true, address: selected });
        geocodeByAddress(selected)
            .then(res => getLatLng(res[0]))
            .then(({ lat, lng }) => {
                this.setState({
                    latitude: lat,
                    longitude: lng,
                    isGeocoding: false,
                });
                this.props.setSearchLocation({
                    latitude: lat,
                    longitude: lng,
                });
            })
            .catch(error => {
                this.setState({ isGeocoding: false });
                console.log('error', error); // eslint-disable-line no-console
            });
    };

    handleCloseClick = () => {
        this.setState({
            address: '',
            latitude: null,
            longitude: null,
            isInput: false,
        });
    };

    handleError = (status, clearSuggestions) => {
        console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };

    handleUserLocation = () => {
        this.setState({ isGeocoding: true, isGettingUserLocation: true });
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
                console.log(pos.coords.latitude, pos.coords.longitude,);
                this.setState({
                    address: 'Current location',
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude,
                    isInput: false,
                    isGeocoding: false,
                    isGettingUserLocation: false,
                });
                this.props.setSearchLocation({
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude
                });
            });
        } else {
            this.setState({
                isGettingUserLocation: false,
            })
        }
    };

    render() {
        const {
            address,
            errorMessage,
            latitude,
            longitude,
            isGeocoding,
            isInput,
            isGettingUserLocation
        } = this.state;

        return (
            <div>
                <PlacesAutocomplete
                    onChange={this.handleChange}
                    value={address}
                    onSelect={this.handleSelect}
                    onError={this.handleError}
                    shouldFetchSuggestions={address.length > 2}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                        return (
                            <div className="Location__search-bar-container">
                                <div className="Location__search-input-container">
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places...',
                                            className: 'Location__search-input',
                                        })}
                                        onClick={this.handleClick}
                                    />
                                    {this.state.address.length > 0 || isInput && (
                                        <button
                                            className="Location__clear-button"
                                            onClick={this.handleCloseClick}
                                        >
                                            x
                                        </button>
                                    )}
                                </div>
                                {
                                    isInput && address.length < 3 &&
                                        <div className="Location__autocomplete-container">
                                            <div className="Location__suggestion-item location" onClick={this.handleUserLocation}>
                                                <i className={`fas ${isGettingUserLocation ? 'fa-spinner fa-spin' : 'fa-crosshairs'}`}></i>{" "}Use my current location
                                            </div>
                                            <div className="Location__suggestion-item title">
                                                Popular
                                            </div>
                                            <div className="Location__suggestion-item" onClick={() => this.handlePopularSelect(53.4807593, -2.2426305000000184, 'Manchester, UK')}>
                                                Manchester<br />
                                                <small>UK</small>
                                            </div>
                                            <div className="Location__suggestion-item" onClick={() => this.handlePopularSelect(53.8007554, -1.549077399999987, 'Leeds, UK')}>
                                                Leeds<br />
                                                <small>UK</small>
                                            </div>
                                        </div>
                                }
                                {suggestions.length > 0 && (
                                    <div className="Location__autocomplete-container">
                                        {suggestions.map(suggestion => {
                                            const className = classnames('Location__suggestion-item', {
                                                'Location__suggestion-item--active': suggestion.active,
                                            });

                                            return (
                                                /* eslint-disable react/jsx-key */
                                                <div
                                                    {...getSuggestionItemProps(suggestion, { className })}
                                                >
                                                    {suggestion.formattedSuggestion.mainText}{' '}<br />
                                                    <small>
                                                        {suggestion.formattedSuggestion.secondaryText}
                                                    </small>
                                                </div>
                                            );
                                            /* eslint-enable react/jsx-key */
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </PlacesAutocomplete>
                {/*{errorMessage.length > 0 && (*/}
                    {/*<div className="Location__error-message">{this.state.errorMessage}</div>*/}
                {/*)}*/}

                {/*{((latitude && longitude) || isGeocoding) && (*/}
                    {/*<div>*/}
                        {/*<h3 className="Location__geocode-result-header">Geocode result</h3>*/}
                        {/*{isGeocoding ? (*/}
                            {/*<div>*/}
                                {/*<i className="fa fa-spinner fa-pulse fa-3x fa-fw Location__spinner" />*/}
                            {/*</div>*/}
                        {/*) : (*/}
                            {/*<div>*/}
                                {/*<div className="Location__geocode-result-item--lat">*/}
                                    {/*<label>Latitude:</label>*/}
                                    {/*<span>{latitude}</span>*/}
                                {/*</div>*/}
                                {/*<div className="Location__geocode-result-item--lng">*/}
                                    {/*<label>Longitude:</label>*/}
                                    {/*<span>{longitude}</span>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*)}*/}
                    {/*</div>*/}
                {/*)}*/}
            </div>
        );
    }
}

export default LocationBar;
