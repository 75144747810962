function ImageWithSmallBackgroundShape({image_url, image_classes, shape_position, shape_classes}) {
    let base_shape_classes = 'absolute lg:h-80 sm:h-56 h-48 -z-10 lg:-bottom-56 sm:-bottom-36 -bottom-24';
    if(shape_position === 'right') {
      base_shape_classes += ' -scale-x-100 -right-[80px] right-12';
    } else {
      base_shape_classes += ' lg:-left-[80px] left-12';
    }
    return (
      <div className="relative">
        <img src={image_url} alt="Photo"  className={image_classes}/>
        <img src="/images/yellow-background-shape.svg" className={base_shape_classes + ' ' + shape_classes}/>
      </div>
    );
  }

  export default ImageWithSmallBackgroundShape;