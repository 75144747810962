import {
  USER_START_LOADER,
  AUTH_USER_SUCCESS,
  USER_REQUEST_ERROR,
  USER_UPDATE_SUCCESS,
  AVATAR_UPDATE_SUCCESS,
  PHOTOS_UPDATE_SUCCESS,
  PHOTOS_DELETE_SUCCESS,
} from '../actions/userAction';

const init = {
  isLoading: false,
  isError: null,
  signed: false,
  current: null,
};

function user(state = init, action) {
  switch (action.type) {
    case USER_START_LOADER:
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case AUTH_USER_SUCCESS:
      return {
        ...state,
        current: action.payload,
        isLoading: false,
        signed: true,
      };
    case USER_REQUEST_ERROR:
      return {
        ...state,
        isError: action.payload,
        isLoading: false,
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        current: action.payload,
        isLoading: false,
      };
    case AVATAR_UPDATE_SUCCESS:
      return {
        ...state,
        current: { ...state.current, avatar: action.payload },
        isLoading: false,
      };
    case PHOTOS_UPDATE_SUCCESS:
      return {
        ...state,
        current: { ...state.current, photos: [...state.current.photos, ...action.payload] },
        isLoading: false,
      };
    case PHOTOS_DELETE_SUCCESS:
      return {
        ...state,
        current: { ...state.current, photos: state.current.photos.filter(item => item.id !== action.payload.deleted) },
        isLoading: false,
      };

    default:
      return state;
  }
}

export default user;
