import React, {useContext, useEffect, useRef, useState} from 'react';
import InputError from '../../Form/InputError';
import PostcodeInput from '../../Form/PostcodeInput';
import { SetupOptions, find } from 'getaddress-find'
import countries from '../../../config/countries';

export default function Address({ prefix, checkout, handleInputChange, validationDetailsSetter = null }) {

  // postcode
  const [postcodeValid, setPostcodeValidation] = useState(false)
  // getAddress-find data
  const [addressData, setAddressData] = useState({
    line_0: '',
    line_1: checkout[`${prefix}address1`] || "",
    line_2: checkout[`${prefix}address1`] || "",
    line_3: '',
    line_4: '',
    town_or_city: checkout[`${prefix}town`] || "",
    county: '',
    country: checkout[`${prefix}country`] || "",
    postcode: checkout[`${prefix}postcode`] || null,
    building_name: '',
    building_number: '',
  })
  // to build line_2 value of adressData.line_2
  const [line_2, setLine2] = useState({
    building_name: '',
    building_number: '',
    line_2: '',
    line_3: '',
    line_4: ''
  })

  useEffect(() => {
    initAddressLookup()
    // document.addEventListener("getaddress-find-suggestions", function(e){
    //   console.log(e.suggestions);
    // })
    // document.addEventListener("getaddress-find-suggestions-failed", function(e){
    // })
    const addressSelected = (e) => {
      e.address.country = getCountryAbbreiviation(e.address.country)
      setAddressData(e.address)
      setLine2({
        building_name: e.address.building_name || '',
        building_number: e.address.building_number || '',
        line_2: e.address.line_2 || '',
        line_3: e.address.line_3 || '',
        line_4: e.address.line_4 || ''
      })
    }
    document.addEventListener("getaddress-find-address-selected", addressSelected)
    return () => {
      document.removeEventListener("getaddress-find-address-selected", addressSelected)
    };
  },[])

  useEffect(() => {
    if(addressData) {
      checkout[`${prefix}address1`] = addressData.line_1
    }
  }, [addressData.line_1])

  useEffect(() => {
    if(addressData) {
      checkout[`${prefix}address2`] = addressData.line_2
    }
  }, [addressData.line_2])

  useEffect(() => {
    if(addressData) {
      checkout[`${prefix}town`] = addressData.town_or_city
    }
  }, [addressData.town_or_city])

  useEffect(() => {
    if(addressData) {
      checkout[`${prefix}country`] = addressData.country
    }
  }, [addressData.country])

  useEffect(() => {
      if(addressData) {
        checkout[`${prefix}postcode`] = addressData.postcode
        let input = document.getElementById('postcode')
        if(input) {
          validatePostcode({target: input})
        }
      }
  }, [addressData.postcode])

  useEffect(() => {
    const line_2_array = Object.values(line_2).filter((value) => {
      return value.length > 0
    })
    if(line_2_array.length > 0) {
      // this will then start of useEffect for addressData which will bubble up with event
      setAddressData(prev => ({
        ...prev,
        line_2: line_2_array.join(', ')
      }))
    }
  }, [line_2])

  const handleAddressInputChange = (event) => {
    const { name, value, id } = event.target;
    // Identify the input field and update the corresponding property in addressData
    if (id === "formatted_address_0") {
      setAddressData((prevData) => ({
        ...prevData,
        line_1: value
      }));
    } else if (id === "formatted_address_1") {
      setAddressData((prevData) => ({
        ...prevData,
        line_2: value
      }));
    } else if (id === "town_or_city") {
      setAddressData((prevData) => ({
        ...prevData,
        town_or_city: value
      }));
    } else if (id === "country") {
      setAddressData((prevData) => ({
        ...prevData,
        country: value
      }));
    } else if (id === "postcode") {
      setAddressData((prevData) => ({
        ...prevData,
        postcode: value
      }));
      validatePostcode(event)
    }
    handleInputChange(event)
  };

  const getCountryAbbreiviation = (string) => {
    if(['England', 'Scotland', 'Wales', 'Northern Ireland'].includes(string)) {
      string = 'United Kingdom'
    }
    string = string.toLowerCase()
    let found_country = countries.find((obj) => obj.name.toLowerCase() == string);
    if(found_country) {
      return found_country.code
    }
    return null;
  }

  const preventPostcodeLookup = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  const setupAddressOptions = () => {
    const options = SetupOptions;
      options.input = {
        id: `postcode_lookup_input`,
        label:'Enter your Postcode to find your address',
        class: 'block w-full rounded-[10px] py-3.5 px-5 border text-gray-900 placeholder:text-gray-400 border-[#ffd23f] col-span-3'
      }
      options.button = {
        class: 'hover:cursor-pointer bg-yellow text-dark-navy py-2.5 px-5 rounded-[8px] lg:w-auto w-full col-span-1',
        id: 'postcode_lookup_button'
      }
      options.dropdown = {
        class: 'hover:cursor-pointer border border-[#ffd23f] w-full mr-6 py-3.5 px-5 rounded-[8px] col-span-4'
      }
      options.error_message = {
        class: 'text-[#dc2626] text-12',  /* The class of the error message' */
      }
      return options;
  }

  const initAddressLookup = () => {
    const options = setupAddressOptions();
    try {
      const lookup_element = find(
        'postcode_lookup',
        process.env.REACT_APP_GET_ADDRESS_KEY,
        options
      )
      const postcode_lookup = lookup_element.querySelector('#postcode_lookup_input')
      // this checks over every keystroke on postcode lookup
      postcode_lookup.addEventListener('input', (event) => {
        let valid = isPostcodeValid(event.target.value)
        if(!valid) {
          event.target.addEventListener("keydown", preventPostcodeLookup);
          document.getElementById('postcode_lookup_button').disabled = true;
        } else {
          event.target.removeEventListener("keydown", preventPostcodeLookup);
          document.getElementById('postcode_lookup_button').disabled = false;
        }
      });
    } catch (error) {
      console.error('Error setting up postcode lookup:', error);
    }
  };

  const isPostcodeValid = (postcode) => {
    const postcode_regex = '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$'
    return postcode.match(postcode_regex) !== null
  }

  const validatePostcode = (event) => {
    const valid = isPostcodeValid(event.target.value)
    if(validationDetailsSetter) {
      validationDetailsSetter((prev) => ({
        ...prev,
        address: valid      
      }))
    }
    setPostcodeValidation(valid)
  }

  return <>
    <div className='col-span-2 grid grid-cols-4 gap-4' id='postcode_lookup'></div>
    <div className="lg:col-span-1 col-span-2">
      <input required value={addressData.line_1} placeholder="Address line 1*" type="text" name={`${prefix}address1`} id="formatted_address_0"
             className="block w-full rounded-[10px] py-3.5 px-5 border-[#ffd23f] border text-gray-900 placeholder:text-gray-400" 
             onChange={handleAddressInputChange} 
             />
    </div>
    <div className="lg:col-span-1 col-span-2">
      <input required placeholder="Country" type="text" name={checkout[`${prefix}country`]} onChange={handleAddressInputChange} value={addressData.country
      } id="country"
             className="block w-full rounded-[10px] py-3.5 px-5 border-[#ffd23f] border text-gray-900 placeholder:text-gray-400" />
    </div>
    <div className="lg:col-span-1 col-span-2">
      <input required placeholder="Address line 2*" type="text" name={`${prefix}address2`} onChange={handleAddressInputChange} value={addressData.line_2} id="formatted_address_1"
             className="block w-full rounded-[10px] py-3.5 px-5 border-[#ffd23f] border text-gray-900 placeholder:text-gray-400" />
    </div>
    <div className="lg:col-span-1 col-span-2">
      <input required placeholder="Town/City*" type="text" name={`${prefix}town`} onChange={handleAddressInputChange} value={addressData.town_or_city} id="town_or_city"
             className="block w-full rounded-[10px] py-3.5 px-5 border-[#ffd23f] border text-gray-900 placeholder:text-gray-400" />
    </div>
    <div className="lg:col-span-1 col-span-2">
      <div>
          <input required
                  placeholder="Postcode*" 
                  type="text" 
                  id={"postcode"}
                  name={`${prefix}postcode`}
                  onChange={handleAddressInputChange}
                  value={addressData.postcode}
                  className={
                      "block w-full rounded-[10px] py-3.5 px-5 border text-gray-900 placeholder:text-gray-400 " +
                      ( !postcodeValid && addressData.postcode !== null ? 'border-[#dc2626]' : 'border-[#ffd23f]' )
                  }
          />
          { (!postcodeValid && addressData.postcode !== null) && (
              <InputError message='Invalid Postcode entered' />
          )}
      </div>
    </div>
  </>
}
