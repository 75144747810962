import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import Icon from "../../Icon";

const categories = [
  {
    name: 'Leisure',
    url: 'leisure',
    icon: 'ico-positivity',
  },
  {
    name: 'Travel',
    url: 'travel',
    icon: 'ico-challenges',
  },
  {
    name: 'Health',
    url: 'health',
    icon: 'ico-relationship',
  },
  {
    name: 'Purpose',
    url: 'purpose',
    icon: 'ico-ageing',
  },
  {
    name: 'Relationships',
    url: 'relationships',
    icon: 'ico-happiness',
  }
];

const FeedCategories = props => {
  const [open, setOpen] = useState('');

  const { pathname } = props.location;
  const { history, setOpened } = props;

  const toggleOpen = name => {
    setOpen(name === open ? '' : name);
  };

  return (
    <Fragment>
      {categories.map(item => {
        const { name, url, icon } = item;
        return (
          <div key={url} className="feed-one-item">
            <button
              className={classNames('feed-list__link', {
                'feed-list__link--active': open === url || pathname.includes(`/lifestyle/${url}`),
              })}
              onClick={() => {
                  history.push(`/lifestyle/${url}/articles`);
                  setOpened(null);
              }}
              type="button"
            >
              <Icon name={icon} />
              <span className="feed-list__text">{name}</span>
            </button>
          </div>
        );
      })}
    </Fragment>
  );
};

FeedCategories.propTypes = {};

export default FeedCategories;
