import React, { useState, useRef } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity-as-nav-for';

function ProductCarousel(props) {
  const { images } = props;
  const [mainCarousel, setMainCarousel] = useState(null);

  const flickityOptionsMain = {
    contain: true,
    pageDots: true,
    draggable: false,
  };

  if (images.length === 1) {
    return (
      <img
        src={images.length ? images[0]['originalUrl'] : undefined}
        className="w-full mx-auto rounded-lg overflow-hidden"
        loading="lazy"
      />
    );
  }

  return (
    <div className="product-carousel">
      <div className="xs:grid grid-cols-12 gap-4">
        {/* NAVIGATION OF IMAGES */}
        <div className="col-span-2 z-10 xs:block hidden">
          <div className="flex w-full flex-col">
            {images.map((image, index) => (
              <div
                key={image.id}
                className="mb-2 carousel-cell rounded-lg overflow-hidden lg:h-[70px] lg:w-[70px] h-[60px] w-[60px] hover:cursor-pointer"
                onClick={() => mainCarousel.select(index, false, true)}
              >
                <img
                  src={image.originalUrl}
                  alt="Nav"
                  className="inset-0 w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>
        {/* MAIN CAROUSEL */}
        <div className="col-span-10 md:z-0">
          <Flickity
            className="carousel carousel-main focus-visible:ring-transparent focus-visible:outline-0"
            options={flickityOptionsMain}
            flickityRef={setMainCarousel}
          >
            {images.map(image => (
              <div className="carousel-cell w-full sm:h-[500px] md:h-[400px] lg:h-[475px] xl:h-[550px] h-[400px] relative">
                <img
                  src={image.originalUrl}
                  alt="Main"
                  className="rounded-lg absolute inset-0 w-full h-full object-cover"
                />
              </div>
            ))}
          </Flickity>
        </div>
      </div>
    </div>
  );
}

export default ProductCarousel;
