import React, {useEffect, useState} from 'react';
import {getTaxonomy, searchEvents} from "../../../utils/api";
import MetaTags from "../../../atoms/MetaTags";
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import {Link} from 'react-router-dom';
import ButtonViewMore from "../../../atoms/ButtonViewMore";
import CardExperience from "../../../atoms/CardExperience";
import Button from "../../../atoms/Button";
import generalContentPage from "../../containers/generalContentPage";
import queryString from "qs";
import {DropdownFilter} from "../../../atoms/DropdownFilter";
import BlockSignUp from "../../../atoms/BlockSignUp";

const FilterPanel = ({ types, durations, locations, queryParams }) => {
  const type = queryParams['type'];
  const location = queryParams['location'];
  const duration = queryParams['duration'];

  return (
    <>
      <div className="text-white hidden sm:block">
        Filter by
      </div>
      <div className="mx-0 sm:space-x-4 space-y-4 lg:space-y-0 flex-1 flex-col flex w-full sm:flex-row">
        <Link to="/experiences" component={Button} colour={(!type && !duration && !location) ? 'bg-yellow text-dark-navy' : 'bg-dark-grey text-white'}>
          All
        </Link>
        <DropdownFilter name="Type" url="/experiences" param="type[]" queryParams={queryParams} slug={type} options={types} />
        <DropdownFilter name="Duration" url="/experiences" param="duration[]" queryParams={queryParams} slug={duration} options={durations} />
        <DropdownFilter name="Location" url="/experiences" param="location[]" queryParams={queryParams} slug={location} options={locations} />
      </div>
    </>
  )
}

const Events = ({ location, perPage = 12 }) => {
  const [experiences, setExperiences] = useState([]);
  const [ search, setSearch ] = useState('');
  const [ loading, setLoading ] = useState(undefined);
  const [ hasMore, setHasMore ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ durations, setDurations ] = useState([]);
  const [ locations, setLocations ] = useState([]);
  const [ types, setTypes ] = useState([]);

  const fetchArticles = (page) => {
    const sort = [
      {
        "pinned_priority": {
          "order" : "asc" ,
          // "type": "integer",
          "unmapped_type" : "integer"
        }
      }
    ];
    return searchEvents(undefined, search, ['duration', 'location', 'type'], perPage, page, sort).then(data => {
      setLoading(false);
      setDurations(data.aggs['duration'] ? data.aggs['duration'].buckets.map(b => b.key) : []);
      setLocations(data.aggs['location'] ? data.aggs['location'].buckets.map(b => b.key) : []);
      setTypes(data.aggs['type'] ? data.aggs['type'].buckets.map(b => b.key) : []);

      setHasMore(data.total.value > (perPage * page));

      return data;
    });
  }

  useEffect(() => {
    setLoading('search');

    fetchArticles(1).then(data => {
      setExperiences(data.hits.events ? data.hits.events : []);
      setPage(1);
    });
  }, [location.search, search])

  // New page - concat
  useEffect(() => {
    setLoading('page');

    if (page > 1) {
      fetchArticles(page).then(data => {
        setExperiences(events => [...events, ...(data.hits.events ? data.hits.events : [])]);
      })
    }
  }, [page]);

  const queryParams = queryString.parse(location.search.replace('?', ''));

  const filteredExperiences = experiences.filter(experience => {
    if (queryParams['type'] && (!Array.isArray(experience.type) || !experience.type.some(v => queryParams['type'].includes(v)))) {
      return false;
    }

    if (queryParams['duration'] && !queryParams['duration'].includes(experience.duration)) {
      return false;
    }

    if (queryParams['location'] && !queryParams['location'].includes(experience.location)) {
      return false;
    }

    return true;
  });

  return (
    <>
      <MetaTags title="Events" pathname={location.pathname} />

      <div className="pb-[80px] xl:pb-[120px]">
        <div className="bg-dark-navy">
          <div className="container px-pad-sm mx-auto py-[80px] xl:py-[120px]">
            <div className="flex items-center">
              <div className="uppercase text-14 text-white">
                Experiences
              </div>
            </div>
            <h2 className="font-heading font-bold text-30 lg:text-50 pt-3 pb-6 xl:pb-4 xl:pb-8 max-w-[650px] text-white">
              It’s time to get out and enjoy
              what life has to offer. It’s
              time to <span className="sm:text-yellow">see the world</span>
            </h2>
            <div className="text-white text-20 max-w-[650px] mb-24">
              Going somewhere you've never gone is a good place to start. You can never be sure where your purpose might be. You can experience fresh situations, people, and ideas by changing your surroundings.
            </div>
            {/* FILTER BAR */}
            <div className="flex flex-col sm:flex-row items-start space-y-4 lg:space-y-0 sm:space-x-5 xl:space-x-10 xl:items-center justify-between py-8 xl:py-14 relative">
              <FilterPanel durations={durations} locations={locations} types={types} events={experiences} queryParams={queryParams} />
              <label
                className="xl:max-w-[400px] flex-1 bg-mid-grey rounded-md w-full sm:w-auto pl-4 flex items-center overflow-hidden">
                {loading === 'search' && <LoadingSpinner size={5} />}
                {!loading && <img src="/images/icon-search.svg" className="w-5 h-5" alt="Search"/>}
                <input className="appearance-none bg-mid-grey w-full py-2 px-4" type="text"
                       placeholder="Keyword search" onChange={e => setSearch(e.target.value)}/>
              </label>
            </div>
          </div>
        </div>
        <div className="container px-pad-sm mx-auto">
          <div className="grid gap-x-10 gap-y-20 md:grid-cols-2 xl:grid-cols-3 mt-[-50px] xl:mt-[-90px]">
            {!loading && filteredExperiences.length === 0 && <h3 className="text-10 text-white">Sorry, no results were found for your search</h3>}
            {filteredExperiences.length > 0 && filteredExperiences.map(experience => (
              <CardExperience image={experience.secondaryImage ? experience.secondaryImage['thumbUrl']: undefined} key={experience.id} text={experience.name} textColour={'text-black'} slug={experience.slug} badge={experience.badge} />
            ))}
          </div>
          <div className="mt-8 xl:mt-14 text-right">
            {loading === 'page' && <LoadingSpinner size={8} />}

            {!loading && hasMore && <ButtonViewMore onClick={(e) => {
              e.preventDefault();
              setPage(page => page + 1);
            }} />}
          </div>
        </div>
      </div>
      <div className="mb-32">
        <BlockSignUp />
      </div>
    </>
  );
}

export default generalContentPage(
  Events
);
