import { getFollowed } from '../utils/api';

export const FOLLOWED_UPDATE = 'FOLLOWED_UPDATE';
export const followedUpdate = data => dispatch => dispatch({ type: FOLLOWED_UPDATE, payload: data });

export const FOLLOWED_LOADING = 'FOLLOWED_LOADING';
export const followedLoad = () => dispatch => {
  dispatch({
    type: FOLLOWED_LOADING,
  });
  return getFollowed()
    .then(response => {
      if (response) {
        dispatch(followedUpdate(response));
      }
    })
    .catch(err => {
      console.warn(err);
    });
};
