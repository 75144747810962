import inspirationConfig from '../config/inspiration-config';
import { CLOSE_INSPIRATION } from '../actions/inspirationsAction';

const getRandomInt = () => inspirationConfig[Math.floor(Math.random() * Math.floor(inspirationConfig.length))];
const initialState = {
  closed: false,
  current: getRandomInt(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_INSPIRATION:
      return {
        ...state,
        closed: true,
      };
    default:
      return state;
  }
};
