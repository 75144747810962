import { getMe, deleteUserAvatar, deleteUserPhoto } from '../utils/api/get';
import { patchCurrentUser, postUserAvatar, postUserPhotos } from '../utils/api/post';

import { updateMenu } from './menuAction';

export const USER_START_LOADER = 'USER_START_LOADER';
export const USER_REQUEST_ERROR = 'USER_REQUEST_ERROR';

export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';

export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const AVATAR_UPDATE_SUCCESS = 'AVATAR_UPDATE_SUCCESS';
export const PHOTOS_UPDATE_SUCCESS = 'PHOTOS_UPDATE_SUCCESS';
export const PHOTOS_DELETE_SUCCESS = 'PHOTOS_DELETE_SUCCESS';

export const getProfileMe = token => {
  return dispatch => {
    dispatch({
      type: USER_START_LOADER,
    });
    return getMe(token)
      .then(res => {
        const { id } = res;
        dispatch({
          type: AUTH_USER_SUCCESS,
          payload: res,
        });
        dispatch(updateMenu(id));
        return res;
      })
      .catch(err => {
        dispatch({
          type: USER_REQUEST_ERROR,
          payload: err,
        });
      });
  };
};

export const updateUserProfile = data => {
  return dispatch => {
    dispatch({
      type: USER_START_LOADER,
    });
    return patchCurrentUser(data)
      .then(res => {
        dispatch({
          type: USER_UPDATE_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: USER_REQUEST_ERROR,
          payload: err,
        });
      });
  };
};

export const updateUserAvatar = data => {
  return dispatch => {
    dispatch({
      type: USER_START_LOADER,
    });
    return postUserAvatar(data)
      .then(res => {
        dispatch({
          type: AVATAR_UPDATE_SUCCESS,
          payload: res.avatarUrl,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: USER_REQUEST_ERROR,
          payload: err,
        });
      });
  };
};
export const userAvatarUpdated = data => {

  return dispatch => {
    console.log(1);
    dispatch({
      type: AVATAR_UPDATE_SUCCESS,
      payload: data.avatarUrl,
    });
    console.log(2, data);
    return data;
  };
};

export const deleteAvatar = () => {
  return dispatch => {
    dispatch({
      type: USER_START_LOADER,
    });
    return deleteUserAvatar()
      .then(res => {
        dispatch({
          type: AVATAR_UPDATE_SUCCESS,
          payload: null,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: USER_REQUEST_ERROR,
          payload: err,
        });
      });
  };
};

export const updateUserPhotos = data => {
  return dispatch => {
    dispatch({
      type: USER_START_LOADER,
    });
    return postUserPhotos(data)
      .then(res => {
        dispatch({
          type: PHOTOS_UPDATE_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: USER_REQUEST_ERROR,
          payload: err,
        });
      });
  };
};

export const deleteUserPhotos = id => {
  return dispatch => {
    dispatch({
      type: USER_START_LOADER,
    });
    return deleteUserPhoto(id)
      .then(res => {
        dispatch({
          type: PHOTOS_DELETE_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(err => {
        dispatch({
          type: USER_REQUEST_ERROR,
          payload: err,
        });
      });
  };
};
