import React, {useState, useEffect, Fragment, useLayoutEffect, useRef} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MetaTags from "../../../atoms/MetaTags"
import PostThumb from '../../../atoms/PostThumb';
import PostType from '../../../atoms/PostType';
import Tags from '../../../atoms/Tags';
import Ratings from '../../shared/Ratings';
import generalPage from '../../containers/generalPage';

import { getArticle, getProductsById } from '../../../utils/api/get';
import { getArticleCount, setArticleCount } from "../../../utils";
import {formatDate, stripStringToWordBoundary} from "../../../utils/helpers"
import ArticlesContainer from '../../containers/ArticlesContainer';
import ItemsWithPagination from '../../containers/ItemsWithPagination';
import SocialShare from "../../../atoms/SocialShare";
import generalContentPage from "../../containers/generalContentPage";
import BlockExperiencesSlider from "../../../atoms/BlockExperiencesSlider";
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import useWebShare from 'react-use-web-share';
import '../../../scss/components/_post.scss';
import ReactDOMServer from 'react-dom/server';


import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

import {
  searchEvents,
} from "../../../utils/api";
import moment from "moment/moment";
import LoadingBlock from "../../../atoms/LoadingBlock";
import ButtonViewMore from "../../../atoms/ButtonViewMore";
import ArticleItem from "../../../atoms/ArticleItem";
import Flickity from "react-flickity-component";
import flickityConfig from "../../../config/flickity-config";
import CardExperience from "../../../atoms/CardExperience";
import CardProduct from '../../../atoms/CardProduct';
import reactStringReplace from 'react-string-replace';

import parse from "html-react-parser";

function CardArticle({ url, text, category, image, style }) {
  const getMainPic = picArr => (picArr && picArr.length ? picArr[0].thumbUrl : '/images/landing-welcome-bg-mobile.jpg');

  if(typeof style === 'undefined' || style === 'short') {
    return (
      <a href={url}>
        <div className="bg-dark-navy rounded-xl mb-4 overflow-hidden">
          <img className="w-full" src={image} alt={text} />
        </div>
        <div>
          <h5 className="uppercase text-14 text-dark-grey mb-2">{category}</h5>
          <h4 className="text-20">
            {text}
          </h4>
        </div>
      </a>
    );
  } else {
    return (
      <a href={url} className="bg-dark-navy rounded-xl xl:col-span-2 px-8 py-10 flex items-end justify-end h-[70vh] max-h-[500px] relative overflow-hidden">
        <img className="absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover" src={image} alt={text} />
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-gradient-to-b xl:bg-gradient-to-br from-[transparent] to-dark-navy opacity-80"></div>
        <div className="w-full xl:max-w-[350px] relative">
          <h5 className="uppercase text-14 text-white/40 mb-2">{category}</h5>
          <h4 className="text-20 text-white">
            {text}
          </h4>
        </div>
      </a>
    );
  }
}

function BlockPostsSlider({ title, items }) {
  const styles = "container px-pad-sm mx-auto py-[80px] xl:py-[120px]";
  return (
    <div className="overflow-hidden">
      <div className={styles}>
        <h2 className="font-heading font-bold text-30 lg:text-40 pb-8 xl:pb-14">
          {title}
        </h2>
        <Flickity
          className={'slider'}
          options={flickityConfig}
        >
          {items.map(article => (
            <ArticleItem key={article.id} {...article} style="large" addedStyles="w-full md:w-1/2 lg:w-1/3 max-w-[400px]" />
          ))}
        </Flickity>
      </div>
    </div>
  );
}

const SingleArticle = props => {
  const { shareLoading, isSupported, share } = useWebShare();

  const [loading, setLoading] = useState(true);

  const PREVIEW_TEXT_CHAR_LIMIT = 380;
  const [article, setArticle] = useState({});
  const [articleCount, setCount] = useState(0);

  const { author, type, body, title = 'Loading', tags, reactions, createdAt, publishedAt, id, shortDescription, videoEmbed } = article;

  const searchForProductShortcode = (str) => {
    if (!str) {
      return;
    }
    return parse(str, {
      replace: (domNode) => {
        if (domNode.data?.includes('[product')) {
          const shortcodeRegex = /\[products=(.+?)\]/g;
          return <>
            {reactStringReplace(domNode.data, shortcodeRegex, (match, i) => {
              const ids = match.split(',').map(id => id.trim());
              return <DisplayProducts ids={ids}/>
            })}
          </>
        }
      }
    });
  };

  const DisplayProducts = ({ ids }) => {
    const [data, setData] = useState([]);
    const shortcodeProductsFlickity = flickityConfig
    shortcodeProductsFlickity.prevNextButtons = true
    useEffect(() => {
      getProductsById(ids).then(data => {
        setData(data.data)
      });
    }, [ids]);
      return (
        <div className="overflow-hidden pr-[20px] py-8 yellow-slider">
          {
            data.length > 2 && (
              <Flickity
                className={'slider slider-with-btns yellow-flickity yellow-with-buttons' + ( (data.length > 2) ? '' : ' products-on-article' )}
                options={shortcodeProductsFlickity}
              >
                {data.map(product => (
                  <div key={product.id} className="w-1/2 h-[208px] w-[162px] lg:h-[379px] lg:w-[297px]">
                    <a className='block h-full' href={`/shop/${product.slug}`}>
                      <img src={product.images[0]['thumbPath']} className="object-cover w-full h-full rounded-md !m-0" loading="lazy"/>
                    </a>
                  </div>
                ))}
              </Flickity>
             )
          }
          {
            data.length <= 2 && (
              <div className="lg:grid grid-cols-2 flex gap-4 lg:gap-0">
                {data.map(product => (
                  <div key={product.id} className="w-1/2 h-[208px] w-[162px] lg:h-[379px] lg:w-[297px]">
                    <a className='block h-full' href={`/shop/${product.slug}`}>
                      <img
                        src={product.images[0]['thumbPath']}
                        className="object-cover w-full h-full rounded-md !m-0"
                        loading="lazy"
                        alt={product.title}
                      />
                    </a>
                  </div>
                ))}
              </div>
            )
          }
        </div>
      )
  };


  useEffect(() => {
    const { slug } = props.match.params;
    getArticle(slug).then(data => {
      setArticle(data);
      setLoading(false);
      incrementArticleCounter();
    });
  }, [props.match.params.slug]);

   const incrementArticleCounter = () => {
      if (getArticleCount()) {
        const currentCount = parseInt(getArticleCount());
          setCount(currentCount);
        setArticleCount((currentCount + 1).toString());
      }
   };

   let icon = '/images/icon-lifestyle.svg';

   if (tags) {
     switch (tags[0]) {
       case 'travel':
         icon = '/images/icon-travel.svg';
         break;
       case 'lifestyle':
         icon = '/images/icon-lifestyle.svg';
         break;
       case 'health':
         icon = '/images/icon-health.svg';
         break;
       case 'purpose':
         icon = '/images/icon-purpose.svg';
         break;
       case 'relationships':
         icon = '/images/icon-relationships.svg';
         break;
     }
   }

  const wysiwyg = useRef(null);

  useLayoutEffect(() => {
    if (wysiwyg.current) {
      let images = wysiwyg.current.getElementsByTagName('img');

      for(let i = 0; i < images.length; i++)
      {
        if (i === 0) {
          images[i].style.width = '';
          images[i].className += ' first-image';
        }
        else {
          images[i].style.width = '500px';
        }

        if (images[i].parentNode.tagName.toLowerCase() === 'p') {
          images[i].parentNode.replaceWith(images[i]);
        }
      }
    }
  }, [loading]);

  return (
    <>
      <MetaTags
          title={title}
          shortDescription={shortDescription}
          pathname={props.location.pathname}
      />
      <div className="bg-dark-navy background-shapes-dark">
        <div className="max-w-[1040px] px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[150px] xl:pb-[200px]">
          {loading && <LoadingBlock colour="white" size={10} text="Loading" classNames="align-center" />}
          {!loading && (
            <>
              <div className="flex items-center">
                <div className="rounded-full bg-white h-[50px] aspect-square flex items-center justify-center mr-4">
                  <img className="inline-block h-[35px]" src={icon} alt="" />
                </div>
                <div className="uppercase text-14 text-white">
                  {tags.join(', ')}
                </div>
              </div>
              <h2 className="font-heading font-bold text-30 lg:text-50 py-6 xl:py-8 max-w-[700px] text-white">
                {title}
              </h2>
              <div className="text-white text-20 max-w-[700px]" dangerouslySetInnerHTML={{__html: shortDescription}}>
              </div>
              {author.id && (
                <div className="flex items-center py-6 xl:pt-8 xl:pb-14">
                  {author.avatar && (
                    <div className="rounded-full bg-white h-[50px] aspect-square flex items-center justify-center mr-4 overflow-hidden">
                      <img className="inline-block h-[50px] aspect-square" src="https://via.placeholder.com/50" alt="Plane" />
                    </div>
                  )}
                  <div className="text-white">
                    <div className="text-18">{author.displayName}</div>
                    {publishedAt && (
                      <div className="text-12">{formatDate(publishedAt, 'Do MMMM YY')}</div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="max-w-[790px] px-pad-sm mx-auto">
        {loading && <LoadingBlock size={10} text="Loading" classNames="align-center my-14" />}
        <div
          className="wysiwyg"
          ref={wysiwyg}
        >
          {searchForProductShortcode(body)}
        </div>
        <div className="border-t border-yellow flex justify-between items-center h-[50px] my-14">
          <div>
            {isSupported && (
              <a className="text-yellow underline" href="#" onClick={e => {
                e.preventDefault();
                share({ url: window.location.href });
              }}>
                Share
              </a>
            )}
          </div>
          <div className="flex space-x-4 items-center">
            <TwitterShareButton url={window.location.href}>
              <svg width="24" height="20" viewBox="0 0 24 20" className="fill-yellow" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.5156 5.125C22.4531 4.42188 23.2969 3.57812 23.9531 2.59375C23.1094 2.96875 22.125 3.25 21.1406 3.34375C22.1719 2.73438 22.9219 1.79688 23.2969 0.625C22.3594 1.1875 21.2812 1.60938 20.2031 1.84375C19.2656 0.859375 18 0.296875 16.5938 0.296875C13.875 0.296875 11.6719 2.5 11.6719 5.21875C11.6719 5.59375 11.7188 5.96875 11.8125 6.34375C7.73438 6.10938 4.07812 4.14062 1.64062 1.1875C1.21875 1.89062 0.984375 2.73438 0.984375 3.67188C0.984375 5.35938 1.82812 6.85938 3.1875 7.75C2.39062 7.70312 1.59375 7.51562 0.9375 7.14062V7.1875C0.9375 9.57812 2.625 11.5469 4.875 12.0156C4.5 12.1094 4.03125 12.2031 3.60938 12.2031C3.28125 12.2031 3 12.1562 2.67188 12.1094C3.28125 14.0781 5.10938 15.4844 7.26562 15.5312C5.57812 16.8438 3.46875 17.6406 1.17188 17.6406C0.75 17.6406 0.375 17.5938 0 17.5469C2.15625 18.9531 4.73438 19.75 7.54688 19.75C16.5938 19.75 21.5156 12.2969 21.5156 5.78125C21.5156 5.54688 21.5156 5.35938 21.5156 5.125Z"/>
              </svg>
            </TwitterShareButton>

            <FacebookShareButton url={window.location.href}>
              <svg width="24" height="24" viewBox="0 0 24 24" className="fill-yellow" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 17.8125 4.59375 22.6406 10.1719 23.4844V15.375H7.21875V12H10.1719V9.46875C10.1719 6.5625 11.9062 4.92188 14.5312 4.92188C15.8438 4.92188 17.1562 5.15625 17.1562 5.15625V8.01562H15.7031C14.25 8.01562 13.7812 8.90625 13.7812 9.84375V12H17.0156L16.5 15.375H13.7812V23.4844C19.3594 22.6406 23.625 17.8125 23.625 12Z"/>
              </svg>
              </FacebookShareButton>
          </div>
        </div>
      </div>

      {article && article.relatedPosts && article.relatedPosts.length > 0 && (
        <BlockPostsSlider title="Related posts" items={article.relatedPosts} />
      )}

      {article && article.relatedEvents && article.relatedEvents.length > 0 && (
        <BlockExperiencesSlider items={article.relatedEvents} />
      )}
    </>
  );
};

SingleArticle.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({});

export default generalContentPage(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SingleArticle),
  'single-article',
);
