import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/helpers';
import Avatar from './Avatar';
import moment from 'moment';

const PostThumb = props => {
  const { url, date, img, fullName, extended, sub, author, followers, isGuestAuthor } = props;
  const content = (
    <Fragment>
      <div className="post-thumb__pic">
        <Avatar src={img} className="profile-thumb__img" fullName={fullName || 'All I Can Tell You'} />
      </div>
      <div className="post-thumb__description">
        <div className="post-thumb__main">
          <div className="post-thumb__title" title={fullName || 'All I Can Tell You'}>
            {fullName || 'All I Can Tell You'}
            {author && <div className="btn btn--author-tag">(Author)</div>}
          </div>
          {sub && <div className="post-thumb__sub-title">{sub}</div>}
        </div>
        {date && date.length > 0 && (
          <div className="post-thumb__second">
            {/* {followers && followers.length > 0 && <div className="post-thumb__date">{followers}</div>} */}
            <div className="post-thumb__date">{moment(date).fromNow()}</div>
          </div>
        )}
      </div>
    </Fragment>
  );

  return (url && !isGuestAuthor) ? (
    <Link to={`/profile/${url}/about`} className={classNames('post-thumb', { 'post-thumb--extended': extended })}>
      {content}
    </Link>
  ) : (
    <div className={classNames('post-thumb', { 'post-thumb--extended': extended })}>{content}</div>
  );
};

PostThumb.propTypes = {
  url: PropTypes.string,
  date: PropTypes.string,
  img: PropTypes.string,
  fullName: PropTypes.string,
  extended: PropTypes.bool,
  sub: PropTypes.string,
  author: PropTypes.bool,
};

export default PostThumb;
