import { useEffect, useState } from "react"
import InputError from "./InputError"
import { validatePhone } from "../../utils/api"

const PhoneInput = ({value, handleInputChange, validationDetails, validationDetailsSetter = null}) => {

    const [phoneValid, setPhoneValidation] = useState(false)
    const [input_value, setInputValue] = useState(value)
    const validationErrorMessage = "Please enter a valid phone number.";

    useEffect(() => {
      let is_valid = checkAgainstLocalStorage(input_value) == "true"
      setPhoneValidation(is_valid);
      if(validationDetailsSetter) {
        validationDetailsSetter((prev) => ({
            ...prev,
            phone: is_valid
        }))
    }
    }, [input_value])

    const validate = (event) => {
      const inputValue = event.target.value;
      setInputValue(inputValue);
      if (inputValue !== '') {
        isPhone(event.target.value)
          .then((response) => {
            setPhoneValidation(response);
            validationDetailsSetter((prev) => ({
              ...prev,
              phone: response
          }))
          })
          .catch((error) => {
            setPhoneValidation(false);
            validationDetailsSetter((prev) => ({
              ...prev,
              phone: false
          }))
          });
      }
      
      handleInputChange(event);
    };
    
    const isPhone = (phone) => {
      return new Promise((resolve, reject) => {
        var valid_phone = true;
        phone = phone.replace(/\s/g, "");
        phone = phone.replace("+44", "0");
        
        if (!(phone.substr(0, 2) == "01" || phone.substr(0, 2) == "02" || phone.substr(0, 2) == "07")) {
          reject(false);
          return
        } else if ( (phone.substr(0, 2) == "07" && !phone.match(/^\d{11}$/g)) || !phone.match(/^\d{10,11}$/g) ) {
          reject(false);
          return
        } else if ( phone.substr(0, 3) == "070" || phone.substr(0, 3) == "071" || phone.substr(0, 3) == "072" ) {
          reject(false);
          return
        } else if (phone.substr(0, 3) == "076" && phone.substr(0, 5) !== "07624") {
          reject(false);
          return
        } else {
          if (valid_phone) {
            var phone_key = phone.replace(/[^0-9]/ig, "");
            var local_storage_check = checkAgainstLocalStorage(phone_key)
            if (local_storage_check === "true") {
              resolve(true);
              return
            } else {
              // validate phone uses ServiceAPI which attaches bearer to it etc
              validatePhone({ phone: phone })
                .then((response) => {
                  if (response.hasOwnProperty("status") && response.status === 200) {
                    if (response.hasOwnProperty("data") && response.data.hasOwnProperty("valid_phone")) {
                      localStorage.setItem("ajax_validate_" + phone_key, response.data.valid_phone)
                      resolve(response.data.valid_phone);
                      return
                    } else {
                      reject(false);
                      return
                    }
                  } else {
                    reject(false);
                    return
                  }
                })
                .catch((error) => {
                  reject(false);
                  return
                });
            }
          } else {
            reject(false);
            return
          }
        }
      });
    };

    const checkAgainstLocalStorage = (phone) => {
      if(phone) {
        return localStorage.getItem("ajax_validate_" + phone.replace(/[^0-9]/ig, ""));
      }
    }
    
      return (
        <>
            <input  required 
                    placeholder="Phone*" 
                    type="tel" 
                    name="phone" 
                    id="phone" 
                    value={input_value} 
                    className={
                        "block w-full rounded-[10px] py-3.5 px-5 border " + 
                        ( !phoneValid && input_value !== undefined ? 'border-[#dc2626]' : 'border-[#ffd23f]' )
                    } 
                    onChange={validate} 
            />
            { !phoneValid && input_value !== undefined && (
                <InputError message={validationErrorMessage} />
            )}
        </>
      )
}

export default PhoneInput;