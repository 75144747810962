import React from 'react';
import PropTypes from 'prop-types';
import { upperFirst } from 'lodash';

const Tags = ({ tags, editMode, title, type, onChange }) => {
  const tagExists = tag => tags.filter(item => item === tag).length;

  const onAdd = value => {
    if (value) {
      if (!tagExists(value)) {
        onChange([...tags, value]);
      }
    }
  };
  const onDelete = value => {
    onChange(tags.filter(item => item !== value));
  };

  return (
    <div className="tags">
      {title && <div className="tags__title">{title}</div>}
      {editMode && (
        <div className="tags__input-frame">
          <input
            type="text"
            className="tags__input"
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onAdd(e.target.value);
                e.target.value = '';
              }
            }}
          />
        </div>
      )}
      <ul className="tags-list">
        <li className="tags-list__item">
          <div className="btn btn--tag btn--tag--special">
            <span className="btn__text">{upperFirst(type)}</span>
          </div>
        </li>
        {tags.slice(0, 2).map((tag, index) => (
          <li className="tags-list__item" key={index}>
            <div className="btn btn--tag">
              <span className="btn__text" title={tag}>{tag}</span>
              {editMode && (
                <span
                  className="tags-list__delete"
                  onClick={() => {
                    onDelete(tag);
                  }}
                  onKeyDown={() => {
                    onDelete(tag);
                  }}
                  role="button"
                  tabIndex="0"
                >
                  <i className="fas fa-times" />
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.array,
  editMode: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

export default Tags;
