import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import FeedCategories from './FeedCategories';
import { setOpened } from '../../actions/configAction';
import Icon from "../../Icon";

class Feed extends Component {
  render() {
    const { pathname } = this.props.location;
    const { setOpened } = this.props;
    return (
      <div className="feed">
        <div className="feed__frame">
          {/*<div className="feed__title">Feed</div>*/}
          <ul className="feed-list">
              {this.props.currentUser && this.props.currentUser.spaces &&
              <li className="feed-list__item">
                <button
                  className={classNames('feed-list__link', {'feed-list__link--active': pathname === '/groups'})}
                  onClick={() => {
                    this.props.history.push('/groups');
                    setOpened(null);
                  }}
                  type="button"
                >
                  <Icon name="ico-agree"/>
                  <span className="feed-list__text bigger">Your Groups</span>
                </button>
              </li>
              }
              {this.props.currentUser &&
              <li className="feed-list__item">
                  <button
                      className={classNames('feed-list__link', {'feed-list__link--active': pathname === '/diary'})}
                      onClick={() => {
                          this.props.history.push('/diary');
                          setOpened(null);
                      }}
                      type="button"
                  >
                      <Icon name="ico-diary" />
                      <span className="feed-list__text bigger">Diary Feed</span>
                  </button>
              </li>
              }
            <li className="feed-list__item">
              <button
                className={classNames('feed-list__link', { 'feed-list__link--active': pathname === '/lifestyle' })}
                onClick={() => {
                  this.props.history.push('/lifestyle');
                  setOpened(null);
                }}
                type="button"
              >
                <Icon name="ico-article" />
                <span className="feed-list__text bigger">Lifestyle</span>
              </button>
            </li>
            {/* <li className="feed-list__item">
              <button
                className={classNames('feed-list__link', { 'feed-list__link--active': pathname === '/authors' })}
                onClick={() => {
                  this.props.history.push('/authors');
                  setOpened(null);
                }}
                type="button"
              >
                <span className="feed-list__text">Writers</span>
                <span className="feed-list__icon">
                  <i className="fas fa-user" />
                </span>
              </button>
            </li> */}
            {/*<li className="feed-list__item">*/}
              {/*<button*/}
                {/*className={classNames('feed-list__link', { 'feed-list__link--active': pathname === '/explore' })}*/}
                {/*onClick={() => {*/}
                  {/*this.props.history.push('/explore');*/}
                  {/*setOpened(null);*/}
                {/*}}*/}
                {/*type="button"*/}
              {/*>*/}
                {/*<span className="feed-list__text">Explore</span>*/}
                {/*<span className="feed-list__icon">*/}
                  {/*<i className="fas fa-globe" />*/}
                {/*</span>*/}
              {/*</button>*/}
            {/*</li>*/}
          </ul>
        </div>

        {/* <div className="feed-one-item feed-one-item--first frame-with-bg">
          <Link
            to="/articles/new"
            className={classNames('feed-list__link', { 'feed-list__link--active': pathname === '/articles/new' })}
          >
            <span className="feed-list__text">New article</span>
            <span className="feed-list__icon">
              <i className="fas fa-plus" />
            </span>
          </Link>
        </div> */}
        <FeedCategories location={this.props.location} setOpened={setOpened} history={this.props.history} />

          {/*<div className="feed__frame" style={{paddingTop: 12}}>*/}
          {/*    <ul className="feed-list">*/}
          {/*        <li className="feed-list__item">*/}
          {/*            <button*/}
          {/*                className={classNames('feed-list__link', { 'feed-list__link--active': pathname === '/toolkit' })}*/}
          {/*                onClick={() => {*/}
          {/*                    this.props.history.push('/toolkit');*/}
          {/*                    setOpened(null);*/}
          {/*                }}*/}
          {/*                type="button"*/}
          {/*            >*/}
          {/*                <span className="feed-list__text bigger last">Helpful Links</span>*/}
          {/*            </button>*/}
          {/*        </li>*/}
          {/*    </ul>*/}
          {/*</div>*/}
      </div>
    );
  }
}

Feed.propTypes = {
  location: PropTypes.object.isRequired,
};

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({
  setOpened: type => dispatch(setOpened(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Feed));
