import {
  GALLERY_UPDATE_IMAGES,
  GALLERY_LOADING,
  GALLERY_CLOSE,
  GALLERY_SET_CURRENT,
  GALLERY_OPEN,
} from '../actions/galleryAction';

const initialState = {
  isLoading: false,
  isOpen: false,
  current: null,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GALLERY_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GALLERY_UPDATE_IMAGES:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    case GALLERY_CLOSE:
      return {
        ...state,
        isOpen: false,
        isLoading: false,
        current: null,
      };
    case GALLERY_SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case GALLERY_OPEN:
      return {
        ...state,
        isOpen: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
