import LoadingSpinner from "../../../atoms/LoadingSpinner";
import React, {useState} from "react";
import {enquireProduct} from "../../../utils/api";
import Button from "../../../atoms/Button";
import {Link} from "react-router-dom";

function EnquiryModal({ title, productName, handleHideForm }) {
  let emptyFormState = {
    product_name: productName,
    first_name: '',
    last_name: '',
    email: '',
    email_confirmation: '',
    phone_number: '',
    services: [],
    service_other: '',
    timescale: '',
    message: '',
  };
  const [contactDetails, setContactDetails] = useState(emptyFormState);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);

  const handleChange = (e) => {
    setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
  }

  const handleCheck = (e) => {
    if (e.target.name.includes('[]')) {
      const name = e.target.name.replace('[]', '');

      if (e.target.checked) {
        setContactDetails({...contactDetails, [name]: [...contactDetails[name], e.target.value]});
      }
      else {
        setContactDetails({...contactDetails, [name]: contactDetails[name].filter((item) => item !== e.target.value)});
      }
    }
    else {
      if (e.target.checked) {
        setContactDetails({...contactDetails, [e.target.name]: e.target.value});
      }
    }
  }

  console.log(contactDetails);

  const sendContactForm = (details) => {
    setLoading(true);
    enquireProduct(details).then((response) => {
      window.scrollTo(0, 120);
      setLoading(false);
      if (!response.success) {
        setFormErrors({ ...response.errors });
        setErrorMessage('Sorry, your message could not be sent. Please correct the errors and try again');
        setSuccessMessage(undefined);
      }
      else {
        setErrorMessage(undefined);
        setSuccessMessage('We’ll be in touch as soon as possible.');
        setContactDetails(emptyFormState);
        setFormErrors({});
      }
    })
      .catch((error) => {
        setLoading(false);
        setErrorMessage('Sorry, an unknown error occurred while sending your message. Please try again later.');
      });
  }

  return <div className="flex justify-center" aria-labelledby="modal-title" role="dialog"
              aria-modal="true">
    <div className="fixed inset-0 z-100 bg-dark-navy bg-opacity-90 transition-opacity" onClick={handleHideForm}></div>

    <div className="absolute top-[140px] z-100 w-full sm:max-w-6xl mx-auto">
      <img src="/images/exit-icon.svg" onClick={handleHideForm} alt="Close" className="absolute top-0 right-0 cursor-pointer invert" />
      <div
        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div
          className="relative transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:my-8">
          <div className="bg-white text-dark-navy p-20">
            {successMessage && (<div className="flex flex-col space-y-10 items-center text-center">
              <img className="w-[82px] aspect-square"
                   src="/images/smiley-thanks.svg" />
              <h2 className="font-heading font-bold text-40">Thanks for Contacting<br /><span className="text-yellow">Grandudes</span></h2>
              <p className="text-20">{successMessage}</p>
              <p><Link to="/" component={Button} colour="bg-yellow">Go to home</Link></p>
            </div>)}

            {!successMessage && <>
              <h3 className="font-heading text-30 font-bold mb-6">{title}</h3>
              <div className="wysiwyg">
                <p>Please complete the form below and we’ll be in touch as soon as possible.</p>
              </div>
              {errorMessage && (<div className="failed-validation mt-6 py-4 px-6 rounded relative mb-8" role="alert">
                <img src={"/images/warning.svg"} className="inline mr-2" alt="" />
                {errorMessage}
              </div>)}
              <EnquiryForm contactDetails={contactDetails} handleChange={handleChange} handleCheck={handleCheck} sendContactForm={sendContactForm} formErrors={formErrors} isLoading={loading} />
            </>}
          </div>
        </div>
      </div>
    </div>
  </div>
}

function EnquiryForm({ contactDetails, handleChange, handleCheck, sendContactForm, formErrors, isLoading }) {
  const services = [
    'Letters',
    'Memoirs',
    'Eulogy',
  ];

  return <form action="#" method="POST" className="mt-10">
    <h5 className="text-14 font-bold mb-4">Your details</h5>
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-3">
        <input
          className={`placeholder-black py-4 px-6 border border-dark-navy rounded-md w-full ${formErrors.first_name ? 'failed-validation' : ''}`}
          type="text" placeholder="First Name*" name="first_name" id="first-name"
          value={contactDetails.first_name} onChange={handleChange}
          autoComplete="given-name" />
        {formErrors.first_name && <span className="text-[#EA4751]"><img src={"/images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.first_name}</span>}
      </div>
      <div className="col-span-6 sm:col-span-3">
        <input
          className={`placeholder-black py-4 px-6 border border-dark-navy rounded-md w-full ${formErrors.last_name ? 'failed-validation' : ''}`}
          type="text" placeholder="Last Name*" name="last_name" id="last-name"
          value={contactDetails.last_name} onChange={handleChange}
          autoComplete="family-name" />
        {formErrors.last_name && <span className="text-[#EA4751]"><img src={"/images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.last_name}</span>}
      </div>
      <div className="col-span-6 sm:col-span-3">
        <input
          className={`placeholder-black py-4 px-6 border border-dark-navy rounded-md w-full ${formErrors.email ? 'failed-validation' : ''}`}
          type="text" placeholder="Email*" name="email" id="email-address"
          value={contactDetails.email} onChange={handleChange}
          autoComplete="email" />
        {formErrors.email && <span className="text-[#EA4751]"><img src={"/images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.email}</span>}
      </div>
      <div className="col-span-6 sm:col-span-3">
        <input
          className={`placeholder-black py-4 px-6 border border-dark-navy rounded-md w-full ${formErrors.email_confirmation ? 'failed-validation' : ''}`}
          type="text" placeholder="Confirm Email*" name="email_confirmation"
          value={contactDetails.email_confirmation} onChange={handleChange}
          id="email-address" autoComplete="email" />
        {formErrors.email_confirmation && <span className="text-[#EA4751]"><img src={"/images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.email_confirmation}</span>}
      </div>
      <div className="col-span-6 sm:col-span-3">
        <input
          className={`placeholder-black py-4 px-6 border border-dark-navy rounded-md w-full ${formErrors.phone_number ? 'failed-validation' : ''}`}
          type="text" placeholder="Phone" name="phone_number" id="phone-number"
          value={contactDetails.phone_number} onChange={handleChange}
          autoComplete="phone" />
        {formErrors.phone_number && <span className="text-[#EA4751]"><img src={"/images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.phone_number}</span>}
      </div>
    </div>
    <div className="grid grid-cols-6 gap-6 pt-6">
      <div className="col-span-6 sm:col-span-3">
        <h5 className="text-14 font-bold mb-4">Service required</h5>
        {services.map(option => <>
          <div className={`rounded-[10px] mr-6 mb-3 py-4 px-6 border-dark-navy border inline-block ${formErrors.services ? 'failed-validation' : ''}`}>
            <label>{option} <input onChange={handleCheck} checked={contactDetails.services.includes(option)} type="checkbox" name="services[]" value={option} /></label>
          </div>
        </>)}
        {formErrors.services && <span className="text-[#EA4751]"><img src={"/images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.services}</span>}
      </div>
      <div className="col-span-6 sm:col-span-3">
        <p>Other</p>
        <input
          className={`mt-4 py-4 px-6 border border-dark-navy rounded-md w-full ${formErrors.service_other ? 'failed-validation' : ''}`}
          type="text" placeholder="Please state" name="service_other" id="service_other"
          value={contactDetails.service_other} onChange={handleChange}
          autoComplete="service_other" />
        {formErrors.service_other && <span className="text-[#EA4751]"><img src={"/images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.service_other}</span>}
      </div>
    </div>
    <div className="grid grid-cols-6 gap-6 pt-6">
      <div className="col-span-6 sm:col-span-3">
        <h5 className="text-14 font-bold mb-4">Timescale</h5>
        <input
          className={`py-4 px-6 border border-dark-navy rounded-md w-full ${formErrors.timescale ? 'failed-validation' : ''}`}
          type="text" placeholder="Please state" name="timescale" id="timescale"
          value={contactDetails.timescale} onChange={handleChange}
          autoComplete="timescale" />
        {formErrors.timescale && <span className="text-[#EA4751]"><img src={"/images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.timescale}</span>}
      </div>
    </div>
    <div className="grid grid-cols-6 gap-6 pt-6">
      <div className="col-span-6">
        <div className="mt-2">
          <h5 className="text-14 mb-4"><span className="font-bold">Message</span></h5>
          <textarea id="contact-about" name="message" rows="3"
                    onFocus={e=> e.target.placeholder = ''}
                    onBlur={e => e.target.placeholder = 'Type your message here...'}
                    onChange={handleChange}
                    className={`mt-1 block w-full rounded-md border border-dark-navy py-2 px-2 sm:text-sm sm:leading-6 h-[120px] ${formErrors.message ? 'failed-validation' : ''}`}></textarea>
          {formErrors.message && <span className="text-[#EA4751]"><img src={"/images/warning.svg"} className="inline mr-2" alt="" /> {formErrors.message}</span>}
        </div>
      </div>
    </div>
    <div className="flex justify-end mt-8">
      <button
        onClick={(e) => {
          e.preventDefault();
          sendContactForm(contactDetails);
        }}
        className="bg-yellow text-dark-navy rounded-md py-4 lg:py-[10px] px-4 w-full lg:w-auto text-[16px] transition">
        {isLoading && (<><LoadingSpinner size={5} /> Submitting</>)}
        {!isLoading && (<>Submit form</>)}
      </button>
    </div>
  </form>
}

export default EnquiryModal;
