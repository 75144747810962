import { apiUrl } from '../constants';
import mock from './mock';
import serviceAPI from '../../services/serviceAPI';
import { catchExpiredToken } from '../helpers';
import feedCategoryTagsMap from "../../config/feed-category-tags-map";
import queryString from "qs";

require('es6-promise').polyfill();

const GET_ENDPOINTS = {
  metadata: `${apiUrl}/metadata`,
  taxonomy: `${apiUrl}/taxonomy`,

  me: `${apiUrl}/me`,
  bio: `${apiUrl}/bio`,
  questions: `${apiUrl}/bio/questions`,
  answers: `${apiUrl}/bio/answers`,
  getUser: `${apiUrl}/user`,

  events: `${apiUrl}/events`,

  deleteUserAvatar: `${apiUrl}/me/avatar`,
  photos: `${apiUrl}/me/photos`,

  posts: `${apiUrl}/posts`,
  home: `${apiUrl}/home`,
  feed: `${apiUrl}/feed/diary-posts`,
  diaryPosts: `${apiUrl}/diary-posts`,

  followed: `${apiUrl}/followed`,

  search: `${apiUrl}/search`,
  related: `${apiUrl}/related`,
  notifications: `${apiUrl}/notifications`,

  deletePhoto: `${apiUrl}/me/photos`,

  forumTopics: `${apiUrl}/forum-topics`,
  forumMessages: `${apiUrl}/forum-messages`,

  helpfulLinks: `${apiUrl}/helpful-links`,
  shipping: `${apiUrl}/shipping`,

  userGroups: `${apiUrl}/spaces/user`,
  userGroup: `${apiUrl}/spaces`,

  products: `${apiUrl}/products`,
  packages: `${apiUrl}/packages`,
  variations: `${apiUrl}/variations`,
};

const getData = (endpoint, addition = '') => {
  const url = `${GET_ENDPOINTS[endpoint]}${addition}`;
  return new Promise((resolve, reject) => {
    serviceAPI
      .get(url)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.response);
        }
      })
      .catch(error => {
        catchExpiredToken(error);
        reject(error.response ? error.response.data : 'Network error');
      });
  });
};

const deleteData = (endpoint, addition) => {
  const url = `${GET_ENDPOINTS[endpoint]}${addition}`;
  return new Promise((resolve, reject) => {
    serviceAPI
      .delete(url)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.response);
        }
      })
      .catch(error => {
        catchExpiredToken(error);
        reject(error.response ? error.response.data : 'Network error');
      });
  });
};

export const getMetadata = () => {
  return getData('metadata');
};

export const getTaxonomy = (keys = []) => {
  if (!Array.isArray(keys)) {
    keys = [keys];
  }

  return getData('taxonomy', '?' + queryString.stringify({
    'keys': keys
  }));
};

export const getMe = token => {
  if (token) {
    // eslint-disable-next-line
    serviceAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return getData('me');
};
export const getUser = id => getData('getUser', `/${id}`);

export const getBio = () => getData('bio');
export const getQuestions = () => getData('questions');
export const getAnswers = () => getData('answers');
export const deleteAnswer = id => deleteData('answers', `/${id}`);

export const deleteUserAvatar = () => deleteData('deleteUserAvatar', '');

export const getPhotos = () => getData('photos');
export const deletePhotos = id => deleteData('photos', `/${id}`);

export const getArticles = (limit = 10, startKey, severity) => {
  const id = startKey ? `start_key=${encodeURIComponent(startKey)}&` : '';
  const severityFilter = severity ? (severity[0] === '!' ? `severity!=${severity.slice(1)}` : `severity=${severity}`) : '';
  return getData('posts', `?${id}limit=${limit}&${severityFilter}`);
};

export const searchArticlesForCategory = (slugs, search = '', limit = 10, pageNo = 1) => {
  if (!slugs) {
    slugs = [];
  }
  else if (!Array.isArray(slugs)) {
    slugs = [slugs];
  }

  const searchTags = slugs.length ? slugs.map(slug => feedCategoryTagsMap[slug]).flat(1) : [];

  return searchByTags(searchTags, search, ['posts'], [], limit, pageNo);
};

export const searchEvents = (slugs = [], search = '', aggs = [], limit = 10, pageNo = 1, sort = null) => {
  if (!slugs) {
    slugs = [];
  }
  else if (!Array.isArray(slugs)) {
    slugs = [slugs];
  }
  return searchByTags(slugs, search, ['events'], aggs, limit, pageNo, sort);
};
export const relatedEvents = id => related(id, 'events', 6);

export const searchProducts = (slugs = [], search = '', limit = 10, pageNo = 1, sort = null) => {
  if (!slugs) {
    slugs = [];
  }
  else if (!Array.isArray(slugs)) {
    slugs = [slugs];
  }

  return searchByTags(slugs, search, ['products'], [], limit, pageNo, sort);
};

export const getVariations = id => getData('variations', `/${encodeURIComponent(id)}`);

// TODO: Let this handle multiple IDs
export const relatedProducts = id => related(id, 'products');
export const getProductsById = (ids) => getData('products', '?' + queryString.stringify({
  'ids': ids
}));

export const getShipping = () => getData('shipping');

export const getProduct = id => getData('products', `/${id}`);
export const getPackages = category => getData('packages', `?category=${encodeURI(category)}`);

export const getArticle = slug => getData('posts', `/${slug}`);
export const getFeed = (limit = 20, startKey) => {
  const id = startKey ? `start_key=${encodeURIComponent(startKey)}` : '';
  return getData('feed', `?${id}&limit=${limit}`);
};
export const getDiaryPosts = () => getData('diaryPosts');
export const deleteDiaryPost = id => deleteData('diaryPosts', `/${encodeURIComponent(id)}`);

export const getFollowed = () => getData('followed');

export const getNotifications = () => getData('notifications');

// TODO after backend integration
export const getInspirations = () => Promise.resolve(mock.inspirations);
// TODO after backend integration
export const getAutors = () => Promise.resolve(mock.authors);

export const getEvents = () => getData('events');
export const getEvent = id => getData('events', `/${id}`);

export const search = text => getData('search', `?q=${text}`);
export const searchByTags = (tags, query = '', types = ['posts'], aggs = [], limit = 20, page = 1, sort = null) => {
  return getData('search', '?' + queryString.stringify({
    tags,
    page,
    limit,
    aggs,
    type: types.join(','),
    q: query,
    r: Math.random(),
    sort: sort
  }));
};
export const related = (id, type = 'posts', limit = 20, pageNo = 1) => getData('related', `?id=${id}&page=${pageNo}&limit=${limit}&type=${type}&r=${Math.random()}`);

export const deleteUserPhoto = id => deleteData('deletePhoto', `/${id}`);

export const getForumTopics = (limit = 10, startKey) => {
  const id = startKey ? `start_key=${encodeURIComponent(startKey)}` : '';
  return getData('forumTopics', `?${id}&limit=${limit}`);
};
export const deleteForumTopic = id => deleteData('forumTopics', `/${encodeURIComponent(id)}`);

export const getForumSideTopics = (authorId, limit = 20) => {
  const id = authorId ? `author_id=${authorId}` : '';
  return getData('forumTopics', `?${id}&limit=${limit}`);
};

export const getForumMessages = id => getData('forumMessages', `/${id}`);
export const deleteForumMessage = id => deleteData('forumMessages', `/${encodeURIComponent(id)}`);

export const getHelpfulLinks = () => getData('helpfulLinks');

export const getUserGroups = () => getData('userGroups');
export const getUserGroup = (id) => getData('userGroup', `/${encodeURIComponent(id)}`);

export const getGroupConversationTopics = (spaceId) => getData('forumTopics', `?spaceId=${spaceId}&limit=1000`);
export const getGroupEvents = (spaceId) => getData('events', `?spaceId=${spaceId}`);
export const getGroupArticles = (limit = 10, startKey, spaceId) => {
    const id = startKey ? `start_key=${encodeURIComponent(startKey)}&` : '';
    return getData('posts', `?${id}limit=${limit}&spaceId=${spaceId}`);
};
export const getGroupHelpfulLinks = (spaceId) => getData('helpfulLinks', `?spaceId=${spaceId}`);
