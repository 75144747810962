import React, { useEffect, useState } from 'react';

import welcomePageContainer from '../../containers/welcomePageContainer';
import generalContentPage from "../../containers/generalContentPage";
import {Link} from "react-router-dom";
import Icon from "../../../Icon";
import { useMediaQuery } from '@material-ui/core';
import {
  getArticles,
  searchArticlesForCategory,
  searchEvents,
  searchByTags
} from "../../../utils/api";
import MetaTags from "../../../atoms/MetaTags";
import feedCategoryTagsMap from "../../../config/feed-category-tags-map";
import TopArticles from "../../Articles/TopArticles";
import Button from "../../../atoms/Button";
import BlockExperiencesSlider from "../../../atoms/BlockExperiencesSlider";
import ButtonViewMore from "../../../atoms/ButtonViewMore";
import BlockSignUp from "../../../atoms/BlockSignUp";
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import Flickity from "react-flickity-component";
import flickityConfig from "../../../config/flickity-config";



const Welcome = props => {
  const [articlesLoading, setArticlesLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    searchArticlesForCategory([], '', 25).then(data => {
      setArticles(data.hits.posts ? data.hits.posts.slice(0, 3) : []);
      setArticlesLoading(false);
    });
  }, [])

  const isMobile = useMediaQuery('(max-width: 1000px)');

  function scrollToSection() {
    const target = document.querySelector("#target-section");
    target.scrollIntoView({ behavior: "smooth" });
  }

  const tabsData = [
    {
      href: "/lifestyle",
      imgSrc: "/images/icon-lifestyle.svg",
      title: "Lifestyle",
      text: "From relationships to fitness",
      button: "Enrich and improve"
    },
    {
      href: "/experiences",
      imgSrc: "/images/icon-travel.svg",
      title: "Experiences",
      text: "For bucket list trips and life changing events",
      button: "Dream away"
    },
    {
      href: "/mastery",
      imgSrc: "/images/future-messages-icon.svg",
      title: "Mastery",
      text: "The rolling handbook for life",
      button: "Take a look"
    },
    {
      href: '/all-i-can-tell-you',
      imgSrc: "/images/future-messages-icon.svg",
      title: "All I Can Tell You",
      text: "Leave no words left unsaid",
      button: "Take a look"
    },
    {
      href: '/shop',
      imgSrc: "/images/icon-basket.svg",
      title: "Shop",
      text: "Your bespoke store",
      button: "Take a look"
    }
  ];

  const Tab = ({ href, imgSrc, title, text, button, className = "" }) => (
    <a
      href={href}
      className={`hover:cursor-pointer w-full sm:max-w-[390px] h-[437px] max-w-[310px] sm:p-14 p-10 rounded-[21px] bg-light-grey flex flex-col justify-between ${
        href === null ? "" : "hover:bg-yellow group"
      } ${className}`}
    >
      <div>
        <img className="h-[55px]" src={imgSrc} alt={title}/>
      </div>
      <div className="text-dark-navy">
        <h3 className="text-[32px] font-bold mb-4">{title}</h3>
        <span className="block mb-12 text-18 sm:text-20 min-h-[3em]">{text}</span>
        <div className="w-fit">
          <span className="block py-3 xl:py-[9px] px-6 rounded-md bg-yellow text-dark-navy group-hover:bg-dark-navy group-hover:text-white transition">
          {button}
          </span>
        </div>
      </div>
    </a>
  );

  return (
    <div className='welcome-page'>
      <MetaTags title="Welcome to Grandudes" pathname={props.location.pathname} />

      <div>
        <div className="text-white bg-dark-navy">
          <div className="flex justify-between container mx-auto p-pad-sm lg:flex-row flex-col lg:space-x-32">
            <div className="flex xl:items-center xl:flex-row flex-col">
              {/*<h3 className="text-30 font-bold">20% off</h3>*/}
              <span className="text-20 tracking-wide font-[300]">We are excited to be finalising the platform for young men over 50! Please note we are not fully operational yet but please sign up now for updates and special discounts.</span>
            </div>
            <div><button onClick={scrollToSection} className="text-dark-navy bg-white py-3 xl:py-[9px] px-6 rounded-md mt-7 xl:mt-0 whitespace-nowrap">Sign up now</button></div>
          </div>
        </div>
        <div
          className="bg-[url('../../public/images/photo-homebanner-new-mob.jpg')] sm:bg-[url('../../public/images/photo-homebanner.jpg')] bg-cover bg-right sm:bg-center relative">
          <div
            className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-gradient-to-b from-[transparent] to-dark-navy opacity-20 sm:hidden"></div>
          <div
            className="flex-col sm:h-[80vh] h-[700px] container p-pad-sm mx-auto flex sm:items-start sm:justify-center relative">
            <h1 className="font-bold font-heading text-dark-navy text-50 xl:text-90">
              <span className="block">your life,</span>
              <span className="block">your <strong
                className="text-yellow">legacy</strong></span>
            </h1>
            <div className="mt-8 sm:mt-14 max-w-[212px] sm:max-w-[440px]">
                <span className="text-20 text-dark-navy">Inspiring men over 50 to live a more fulfilled and connected Life.</span>
            </div>
            <button className="mt-16 sm:mt-24 text-16 bg-yellow py-3 xl:py-[9px] px-6 rounded-md w-fit">Join for free</button>
          </div>
          <div className="flex justify-end container">
            <img className="hidden lg:block absolute top-[50%] right-40" src="/images/white-arrow.svg"/>
          </div>
        </div>

        <div className="background-shapes-home">
          <div className="container mx-auto p-pad-md yellow-slider">
            <h2 className="text-dark-navy xl:text-50 text-36 max-w-[770px] font-bold">We’re here to empower men over 50 to enjoy life to the fullest</h2>
            {isMobile ? (
            <Flickity
              options={flickityConfig}
              className="slider yellow-flickity flickity-enabled pt-20 pb-[100px] sm:pb-[240px]"
            >
              {tabsData.map((tab, index) => (
                <Tab key={index} {...tab}/>
              ))}
            </Flickity>
            ) : (
            <div className="grid xl:grid-cols-3 grid-cols-2 gap-x-[63px] lg:gap-x-[30px] gap-y-[80px] pt-20 pb-[100px] sm:pb-[240px]">
              {tabsData.map((tab, index) => (
                <Tab key={index} {...tab}/>
              ))}
            </div>
            )}
          </div>
        </div>
        <div className="bg-light-grey yellow-slider">
            <div className="container mx-auto p-pad-sm yellow-flickity">
              <h2 className="text-dark-navy xl:text-50 text-30 max-w-[770px] font-bold xl:pt-[100px]">Recent articles</h2>
              <div className="overflow-hidden">
                <div className="container mx-auto py-[25px] xl:py-[70px]">
                  {articlesLoading && <LoadingSpinner size={20} />}
                  {!articlesLoading && <TopArticles items={articles} align="left" />}
                  <div className="mt-8 xl:mt-14 text-right">
                    <Link to={'/lifestyle'} component={ButtonViewMore} />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <BlockSignUp fullWidth={true} />
    </div>
  );
};

export default generalContentPage(Welcome);
