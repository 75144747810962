// import url from 'url';
import _ from 'lodash';

export const getJWT = () => {
  return localStorage.getItem('token');
};

export const getArticleCount = () => {
    return localStorage.getItem('article_count');
};

export const removeArticleCounter = () => {
    localStorage.removeItem('article_count')
};

export const setArticleCount = (value) => {
    localStorage.setItem('article_count', value)
};

/**
 * Get 'GET' params from window location
 */
export const getParamsFromGet = () => {
  const params = url.parse(window.location.href, true).query;
  return _.size(params) ? params : null;
};
