import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import Input from '../../../../atoms/form/Input';
import formValidator from '../../../../utils/form';

import authPage from '../../../containers/authPage';
import ErrorParser from '../../../../atoms/ErrorParser';
import MetaTags from "../../../../atoms/MetaTags";

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Required';
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = 'Password mismatched';
  }

  return errors;
};

class NewPassword extends Component {
  state = {
    token: '',
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    // check if there token in url
    if (token) {
      this.setState({ token });
    }
  }

  render() {
    const {
      sendNewPassword,
      auth: { success, error },
      pristine,
      submitting,
      handleSubmit,
      authClearData,
    } = this.props;

    const resetHandler = values => {
      const val = { ...values, token: this.state.token };
      // send New Password
      sendNewPassword(val);
    };

    return (
      <div className="auth-form auth-form--sign-up">
        <MetaTags title="New Password" pathname={this.props.location.pathname} />

        <form>
          <div className="auth-form__vertical-frame">
            <div className="auth-form__top">
              <div className="auth-form__title">New Password</div>
              {success && (
                <div className="auth-form__success-validation">
                  New password has been set, You can now <Link to={'/login'}>login</Link> to the application.
                </div>
              )}
              {error && <ErrorParser data={error} />}
              <Field
                title="Password"
                name="password"
                component={Input}
                type="password"
                validate={[formValidator.required]}
              />
              <Field
                title="Password Confirmation"
                name="passwordConfirmation"
                component={Input}
                type="password"
                validate={[formValidator.required]}
              />
            </div>
            <div>
              <button
                className="btn btn--primary"
                type="submit"
                onClick={handleSubmit(resetHandler)}
                disabled={submitting}
              >
                <span className="text-el">New Password</span>
              </button>
              <Link
                to={'/sign-up'}
                className="btn btn--secondary"
                onClick={() => {
                  authClearData();
                }}
              >
                Create an account
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const form = reduxForm({
  form: 'newPassword', // a unique identifier for this form
  validate,
})(NewPassword);

export default authPage(form);
