import { FOLLOWED_UPDATE, FOLLOWED_LOADING } from '../actions/followedAction';

const initialState = {
  isLoading: false,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FOLLOWED_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FOLLOWED_UPDATE:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    default:
      return state;
  }
};
