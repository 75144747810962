import React, { Fragment } from 'react';

export default React.forwardRef(({ navigate, onClick, colour = '', classes = '', children = 'View more', ...props }, ref) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (navigate) {
      navigate(e);
    }
  };

  return (
    <button ref={ref} className={"cursor-pointer text-16 " + classes} href="#" onClick={handleClick} {...props}>
      <img className="inline-block mr-2 align-top" src="/images/icon-plus.svg" alt="Plus" />
      {children}
    </button>
  );
})
