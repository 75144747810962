import React, {createContext, useEffect, useState} from 'react'
import {getShipping, relatedProducts} from "../utils/api";

export const BasketContext = createContext(undefined)

export const BasketProvider = ({ children }) => {
  let defaultCoupon = {
    code: '',
    percent: 0,
    products: [],
  };

  let initialBasket = {
    items: {},
    shipping: null,
    coupon: defaultCoupon,
  };

  const [shippingOptions, setShippingOptions] = useState({});
  const [availableShippingOptions, setAvailableShippingOptions] = useState([]);
  const [basket, setBasket] = useState(() => {
    const savedBasket = JSON.parse(localStorage.getItem("basket"));

    return savedBasket || initialBasket;
  })
  const [order, setOrder] = useState({});
  const items = basket.items;
  const shipping = basket.shipping;
  const coupon = basket.coupon ?? defaultCoupon;

  useEffect(() => {
    getShipping().then((data) => {
      setShippingOptions(Object.values(data).reduce((obj, item) => {
        obj[item.id] = {
          ...item,
          price: parseFloat(item.price),
        };
        return obj;
      }, {}));
    });
  }, []);

  useEffect(() => {
    if (Object.keys(shippingOptions).length > 0) {
      setBasket(basket => {
        let availableShipping = calculateShipping(basket.items);

        setAvailableShippingOptions(availableShipping);

        return ({
          ...basket,
          shipping: availableShipping.includes(basket.shipping?.id) ? basket.shipping : shippingOptions[availableShipping[0]] ?? null,
        });
      });
    }
  }, [shippingOptions, basket.items]);

  useEffect(() => {
    // storing input name
    localStorage.setItem("basket", JSON.stringify(basket));
  }, [basket]);

  const calculateShipping = (items) => {
    // Filter items to return those with the same .shipping in common
    const allItems = Object.values(items)
    const physicalItems = allItems.filter(item => !item.is_virtual);
    const shippingGroups = allItems.reduce((groups, item) => {
      (item.shipping ?? []).forEach(shipping => {
        shipping = parseInt(shipping, 10);

        if (!shippingOptions[shipping]) {
          console.error('Invalid shipping option: ' + shipping);
          return;
        }

        groups[shipping] = groups[shipping] || [];
        groups[shipping].push(item);
      });
      return groups;
    }, {});

    // Find the shipping option that all items share
    const applicable = Object.keys(shippingGroups).filter(group => {
      return shippingGroups[group].length >= physicalItems.length;
    });

    return applicable
      .sort((a, b) => parseFloat(shippingOptions[parseInt(a)].price) - parseFloat(shippingOptions[parseInt(b)].price))
      .map(id => parseInt(id));
  }

  function itemIsDiscounted(id, coupon) {
    return !!coupon?.percent && (coupon.products.length === 0 || coupon.products.includes(id));
  }

  let calculateSubTotal = (includeCoupon = false) => {
    return Object.keys(items).reduce((acc, key) => {
      let keyInt = parseInt(key, 10);
      const item = items[keyInt];
      let price = parseFloat(item.price);
      price += (item.variations || []).reduce((a, b) => a + parseFloat(b.price), 0);

      if (includeCoupon && itemIsDiscounted(keyInt, coupon)) {
        price = price - (price * (coupon.percent / 100));
      }

      return acc + ((item.qty || 0) * price);
    }, 0)
  };

  let resetBasket = () => {
    localStorage.removeItem("basket");
    setBasket(initialBasket);
  };

  let convertToOrder = () => {
    const order = {
      items: JSON.parse(JSON.stringify(items)),
      shipping: { ...shipping },
      coupon: coupon ? { ...coupon } : {},
      subTotal: calculateSubTotal(),
      total: calculateTotal(),
    };

    setOrder(order);
    resetBasket();
  };

  const addCoupon = (code, percent, products) => {
    setBasket((prev) => ({
      ...prev,
      coupon: {
        code,
        percent,
        products,
      }
    }))
  }

  const removeCoupon = () => {
    setBasket((prev) => ({
      ...prev,
      coupon: defaultCoupon,
    }))
  }

  let calculateTotal = () => {
    return calculateSubTotal(true) + (shipping ? parseFloat(shipping.price) : 0);
  };

  let removeItem = (productId) => {
    delete items[productId];

    if (Object.keys(items).length === 0) {
      resetBasket();
    }
    else {
      setBasket({
        ...basket,
        items,
      });
    }
  };

  let updateItem = (productId, qty) => {
    qty = parseInt(qty, 10)
    const newBasket = {
      ...basket,
      items: {
        ...items,
      }
    }

    if (qty === 0) {
      delete newBasket.items[productId];
    } else {
      newBasket.items[productId].qty = qty;
    }

    if (Object.keys(newBasket.items).length === 0) {
      resetBasket();
    }
    else {
      setBasket(newBasket);
    }
  };

  let addItem = (productId, name, thumbUrl, price, relatedProducts = [], variations = [], shipping, is_virtual) => {
    setBasket(basket => {
      return ({
        ...basket,
        items: {
          ...items,
          [productId]: {
            qty: (items[productId] ? items[productId].qty : 0) + 1,
            name,
            thumbUrl,
            price: parseFloat(price),
            relatedProducts: relatedProducts,
            variations: variations.map(variation => ({
              id: variation.id,
              name: variation.name,
              price: parseFloat(variation.price),
            })),
            shipping,
            is_virtual: is_virtual
          }
        },
      });
    });
  }

  const sortRelatedProductsByDuplicates = (arr) => {
    const frequencyMap = {};
    arr.forEach(item => {
      frequencyMap[item] = (frequencyMap[item] || 0) + 1;
    });
    return arr.slice().sort((a, b) => frequencyMap[b] - frequencyMap[a]);
  };
  
  const getRelatedProducts = () => {
    let related = Object.values(items).length ? [...new Set(sortRelatedProductsByDuplicates(Object.values(items).map(item => item.relatedProducts).flat()))] : []
    if(related.length) {
      related = related.filter((product_id) => !Object.keys(items).includes(product_id))
    }
    return related
  }

  return (
    <BasketContext.Provider
      value={{
        basket,
        items,
        shipping,
        order,
        numItems: Object.values(items).reduce((a, b) => a + b.qty, 0),
        addItem: addItem,
        updateItem: updateItem,
        setShipping: (shippingId, name, price) => setBasket(basket => ({
          ...basket,
          shipping: {
            id: parseInt(shippingId, 10),
            name,
            price: parseFloat(price),
          }
        })),
        availableShippingOptions,
        shippingOptions,
        removeItem: removeItem,
        calculateSubTotal,
        calculateTotal: calculateTotal,
        resetBasket: resetBasket,
        convertToOrder: convertToOrder,
        addCoupon: addCoupon,
        removeCoupon: removeCoupon,
        coupon,
        itemIsDiscounted,
        resetOrder: () => setOrder({}),
        // ...new SET() removes the duplicates once we have the array ordered by duplicates
        relatedProducts: getRelatedProducts()
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}
