import React, {useEffect, useRef, useState} from 'react';

import MetaTags from "../../../atoms/MetaTags";
import generalContentPage from "../../containers/generalContentPage";
import Button from "../../../atoms/Button";
import NextArrow from "../../../atoms/NextArrow";
import {Link} from "react-router-dom";
import PageBlockBanner from '../../PageBlocks/Banner';
import PageBlockHangingImageAndText from '../../PageBlocks/HangingImageAndText';
import PageBlockIconAndText from '../../PageBlocks/IconAndText';
import PageBlockPackagesGrid from '../../PageBlocks/PackagesGrid';
import BlockSignUp from '../../../atoms/BlockSignUp';
import {getPackages} from "../../../utils/api";
import LoadingSpinner from "../../../atoms/LoadingSpinner";
import NeedHelp from "../../../atoms/NeedHelp";

const list = [
  "You may wish to compose letters to be sent now or in the near future",
  "Perhaps you want to leave messages for when you are no longer here",
  "Some conversations can be difficult so a thoughtful letter diffuses emotion",
  "You could want your words to be re-read and considered at leisure",
  "Maybe you don’t want someone to judge you without hearing your version",
  "It might be that you just want someone to know how much you love them",
  "Perhaps you have memoirs or life lessons to pass on",
  "You have possibly been asked to write and deliver a eulogy",
];

const FutureMessages = (props) => {
  const packages = useRef();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ products, setProducts ] = useState(undefined);

  useEffect(() => {
    getPackages('Legacy Letters').then(data => {
      setProducts(data);
      setIsLoading(false);
    });
  }, []);

  const handleDiscoverPackages = (e) => {
    e.preventDefault();
    packages.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <>
      <MetaTags title="All I can tell you" pathname={props.location.pathname} />

      <PageBlockBanner title={'All I can tell you'} icon_color='yellow' icon_path={'/images/future-messages-icon.svg'}>
        <div className="mb-10">
            <strong className="block text-40 lg:text-60 font-heading font-bold max-w-[500px]">
              Our aim is to ensure that you <span className='text-yellow'>say</span> what you want, to whom you want, and when.
            </strong>
        </div>
        <p className='max-w-[450px]'>We provide purpose made aides and materials to help you create letters, messages and information, whatever the situation or occasion. There is also the option of having a skilled writer perfect the wording for you.</p>
        <a className="bg-yellow text-black rounded-md py-2 px-4 inline-flex items-center text-16 mt-20 cursor-pointer" onClick={handleDiscoverPackages}>
          Discover packages
          <span className="ml-6">
            <svg width="8" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12.6665L6.66667 6.99984L1 1.33317" stroke="#23323F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </span>
        </a>
      </PageBlockBanner>
      <PageBlockHangingImageAndText img_src='/images/all-i-can-tell-you.jpeg' image_side='left'>
            <h2 className="mb-8">
              <strong className="block leading-[14px] lg:text-[100px] font-heading font-bold">“</strong>
              <figure>
                <blockquote className="block text-40 lg:text-60 font-heading font-bold">
                  Carve your name on hearts, not tombstones. A <span className="text-yellow">legacy</span> is etched into the minds of others and the stories they share about you
                </blockquote>
                <figcaption className="mt-4 text-18">Shannon Alder</figcaption>
              </figure>
            </h2>
            <p className="mb-6">Now, let’s be very clear: none of us, in this tribe of men embracing life, is thinking about keeling over anytime soon. Our priority is to make the most of life now. However, life throws us curve balls, and in many relationships important words are left unsaid.</p>
      </PageBlockHangingImageAndText>

      <section className='container max-w-[1200px] xl:mt-[100px] lg:-mt-[450px] px-pad-sm mx-auto lg:grid grid-cols-2 items-center justify-end mb-20 lg:mb-28 xl:mb-10 xl:gap-32 lg:gap-20'>
        <div className='col-span-1'></div>
        <div className='col-span-1 col-start-2'>
          <ul className='list-disc'>
            {list.map((item, index) => (
              <li key={index} className='md:mb-4 mb-3'>{item}</li>
            ))}
          </ul>
        </div>
      </section>

      <PageBlockIconAndText icon_src={'/images/future-messages-icon.svg'}>
        <p className="mb-6 max-w-[450px]">Whatever the reason, our hard copy and electronic materials, enable you to create perfectly composed words.</p>
      </PageBlockIconAndText>

      <PageBlockHangingImageAndText img_src='/images/man-writing.png' image_side='right'>
            <h2 className="font-heading text-dark-navy lg:text-50 text-36 font-bold mb-6">Many people never put pen to paper or start to type a <span className='text-yellow'>letter</span></h2>
            <p className="lg:text-22 text-18 mb-6">because they don’t know where to start and quite what to say. We have you covered.</p>
            <p className="mb-6">Our digital guidebook helps you to get started, provides a host of useful expressions and phrases, and illustrates how to conclude, so that you can compose your own perfect letters. We provide 60 examples of letters for different occasions and for different recipients. We have beautiful purpose designed folders and paper to write your letters on, and to keep them in a safe place, until the time they are to be presented and read.</p>
            <p>You also have the bespoke option of engaging a talented writer to help you to compose the content for your letters, messages, memoirs, lessons or eulogy (your own in advance, or for others)</p>
      </PageBlockHangingImageAndText>

      <div ref={packages}>
        {isLoading && (<LoadingSpinner />)}
        {!isLoading && (<PageBlockPackagesGrid products={products} route="/all-i-can-tell-you" className='xl:mt-16 lg:-mt-[350px] -mt-[100px]' bg_color='white' title={<>We have 3 packages to ensure no important words are left <span className='text-yellow'>unspoken</span></>}/>)}
      </div>

      <NeedHelp />
    </>
  );
};
export default generalContentPage(FutureMessages);
