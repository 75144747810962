import { UPDATE_MENU } from '../actions/menuAction';

export const getInitMenu = (id = null) => {
  return [
    {
      name: 'Lifestyle',
      url: '/lifestyle',
      alias: 'lifestyle',
    },
    {
      name: 'Experiences',
      url: '/experiences',
      alias: 'experiences',
    },
    {
      name: 'Mastery',
      url: '/mastery',
      alias: 'mastery',
    },
    {
      name: 'All I can tell you',
      url: '/all-i-can-tell-you',
      alias: 'all i can tell you',
    },
    ,
    {
      name: 'About',
      url: '/about-us',
      alias: 'about',
    },
    {
      name: 'Shop',
      url: '/shop',
      alias: 'shop',
    },
  ];
};

function menu(state = getInitMenu(), action) {
  switch (action.type) {
    case UPDATE_MENU:
      return [...getInitMenu(action.payload)];
    default:
      return state;
  }
}

export default menu;
