import React, {useContext, useEffect, useRef, useState} from "react";
import MetaTags from "../../../atoms/MetaTags";
import BlockSignUp from "../../../atoms/BlockSignUp";
import generalContentPage from "../../containers/generalContentPage";
import PageBlockPackagesGrid from "../../PageBlocks/PackagesGrid";
import LoadingSpinner from "../../../atoms/LoadingSpinner";
import {getPackages, getProduct} from "../../../utils/api";
import { NotFound } from "../NotFound";
import ProductCarousel from "../../ProductCarousel";
import NeedHelp from "../../../atoms/NeedHelp";
import {Link, useHistory} from "react-router-dom";
import {BasketContext} from "../../../providers/BasketProvider";
import DisplayProductsByIDs from "../../Product/DisplayProductsByIDs";


function Product(props) {
    const { addItem } = useContext(BasketContext);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ added, setAdded ] = useState(false);
    const [ product, setProduct ] = useState(undefined);
    const history = useHistory();

    useEffect(() => {
      getProduct(props.match.params.slug).then(product => {
        setProduct(product);
      }).finally(_ => {
        setIsLoading(false);
      });
    }, [props.match.params.slug])

    let handleAdd = e => {
      e.preventDefault();
      addItem(product.id, product.name, product.images[0]['thumbUrl'], product.price, product.relatedProducts, [], product.shipping, product.is_virtual);
      setAdded(true);

      setTimeout(() => {
        history.push("/basket");
      }, 500);
    };

    if (!isLoading && !product) {
      return <NotFound />;
    }

    return (
        <>
          {/* <MetaTags title="Mastery Package" pathname={props.location.pathname}/> */}

          <div className="overflow-hidden lg:pb-20 mb-20">
            <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
               {isLoading && (<LoadingSpinner />)}
               {!isLoading && product && (<>
                <div className="grid gap-y-6 md:grid-cols-2 md:gap-x-20 xl:gap-x-60">
                  <div className="hidden md:block">
                    <Link to={'/shop'} className="text-dark-grey underline hover:no-underline uppercase">Back</Link>
                  </div>
                  <div className="flex items-center mb-8 md:col-span-1 col-span-2">
                    <div className="uppercase text-14">
                      {product.category.join(', ')}
                    </div>
                  </div>
                  <div className="relative w-full lg:max-w-none md:max-w-[400px] lg:max-h-none xs:max-h-[500px] md:col-span-1 col-span-2 sm:mb-20 md:mb-0 overflow-hidden">
                      <ProductCarousel images={product.images} />
                  </div>
                  <div className="h-full flex flex-col md:col-span-1 col-span-2">
                    <div className="mb-10">
                      <strong className="block text-40 lg:text-56 font-heading font-bold max-w-sm">{product.name}</strong>
                    </div>
                    <div className="mb-8">
                      <strong className="block text-20 lg:text-30 font-heading font-bold max-w-md">&pound;{product.price}</strong>
                    </div>
                    <p className="text-16 max-w-md wysiwyg" dangerouslySetInnerHTML={{__html: product.description }}></p>
                    <p className="lg:mt-12 mt-4">
                      {added === false && (
                        <a className="w-full md:w-auto bg-yellow rounded-lg text-center py-3 px-4 mt-12 text-18 inline-block text-dark-navy cursor-pointer" onClick={handleAdd}>Add to basket</a>
                      )}
                      {added === true && (
                        <a className="w-full md:w-auto bg-yellow rounded-lg text-center py-3 px-4 mt-12 text-18 inline-block text-dark-navy">Added to basket</a>
                      )}
                    </p>
                  </div>
                </div>
               </>)}
                {
                  product && product.hasOwnProperty('relatedProducts') && product.relatedProducts !== null  && product.relatedProducts.length && (
                    <>
                      <div className="pt-40 lg:text-22 text-18 font-bold">
                        <h4>Related Products</h4>
                        <DisplayProductsByIDs ids={product.relatedProducts} className="pb-0 pt-10" />
                      </div>
                    </>
                  )
                }
            </div>
          </div>
          <div className="mt-32 lg:mt-32">
            <NeedHelp text={<>A real person will assist with any query. We can also allocate a personal shopper to help with your selection and experience.</>} />
          </div>
        </>
      );
}

export default generalContentPage(Product)

