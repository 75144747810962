import React, {useState, useEffect, Component, Fragment, useRef} from 'react';

import {
  getEvents, getTaxonomy,
  searchProducts
} from "../../../utils/api";

import MetaTags from "../../../atoms/MetaTags";
import LoadingSpinner from '../../../atoms/LoadingSpinner';
import { Link } from 'react-router-dom';
import ButtonViewMore from "../../../atoms/ButtonViewMore";
import Button from "../../../atoms/Button";
import generalContentPage from "../../containers/generalContentPage";
import queryString from "qs";
import {DropdownFilter} from "../../../atoms/DropdownFilter";
import CardProduct from "../../../atoms/CardProduct";
import Flickity from "react-flickity-component";
import flickityConfig from "../../../config/flickity-config";
import NeedHelp from "../../../atoms/NeedHelp";

let hideCategories = ['Legacy Letters', 'Mastery handbook for men'];

const FilterPanel = ({ products, queryParams }) => {
  const [ showDropdown, setShowDropdown ] = useState(undefined);

  const category = queryParams['category'];
  const price = queryParams['price'];

  const [ taxonomies, setTaxonomies ] = useState({
    'categories': { values: [] },
    'price-bands': { values: [] },
  });

  useEffect(() => {
    getTaxonomy(['categories', 'price-bands']).then(tax => {
      setTaxonomies({ ...tax, categories: {
          ...tax.categories,
          values: tax.categories.values.filter(v => !hideCategories.includes(v))
        }
      });
    });
  }, [])

  const categories = taxonomies['categories'] ? taxonomies['categories'].values : [];
  const prices = taxonomies['price-bands'] ? taxonomies['price-bands'].values : [];

  return (
    <>
      <div className="hidden sm:block">
        Filter by
      </div>
      <div className="mx-0 sm:space-x-4 space-y-4 sm:space-y-0 flex-1 flex-col flex w-full sm:flex-row">
        <span className="inline-block">
            <Link to="/shop" component={Button} classes="hidden sm:inline" colour={(!category && !price) ? 'bg-yellow' : 'bg-dark-grey text-white'}>
              All
            </Link>
        </span>
        <DropdownFilter name="Category" showDropdown={showDropdown} setShowDropdown={setShowDropdown} url="/shop" param="category[]" queryParams={queryParams} slug={category} options={categories} />
        <DropdownFilter name="Price" showDropdown={showDropdown} setShowDropdown={setShowDropdown} url="/shop" param="price[]" queryParams={queryParams} slug={price} options={prices} />
      </div>
    </>
  )
}
const SortFilter = ({ queryParams }) => {
  const [ showDropdown, setShowDropdown ] = useState(undefined);

  const sort = queryParams['sort'];

  const options = [
    'Price: Low to High',
    'Price: High to Low',
    'Newest',
    'Oldest',
  ];

  return (
    <div className="mx-0 sm:space-x-4 space-y-4 sm:space-y-0 flex-1 flex-col flex w-full sm:flex-row justify-end">
      <DropdownFilter
        name="Sort"
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        url="/shop"
        param="sort"
        queryParams={queryParams}
        slug={sort}
        options={options}
        defaultClassName="border border-dark-navy"
        darkArrow={true}
        align="right"
        allText={'Default'}
      />
    </div>
  )
}

const Events = ({ location, perPage = 12 }) => {
  const [products, setProducts] = useState([]);
  const [ search, setSearch ] = useState('');
  const [ loading, setLoading ] = useState(undefined);
  const [ hasMore, setHasMore ] = useState(false);
  const [ page, setPage ] = useState(1);
  const productsRef = useRef(null)
  const queryParams = queryString.parse(location.search.replace('?', ''));

  const fetchProducts = (page) => {
    let sort;

    if (queryParams['sort'] === 'Newest') {
      sort = {
        'created_at': 'desc',
      }
    }
    else if (queryParams['sort'] === 'Oldest') {
      sort = {
        'created_at': 'asc',
      }
    }
    else if (queryParams['sort'] === 'Price: Low to High') {
      sort = {
        'price': 'asc',
      }
    }
    else if (queryParams['sort'] === 'Price: High to Low') {
      sort = {
        'price': 'desc',
      }
    }

    return searchProducts(undefined, search, perPage, page, sort).then(data => {
      setLoading(false);
      setHasMore(data.total.value > (perPage * page));

      return data;
    });
  }

  useEffect(() => {
    setLoading('search');

    fetchProducts(1).then(data => {
      setProducts(data.hits.products ? data.hits.products : []);
      setPage(1);
    });
  }, [location.search, search])

  // New page - concat
  useEffect(() => {
    setLoading('page');

    if (page > 1) {
      fetchProducts(page).then(data => {
        setProducts(products => [...products, ...(data.hits.products ? data.hits.products : [])]);
      })
    }
  }, [page]);

  const filteredProducts = products.filter(product => {
    if (product.category.some(v => hideCategories.includes(v))) {
      return false;
    }

    if (queryParams['category'] && (!Array.isArray(product.category) || !product.category.some(v => queryParams['category'].includes(v)))) {
      return false;
    }

    if (queryParams['price'] && !queryParams['price'].some(band => {
      const matches = band.match(/£([0-9]+)[^£]*(?:£([0-9]+))?/);

      return parseFloat(product.price) >= parseFloat(matches[1]) && (!matches[2] || parseFloat(product.price) <= parseFloat(matches[2]));
    })) {
      return false;
    }

    return true;
  });

  const scrollToProducts = (e) => {
    e.preventDefault();
    productsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <>
      <MetaTags title="Shop" pathname={location.pathname} />

      <div className="pb-[80px] xl:pb-[120px]">
        <div className="bg-dark-navy">
          <div className="container px-pad-sm mx-auto py-[60px] lg:py-[100px]">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div className="relative lg:pr-9">
                <h2 className="font-heading font-bold text-30 lg:text-50 max-w-[650px] text-white">
                  Shop
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-pad-sm mx-auto" ref={productsRef}>
          {/* FILTER BAR */}
          <div className="flex flex-col sm:flex-row items-start space-y-4 lg:space-y-0 sm:space-x-5 xl:space-x-10 xl:items-center justify-between py-8 xl:py-14 relative">
            <FilterPanel products={products} queryParams={queryParams} />
            <SortFilter queryParams={queryParams} />
          </div>
          <div className="grid gap-x-10 gap-y-20 md:grid-cols-3 xl:grid-cols-4">
            {!loading && filteredProducts.length === 0 && <h3 className="text-10 text-dark-navy italic">Sorry, no results were found for your search</h3>}
            {filteredProducts.length > 0 && filteredProducts.map(product => (
              <CardProduct 
                image={product.images.length ? product.images[0]['thumbUrl']: undefined} 
                key={product.id} 
                id={product.id} 
                price={product.price} 
                text={product.name} 
                shipping={product.shipping} 
                textColour={'text-black'} 
                slug={product.slug} 
                is_virtual={product.is_virtual} 
                relatedProducts={product.relatedProducts}
              />
            ))}
          </div>
          <div className="mt-8 xl:mt-14 text-right">
            {loading === 'page' && <LoadingSpinner size={8} />}

            {!loading && hasMore && <ButtonViewMore onClick={(e) => {
              e.preventDefault();
              setPage(page => page + 1);
            }} />}
          </div>
        </div>
      </div>
      <NeedHelp text={<>A real person will assist with any query. We can also allocate a personal shopper to help with your selection and experience.</>} />
    </>
  );
}

export default generalContentPage(
  Events
);
