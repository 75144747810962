import React, {useContext, useEffect, useRef, useState} from "react";
import MetaTags from "../../../atoms/MetaTags";
import BlockSignUp from "../../../atoms/BlockSignUp";
import generalContentPage from "../../containers/generalContentPage";
import PageBlockPackagesGrid from "../../PageBlocks/PackagesGrid";
import LoadingSpinner from "../../../atoms/LoadingSpinner";
import {getPackages, getVariations} from "../../../utils/api";
import { NotFound } from "../NotFound";
import ProductCarousel from "../../ProductCarousel";
import EnquiryModal from "./EnquiryModal";
import NeedHelp from "../../../atoms/NeedHelp";
import {Link, useHistory} from "react-router-dom";
import {BasketContext} from "../../../providers/BasketProvider";


function VariationDropDown({ name, handleAddVariation, variation }) {
  const [ selected, setSelected ] = useState(undefined);

  useEffect(() => {
    setSelected(undefined);
  }, [variation]);

  const handleSelect = e => {
    setSelected(e.target.value);
    handleAddVariation(e);
  }

  return (
    <select
      className="hover:cursor-pointer border border-dark-navy mr-6 py-3.5 px-5 w-auto rounded-[8px]"
      name={name}
      onChange={handleSelect}
    >
      <option value="" selected={selected === undefined}>Add {name}</option>
      {variation.map((v, i) => {
        return (
          <option key={i} value={v.id}>{v.label} (£{v.price})</option>
        );
      })}
    </select>
  )
}

function AllICanTellYouPackage(props) {
    const { addItem } = useContext(BasketContext);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ added, setAdded ] = useState(false);
    const [ products, setProducts ] = useState(undefined);
    const [ product, setProduct ] = useState(undefined);
    const slug = props.match.params.package;
    const [showForm, setShowForm] = useState(false);
    const history = useHistory();
    const [ variations, setVariations ] = useState([]);
    const [ variationIds, setVariationIds ] = useState({});

    const isEnquiryOnly = product && product.slug === 'gold-package' ? true : false;

    useEffect(() => {
      if (product?.id) {
        getVariations(product.id).then(data => {
          setVariations(data.allVariations);
          setVariationIds({});
        });
      }
    }, [product]);

    useEffect(() => {
      getPackages('Legacy Letters').then(data => {
        setProducts(data);
        const index = data.findIndex(p => p.slug === slug);
        if (data[index]) {
          setProduct(data[index]);
        }
        setIsLoading(false);
      });
    }, [slug]);

    const handleShowForm = (e) => {
      e.preventDefault();
      window.scrollTo(0, 120);
      setShowForm(true);
    }

    const handleHideForm = (e) => {
      e.preventDefault();
      setShowForm(false);
    }

    let handleAddVariation = e => {
      e.preventDefault();
      const index = e.target.name;
      const value = parseInt(e.target.value, 10);
      const newVariationIds = {...variationIds};

      if (value === '') {
        delete newVariationIds[index];
      }
      else {
        newVariationIds[index] = value;
      }

      setVariationIds(newVariationIds);
    }

    let handleAdd = e => {
      e.preventDefault();
      const variationData = Object.entries(variationIds).map(([group, id]) => {
        const variation = variations[group].find(v => v.id === id);
        return {
          id: variation.id,
          name: variation.label,
          price: variation.price,
        };
      });

      addItem(product.id, product.name, product.images[0]['thumbUrl'], product.price, product.relatedProducts, variationData, product.shipping, product.is_virtual);
      setAdded(true);

      setTimeout(() => {
        history.push("/basket");
      }, 500);
    };

    if (!isLoading && !product) {
      return <NotFound />;
    }

    return (
        <>
          {/* <MetaTags title="Mastery Package" pathname={props.location.pathname}/> */}

          <div className="overflow-hidden lg:pb-20 mb-20">
            <div className="container px-pad-sm mx-auto relative z-10 pt-8 xl:pt-14 pb-[50px]">
               {isLoading && (<LoadingSpinner />)}
               {!isLoading && product && (<>
                <div className="grid gap-y-6 md:grid-cols-2 md:gap-x-20 xl:gap-x-60">
                  <div className="hidden md:block">
                    <Link to={'/all-i-can-tell-you'} className="text-dark-grey underline cursor-pointer hover:no-underline uppercase">Back</Link>
                  </div>
                  <div className="flex items-center mb-8 md:col-span-1 col-span-2">
                    <div
                      className="rounded-full bg-white h-12 aspect-square flex items-center justify-center mr-4">
                      <img className="inline-block h-8" src="/images/mastery-icon.svg" alt="Mastery"/>
                    </div>
                    <div className="uppercase text-14">
                      All I can tell you
                    </div>
                  </div>
                  <div className="relative w-full lg:max-w-none md:max-w-[400px] lg:max-h-none xs:max-h-[500px] md:col-span-1 col-span-2 sm:mb-20 md:mb-0 overflow-hidden">
                      <ProductCarousel images={product.images} />
                  </div>
                  <div className="h-full flex flex-col md:col-span-1 col-span-2">
                    <div className="mb-10">
                      <strong className="block text-40 lg:text-56 font-heading font-bold max-w-sm">{product.name}</strong>
                    </div>
                    <div className="mb-8">
                      <strong className="block text-20 lg:text-30 font-heading font-bold max-w-md">&pound;{product.price}</strong>
                    </div>
                    <p className="text-16 max-w-md mastery-list mb-12" dangerouslySetInnerHTML={{__html: product.description }}></p>

                    {variations && (
                      <div className="mt-4 space-y-3">
                        {Object.entries(variations).map(([key, variation]) => {
                          return <VariationDropDown key={key} name={key} handleAddVariation={handleAddVariation} variation={variation} />
                        })}
                      </div>
                    )}
                    <span className="mt-4">
                      {!isEnquiryOnly && added === false && (
                        <a className="w-full md:w-auto bg-yellow rounded-lg text-center py-3 px-4 text-18 inline-block text-dark-navy cursor-pointer" onClick={handleAdd}>Buy package</a>
                      )}
                      {!isEnquiryOnly && added === true && (
                        <a className="w-full md:w-auto bg-yellow rounded-lg text-center py-3 px-4 text-18 inline-block text-dark-navy">Added to basket</a>
                      )}
                      {isEnquiryOnly && (
                        <button className="w-full md:w-auto bg-yellow rounded-lg text-center py-2 px-3 text-16 inline-block text-dark-navy cursor-pointer" onClick={handleShowForm}>Fill out enquiry form</button>
                      )}
                    </span>
                  </div>
                </div>
               </>)}
            </div>
          </div>
          <div className="mt-0">
            {isLoading && (<LoadingSpinner />)}
            {!isLoading && (<PageBlockPackagesGrid products={products} current_id={product.id} route="/all-i-can-tell-you" bg_color='white' padding={false} />)}
          </div>
          <div className="mt-32 lg:mt-32">
            <NeedHelp />
          </div>
          {showForm && <EnquiryModal title={`${product.name} enquiry form`} productName={product.name} handleHideForm={handleHideForm} />}
        </>
      );
}

export default generalContentPage(AllICanTellYouPackage)

