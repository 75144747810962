import React, { Fragment } from 'react';

export default React.forwardRef(({ navigate, onClick, active = false, children = 'View more', ...props }, ref) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (navigate) {
      navigate(e);
    }
  };

  return (
    <button ref={ref} className="text-14 flex items-center space-x-2 py-1 pl-3 pr-4 cursor-pointer w-full" onClick={handleClick} {...props}>
      <div>
        <svg width="12" height="12" viewBox="0 0 12 12"
             className={`${active ? "fill-dark-navy" : "fill-transparent"} stroke-white`}
             xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="5.5"/>
        </svg>
      </div>
      <div className={active ? "semibold" : ""}>
        {children}
      </div>
    </button>
  );
})
