import {Link} from "react-router-dom";
import ButtonFilter from "./ButtonFilter";
import queryString from "qs";
import React, {useRef, useState} from "react";
import useClickOutside from 'use-click-outside';

export function DropdownFilter({
  name,
  param,
  slug,
  url,
  options,
  queryParams,
  defaultClassName = 'bg-dark-grey text-white',
  selectedClassName = 'bg-yellow text-dark-navy',
  darkArrow = false,
  allText = 'All',
  align = 'left',
}) {
  const [ showDropdown, setShowDropdown ] = useState(undefined);
  const ref = useRef();

  useClickOutside(ref, () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  const isSelected = (value) => {
    if (Array.isArray(slug)) {
      return slug.includes(value);
    }

    return slug === value;
  }

  const selected = options.filter(isSelected);
  let cleanParam = param.indexOf('[]') !== -1 ? param.substring(0, param.length - 2) : param;

  let allParams = JSON.parse(JSON.stringify(queryParams));
  delete allParams[cleanParam];

  return <div className="relative inline-block" ref={ref}>
    <a className={`rounded-md py-2 px-4 flex items-center ${selected.length ? selectedClassName : defaultClassName}`}
       href="#" onClick={(e) => {
      e.preventDefault();
      setShowDropdown(!showDropdown);
    }}>
      {selected.length ? selected.join(', ') : name}
      <img className="ml-2" src={`${selected.length || (!selected.length && darkArrow)? '/images/icon-chevron-down-active.svg' : '/images/icon-chevron-down.svg' }`} alt="Down"/>
    </a>
    <div className={`md:absolute whitespace-nowrap pt-2 ${align === 'left' ? 'left-0' : 'right-0'} z-20 py-2 ` + (showDropdown ? '' : 'hidden')}>
      <div className="bg-yellow">
        <Link to={`${url}?${queryString.stringify(allParams)}`} onClick={_ => setShowDropdown(false)} component={ButtonFilter} active={!slug}>{allText}</Link>
        {options.map(value => {
          let params = JSON.parse(JSON.stringify(queryParams));

          if (param.substring(param.length - 2) === '[]') {
            if (!Array.isArray(params[cleanParam])) {
              params[cleanParam] = [];
            }

            if (isSelected(value)) {
              let idx = params[cleanParam].indexOf(value);
              delete params[cleanParam][idx];
            } else {
              params[cleanParam].push(value);
            }
          }
          else {
            if (isSelected(value)) {
              delete params[param]
            } else {
              params[param] = value;
            }
          }

          return (
            <Link key={value}
                  to={`${url}?${queryString.stringify(params)}`}
                  component={ButtonFilter} active={isSelected(value)}>{value}</Link>
          );
        })}
      </div>
    </div>
  </div>;
}
