import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loaderSet } from '../../actions/loaderAction';

const Search = props => {
  const [search, setSearch] = useState('');
  const onSubmit = e => {
    e.preventDefault();
    if (search.trim().length) {
      props.setLoader();
      props.history.push(`/search?text=${search}`);
      window.location.reload()
    }
  };
  return (
    <div className="search">
      <form onSubmit={onSubmit}>
        <input
          type="text"
          onChange={e => {
            setSearch(e.target.value);
          }}
          className="search__input"
          placeholder="Search"
          defaultValue={props.location.pathname === '/search' ? new URLSearchParams(window.location.search).get('text') : ''}
        />
        <button type="submit" className="search__btn">
          <i className="fas fa-search" />
        </button>
      </form>
    </div>
  );
};

Search.propTypes = {
  history: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  setLoader: bool => dispatch(loaderSet(bool)),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(Search),
);
