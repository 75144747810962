// import PropTypes from "prop-types";
import SVG from 'react-inlinesvg';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Icon extends PureComponent {
  render() {
    const { name, pathExtra = '' } = this.props;
    // eslint-disable-next-line
    const icon = require(`./assets/${pathExtra}${name}.svg`);

    return (
      <i className={`icon icon--${name}`}>
        <SVG src={icon} cacheGetRequests />
      </i>
    );
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  pathExtra: PropTypes.string,
};
