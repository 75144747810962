import Flickity from "react-flickity-component";
import flickityConfig from "../config/flickity-config";
import CardExperience from "./CardExperience";
import ButtonViewMore from "./ButtonViewMore";
import {Link} from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import LoadingBlock from "./LoadingBlock";
import React from "react";

export default ({ items, loading, title = 'Experiences', viewMore = false }) => {
    return (
      <div className="bg-dark-navy overflow-hidden">
        <div className="container px-pad-sm mx-auto py-[80px] xl:py-[120px]">
          <h2 className="font-heading font-bold text-30 lg:text-50 flex items-center pb-8 xl:pb-14 text-white">
            {title}
          </h2>
          {loading && <LoadingBlock colour="white" size={20} text="Loading" classNames="align-center" />}
          {!loading && items.length === 0 && (
            <div className="text-white">
              Sorry, no experiences were found.
            </div>
          )}
          {!loading && items.length > 0 && (
            <>
              <Flickity
                className={'slider'}
                options={flickityConfig}
              >
                {items.map(item => (
                  <div key={item.id} className="w-[100%] md:w-[49%] xl:w-[32%]">
                    <CardExperience image={item.secondaryImage ? item.secondaryImage['thumbUrl'] : undefined} text={item.name} slug={item.slug} badge={item.badge} />
                  </div>
                ))}
              </Flickity>
              {viewMore && (
                <div className="mt-8 xl:mt-14 text-right">
                  <Link to={'/experiences'} component={ButtonViewMore} classes="text-white" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
